.lead-magnet-form {
  &.container {
    margin: 0 auto;
    max-width: 1188px;
    padding: 0 20px;
  }

  form {
    padding: 32px 40px 40px 40px;

    .checkbox-container {
      width: 100%;

      .checkbox {
        float: right;
        margin: 3px 0 0 0;
      }
    }

    .form-fields {
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;

      .text-field {
        border: 1px solid #e1e1e1;
        display: flex;
        flex-direction: column;
        height: 60px;
        margin: 0 16px 24px 0;
        padding: 14px 24px;
        width: 340px;

        @include media("<=m") {
          margin-right: 0;
          width: 100%;
        }
      }
    }
  }

  .white-boxes {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;

    p {
      font-size: 15px;
      line-height: 23px;
    }

    .white-box {
      align-items: flex-start;
      background-color: #fff;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      padding: 32px;

      @include media(">m") {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }

      .white-box-icon {
        height: auto;
        margin-bottom: 31px;
        width: 64px;
      }

      @include media("<=m") {
        width: 100%;
      }
    }
  }

  .white-box-with-icon {
    color: #1b508e;
    height: 214px;
    width: 270px;
  }

  .white-box-with-checkbox {
    height: 204px;
    width: 509px;

    @include media("<=m") {
      height: auto;
    }
  }

  .large-text {
    font-size: 24px;
  }

  .small-text {
    font-size: 16px;
  }

  .checkbox {
    appearance: none;
    border: 1px solid #1b508e;
    display: inline-block;
    height: 20px;
    padding: 0;
    /*outline: 1px solid #1b508e;*/
    width: 20px;

    &:checked {
      background: #fff url("../img/landing_pages/3d_printing_landing_page/checkmark.png") no-repeat center center;
      background-size: 80%;
      color: #000;
    }
  }

  .privacy-policy-container {
    display: flex;
    align-items: center;
  }

  .privacy-policy-checkbox {
    margin-right: 10px;
    margin-top: 0;
  }

  .btn {
    font-size: 20px;
    font-weight: 700;
    height: 60px;
    line-height: 24px;
    padding: 18px 24px;
    text-transform: uppercase;
  }

  .submit-btn {
    margin-top: 15px;
  }

  .captcha-error-message {
    color: #ff0000;
    display: none;
    font-size: 16px;
  }

  input,
  textarea {
    color: #2a2a2a;
    font-size: 20px;

    &::placeholder {
      color: #2a2a2a;
    }
  }
}
