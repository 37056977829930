.checkout {
  &__button-comment-container {
    margin-top: 45px;
  }
  &__form {
    @include responsive-margin-bottom($aria-double, $aria-two-thirds * 2, $aria);
  }

  &__title {
    @include responsive-margin-bottom($aria-double, $aria-two-thirds * 2, $aria);
  }

  &__products-list {
    .products__list-item {
      &:before {
        display: none;
      }

      &-name {
        cursor: inherit;
        margin-bottom: 0;
        padding-left: 0;
      }
    }

    .btn-checkout {
      width: auto;
    }
  }

  &__order {
    &-details {
      h3 {
        color: $blue;
      }

      + .checkout__order-details {
        @include responsive-margin-top($aria-double, $aria-two-thirds * 2, $aria);
      }

      .grid {
        @include media("<=s") {
          flex-wrap: wrap-reverse;
        }
      }
    }

    &-address {
      list-style: none;

      &-title {
        @include media("<=s") {
          margin-top: $aria-half;
        }
        @include media("<=xs") {
          margin-top: $aria-one-third;
        }
        @include responsive-margin-bottom($aria-two-thirds, $aria-half, $aria-one-third);
      }
    }
  }

  .product-description__details {
    .checkout__product-group-title {
      @include responsive-margin-bottom($aria-two-thirds, $aria-half, $aria-one-third);
    }

    & + .product-description__details {
      @include responsive-margin-top($aria-two-thirds, $aria-half, $aria-one-third);
    }
  }
}
