.map-with-employees {
  pointer-events: auto;
  cursor: pointer;

  @media (max-width: 768px) {
    border-top: 1px solid #939393;
  }

  .map-container {
    width: 100%;
    height: 738px;

    @media (max-width: 768px) {
      height: 300px;
    }
  }

  .country-section {
    display: none;
    border-top: 1px solid #939393;
    border-bottom: 1px solid #939393;
    padding-top: 44px;

    &.active {
      display: block;
    }
  }

  .map-employee,
  .map-distributor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding-bottom: 20px;

    &:not(:first-child) {
      padding-top: 20px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #f6f6f6;
    }

    &_image {
      width: 114px;
      height: 114px;
      border-radius: 50%;
      margin-right: 30px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      width: 30%;
      flex-grow: 1;
      padding-right: 20px;
      max-width: 40%;

      &-website {
        img {
          margin-left: 3px;
          margin-right: 10px;
        }
      }

      @media (max-width: 768px) {
        max-width: 100%;
      }

      &-contacts {
        display: flex;
        flex-direction: column;

        @media (max-width: 768px) {
          margin-top: 18px;
        }

        a {
          font-size: 16px;
          color: #1b508e;
          text-decoration: none;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 7px;
        }
      }

      &-name {
        color: #282828;
        font-weight: 600;
        font-size: 20px;
      }

      &-detail {
        color: #282828;
        font-weight: 300;
        font-size: 16px;
      }
    }
  }

  .map-distributor {
    &__info {
      &-website {
        color: #1b508e;
        font-size: 16px;
        text-decoration: none;
        font-style: normal;
        font-weight: 400;

        img {
          margin-left: 6px;
          margin-right: 10px;
        }
      }

      &-mail,
      &-phone--number {
        margin-bottom: 10px;
        color: #1b508e;
        font-size: 16px;
        text-decoration: none;
        font-style: normal;
        font-weight: 400;

        img {
          margin-left: 3px;
          margin-right: 8px;
        }
      }

      &-address {
        margin-bottom: 48px;

        &-label {
          margin-bottom: 8px;
        }
      }

      &-name {
        margin-bottom: 21px;
      }
    }
  }

  .country-name {
    font-family: "GT America Medium";
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    color: #153d6b;
  }

  .company-name {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;

    color: #515151;
  }

  .map-employee-country-name {
    margin-bottom: 45px;
  }
}

text.jvm-marker {
  pointer-events: none;
}

image.jvm-marker {
  pointer-events: auto;
}

.jvm-zoom-btn {
  pointer-events: auto;
}
