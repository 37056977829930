//sass-lint:disable no-important

/**
 * Apply to the columns to remove the gutter on both sides
 *
 * @molecule .no-gutter
 * @section Grid > Column classes
 * @markup
 *  <div class="s-12 no-gutter"></div>
 */
.no-gutter {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/**
 * Apply to the columns to remove the gutter on left side
 *
 * @molecule .no-gutter-left
 * @section Grid > Column classes
 * @markup
 *  <div class="s-12 no-gutter-left"></div>
 */
.no-gutter-left {
  padding-left: 0 !important;
}

/**
 * Apply to the columns to remove the gutter on right side
 *
 * @molecule .no-gutter-right
 * @section Grid > Column classes
 * @markup
 *  <div class="s-12 no-gutter-right"></div>
 */
.no-gutter-right {
  padding-right: 0 !important;
}

/**
 * Apply to the columns to make the element inside grow in height
 *
 * @molecule .vertical-grow
 * @section Grid > Column classes
 * @markup
 *  <div class="s-12 vertical-grow"></div>
 */
.vertical-grow {
  > * {
    flex: 1;
  }
}

/**
 * Apply to the columns to push the element to the right
 *
 * @molecule .right
 * @section Grid > Column classes
 * @markup
 *  <div class="s-12 right"></div>
 */
.right {
  margin-left: auto;
}

/**
 * Apply to the columns to make the element centered
 *
 * @molecule .centered
 * @section Grid > Column classes
 * @deprecated
 * @markup
 *  <div class="s-6 centered"></div>
 */
.centered {
  margin: 0 auto;
}

/**
 * Apply to the columns to center text
 *
 * @molecule .centered--text
 * @section Grid > Column classes
 * @deprecated
 * @markup
 *  <div class="s-12 centered--text"></div>
 */
.centered--text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.left-text {
  text-align: left;
}

.no-margin {
  margin: 0 !important;
}

// Grid utilities for media queries

.visible-xs,
.visible-s,
.visible-m,
.visible-l,
.visible-xl {
  display: none;
}

@include media("<s") {
  .hidden-xs {
    display: none !important;
  }

  .visible-xs {
    display: flex !important;
  }

  .no-gutter-xs {
    padding: 0 !important;
  }

  .no-gutter-left-xs {
    padding-left: 0 !important;
  }

  .no-gutter-right-xs {
    padding-right: 0 !important;
  }

  .gutter-xs {
    padding-left: $gutter / 2 !important;
    padding-right: $gutter / 2 !important;
  }

  .vertical-grow-xs {
    > * {
      flex: 1;
    }
  }

  .right-xs {
    margin-left: auto;
  }

  // Use .no-margin to undo the .right behaviour

  .no-margin-xs {
    margin: 0;
  }

  .row--centered-xs {
    justify-content: center;
  }
}

@include media("<m") {
  .hidden-s {
    display: none !important;
  }

  .visible-s {
    display: flex !important;
  }

  .no-gutter-s {
    padding: 0 !important;
  }

  .no-gutter-left-s {
    padding-left: 0 !important;
  }

  .no-gutter-right-s {
    padding-right: 0 !important;
  }

  .gutter-s {
    padding-left: $gutter / 2 !important;
    padding-right: $gutter / 2 !important;
  }

  .vertical-grow-s {
    > * {
      flex: 1;
    }
  }

  .right-s {
    margin-left: auto;
  }

  // Use .no-margin to undo the .right behaviour

  .no-margin-s {
    margin: 0;
  }

  .row--centered-s {
    justify-content: center;
  }
}

@include media("<l") {
  .hidden-m {
    display: none !important;
  }

  .visible-m {
    display: flex !important;
  }

  .no-gutter-m {
    padding: 0 !important;
  }

  .no-gutter-left-m {
    padding-left: 0 !important;
  }

  .no-gutter-right-m {
    padding-right: 0 !important;
  }

  .gutter-m {
    padding-left: $gutter / 2 !important;
    padding-right: $gutter / 2 !important;
  }

  .vertical-grow-m {
    > * {
      flex: 1;
    }
  }

  .right-m {
    margin-left: auto;
  }

  // Use .no-margin to undo the .right behaviour

  .no-margin-m {
    margin: 0;
  }

  .row--centered-m {
    justify-content: center;
  }
}

@include media("<xl") {
  .hidden-l {
    display: none !important;
  }

  .visible-l {
    display: flex !important;
  }

  .no-gutter-l {
    padding: 0 !important;
  }

  .no-gutter-left-l {
    padding-left: 0 !important;
  }

  .no-gutter-right-l {
    padding-right: 0 !important;
  }

  .gutter-l {
    padding-left: $gutter / 2 !important;
    padding-right: $gutter / 2 !important;
  }

  .vertical-grow-l {
    > * {
      flex: 1;
    }
  }

  .right-l {
    margin-left: auto;
  }

  // Use .no-margin to undo the .right behaviour

  .no-margin-l {
    margin: 0;
  }

  .row--centered-l {
    justify-content: center;
  }
}

@include media(">=xl") {
  .hidden-xl {
    display: none !important;
  }

  .visible-xl {
    display: flex !important;
  }

  .no-gutter-xl {
    padding: 0 !important;
  }

  .no-gutter-left-xl {
    padding-left: 0 !important;
  }

  .no-gutter-right-xl {
    padding-right: 0 !important;
  }

  .gutter-xl {
    padding-left: $gutter / 2 !important;
    padding-right: $gutter / 2 !important;
  }

  .vertical-grow-xl {
    > * {
      flex: 1;
    }
  }

  .right-xl {
    margin-left: auto;
  }

  // Use .no-margin to undo the .right behaviour

  .no-margin-xl {
    margin: 0;
  }

  .row--centered-xl {
    justify-content: center;
  }
}
//sass-lint:enable no-important

/**
 * A container limited at 1280px
 *
 * @molecule .wrapper
 * @section Grid > Wrapper
 * @markup
 *  <div class="wrapper"></div>
 */
.wrapper {
  display: block;
  margin: 0 auto;
  max-width: $screen-l;
  width: 100%;
}

/**
 * A container limited at $content-limit - $gutter
 *
 * @molecule .wrapper-narrow
 * @section Grid > Wrapper Narrow
 * @markup
 *  <div class="wrapper-narrow"></div>
 */
.wrapper-content {
  display: block;
  margin: 0 auto;
  max-width: $content-limit - $gutter;
  width: 100%;
}

/**
 * A container limited at $page-header-limit - $gutter
 *
 * @molecule .wrapper-narrow
 * @section Grid > Wrapper Narrow
 * @markup
 *  <div class="wrapper-narrow"></div>
 */
.wrapper-page-header {
  display: block;
  margin: 0 auto;
  max-width: $page-header-limit - $gutter;
  width: 100%;
}
