.before-after-slider {
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    width: 80%;
    max-width: 800px;
    overflow: hidden;
    position: relative;
    max-height: 100%;
  }

  .slider {
    position: relative;
    overflow: hidden;
  }

  .before,
  .after {
    display: block;
    width: 100%;
    max-width: 100%;
  }

  .after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .before {
    position: relative;
  }

  .slider-handle-wrapper {
    position: absolute;
    top: 0;
    left: 25%; /* Start at 25% */
    height: 100%;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    cursor: ew-resize;
  }

  .slider-handle {
    width: 3px;
    height: 100%;
    background-color: #fff;
    padding-left: 6px;
    margin-left: -13px;
  }

  .icon {
    width: 40px;
    height: auto;
    pointer-events: none;
    left: -10px;
    margin-left: -19px;
    background: #ffffff;
    border-radius: 50px;
    padding: 6px;
  }

  .slider-handle-wrapper:hover .slider-handle,
  .slider-handle-wrapper:hover .icon {
    background-color: #ccc;
  }

  .labels {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    pointer-events: none;
    z-index: 3;
  }

  .label {
    font-size: 1.2em;
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border-radius: 5px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  }

  .before-label {
    text-align: left;
  }

  .after-label {
    text-align: right;
  }

  @media (max-width: 600px) {
    .container {
      width: 99%;
    }

    .label {
      font-size: 1em;
    }
  }
}
