.tabs-header {
  border-bottom: 1px solid #d1d2d3; // Carlo TODO: why not variable?
  list-style: none;
  padding: 0;
  text-align: center;

  @media (max-width: $screen-s) {
    display: none;
  }
}

.tabs-header__item {
  &.active a {
    background: white; // Carlo TODO: why not variable?
    position: relative;
    top: 1px;
  }
}

.tabs-list {
  display: block;
  margin-bottom: 14px; // Carlo TODO: why not variable?
  margin-top: 0;
  padding: 0;

  li {
    cursor: pointer;
    display: inline-block;
    margin-right: 20px;

    a {
      background: #d1d2d3;
      border-left: 1px solid #d1d2d3; // Carlo TODO: why not variables?
      border-right: 1px solid #d1d2d3;
      border-top: 1px solid #d1d2d3;
      color: #000;
      padding: 15px 30px;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}

.tab-content {
  display: none;

  &.active {
    display: block;
  }
}

.accordion-tab {
  cursor: pointer;
  display: none;

  @media (max-width: $screen-s) {
    display: block;
  }
}
