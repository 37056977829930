.slick-arrow {
  @include media("<=xl") {
    display: none !important; // sass-lint:disable-line no-important
  }
  background-position: center;
  background-repeat: no-repeat;
  background-size: $gutter;
  cursor: pointer;
  display: block;
  height: $gutter-double;
  position: absolute;
  top: calc(50% - #{$gutter});
  width: $gutter-double;
  z-index: 2;
}

.slick-prev {
  background-image: url("/static/assets/right-arrow-blue.svg");
  left: $aria;
  transform: rotateY(180deg);

  &:hover {
    background-image: url("/static/assets/right-arrow-orange.svg");
  }
}

.slick-next {
  background-image: url("/static/assets/right-arrow-blue.svg");
  right: $aria;

  &:hover {
    background-image: url("/static/assets/right-arrow-orange.svg");
  }
}

.rca-styling {
  .slick-prev,
  .slick-next {
    background-image: url("/static/assets/right-arrow.svg");

    &:hover {
      background-image: url("/static/assets/right-arrow.svg");
    }
  }

  &.product-overview-slider {
    .slick-prev,
    .slick-next {
      background-image: url("/static/assets/right-arrow-white.svg");

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .slick-prev {
    left: -60px;
  }

  .slick-next {
    right: -60px;
  }
}

.slick-dots {
  bottom: 0;
  display: flex;
  justify-content: center;
  padding-bottom: $aria-one-third;
  position: absolute;
  width: 100%;
  overflow: initial;

  @include media("<=tablet") {
    overflow: auto;
    justify-content: unset;
    padding-left: 10px;
  }

  li {
    display: inline-block;
    margin-right: $aria-one-third;

    &:last-child {
      margin-right: 0;
    }

    button {
      @include media("<=xs") {
        padding: $aria-one-sixth;
      }
      background: transparent;
      border: $border-half-and-half $blue;
      border-radius: 100%;
      font-size: 0;
      margin-bottom: 0;
      padding: $aria-quarter;
    }

    &.slick-active button {
      background: $blue;
    }
  }

  @include media("<=xs") {
    overflow: auto;
  }
}
