.add-to-cart-container {
  background: linear-gradient(#fff, #fff) padding-box, $gradient-blue-with-pink border-box;
  border: 1px solid transparent;
  border-radius: 16px;
  padding: 38px;
  width: 100%;

  &__title,
  &__subtitle {
    margin-bottom: 16px;
  }

  &__title {
    color: $blue;
  }

  &__subtitle {
    color: $grey-d-1;
    font-weight: 100;
  }

  &__button {
    .btn--main-btn {
      background: linear-gradient(#fff, #fff) padding-box, $gradient-blue-with-pink border-box;
      border: 1px solid transparent;
      border-radius: 16px;
      display: inline-block;
      padding: 11px 13px;

      h3 {
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: $gradient-blue-with-pink;
      }

      &:hover {
        background: transparent;
        border: 1px solid $blue-d;

        h3 {
          -webkit-background-clip: unset;
          -webkit-text-fill-color: unset;
          background-image: unset;
          color: $blue-d;
        }
      }
    }
  }

  @include media("<=tablet") {
    padding: 20px;
  }
}
