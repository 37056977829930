/**
 * @molecule .breadcrumbs
 * @section Breadcrumbs
 * @markup
 *  <nav class="breadcrumbs">
 *    <a class="breadcrumbs__item" href="#">Breadcrumb Level 1</a>
 *    <span class="breadcrumbs__separator">&gt;</span>
 *    <a class="breadcrumbs__item" href="#">Breadcrumb Level 2</a>
 *    <span class="breadcrumbs__separator">&gt;</span>
 *    <a class="breadcrumbs__item" href="#">Breadcrumb Level 3</a>
 *    <span class="breadcrumbs__separator">&gt;</span>
 *    <span class="breadcrumbs__item breadcrumbs__item--active">Breadcrumb active level</span>
 *  </nav>
 */
.breadcrumbs {
  @include media("<=xs") {
    display: none;
  }
  @include media("<=s") {
    margin-top: 0;
  }
  @include vmargin($gutter, $gutter-one-third);

  &__list {
    text-align: right;
  }

  &__list-item {
    color: $black-d-t;
    display: inline-block;
    position: relative;

    a {
      color: $black-d-t;

      &:visited {
        color: $black-d-t;
      }
    }
  }

  &__separator {
    margin: 0 $gutter-quarter;
  }
}
