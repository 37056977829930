.supplier {
  &__logo {
    max-width: $aria-double * 3;
  }

  &__title {
    color: $blue;
    margin-bottom: $aria-one-third;
    margin-top: $aria-two-thirds;
  }

  &__link {
    @include link-style();
  }

  &-documents__info {
    @include responsive-margin-top(0, $gutter-two-thirds * 2, $gutter);
  }

  &__item {
    @include media("<=xs") {
      flex-direction: column;
    }
    display: flex;

    & + .supplier__item {
      @include responsive-margin-top($gutter, $gutter-two-thirds, $gutter-one-third);
    }
  }

  &__image {
    @include media("<=xs") {
      flex: 100%;
    }
    flex: 20%;
  }

  &__description {
    @include media("<=xs") {
      flex: 100%;
      padding-left: 0;
    }
    flex: 80%;
    padding-left: $gutter;
  }
}

.supplier-video {
  height: 370px;
  width: 100%;
  margin: 60px 0 60px 0;
}

.suppliers__list {
  @include responsive-margin-top($gutter, $gutter-two-thirds, $gutter-one-third);

  color: $grey-d;

  a {
    @include link-style();
  }
}
