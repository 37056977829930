.video-plugin {
  @include media(">s") {
    margin-bottom: $aria;
  }
  @include media("<=s") {
    margin-bottom: $aria-two-thirds;
  }
  @include media("<=xs") {
    margin-bottom: $aria-one-third;
  }

  &__iframe-container {
    height: 0;
    padding-bottom: 56.25%; // 16:9 ratio
    position: relative;

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__video-container {
    width: 100%;

    video {
      background-color: transparent;
      height: 100%;
      padding-bottom: 56.25%;
      position: relative;
    }
  }
}
