.search-container {
  @include media("<=s") {
    @include vpadding($gutter-half, $gutter-half);
  }
  @include media("<=xs") {
    @include vpadding($gutter-one-third, $gutter-one-third);
  }
  @include vpadding($gutter-two-thirds, $gutter-two-thirds);
  background: $bg-light-grey;

  &__selection,
  &__documents {
    @include media("<=m") {
      margin-top: $gutter-one-third;
    }
    @include media("<=s") {
      align-items: flex-start;
    }
    @include media("<=xs") {
      border-top: $border-half $white;
      justify-content: flex-start;
    }
    align-items: center;
    display: flex;
    height: 38px;
    justify-content: center;

    &-tools,
    &-download {
      @include media("<=xs") {
        margin-left: 0;
        margin-top: $gutter-one-third;
      }
      margin-left: auto;
      text-align: center;
      width: 100%;

      &__link {
        color: inherit;
        text-decoration: none;
      }

      &__mt {
        margin-bottom: 5px;
      }
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.search-options,
.search-options__options-list {
  align-items: center;
  display: flex;
  list-style: none;

  p {
    color: $black-d-t;
  }
}

.search-options {
  @include media("<=xs") {
    flex-wrap: wrap;
    margin-top: $gutter-one-third;
  }
  margin-top: $gutter-half;

  p {
    @include media("<=xs") {
      margin-bottom: $aria-one-sixth;
    }
  }

  &__option {
    @include media("<=xs") {
      &:first-of-type {
        margin-left: 0;
      }
    }
    @include media("<=s") {
      margin-left: $aria-half;
    }
    align-items: center;
    display: flex;
    margin-left: $aria;
    position: relative;

    [type="checkbox"] {
      //sass-lint:disable-line no-qualifying-elements
      display: none;

      &:checked + .search-options__option-checkbox {
        background: $blue;

        &:before {
          border-bottom: $border-half-and-half $white;
          border-right: $border-half-and-half $white;
          content: "";
          height: $size-half - 1px;
          left: calc(#{$aria-one-sixth} + 1px);
          position: absolute;
          top: 0;
          transform: rotate(45deg);
          width: $size-one-third - 1px;
        }
      }
    }

    label {
      color: $black-d-t;
      margin-bottom: 0;
      margin-left: $aria-one-sixth;

      &.search-options__option-checkbox {
        background: $white;
        border-radius: $border-radius;
        content: "";
        display: block;
        height: $size-one-third * 2;
        left: 0;
        margin-left: 0;
        width: $size-one-third * 2;
      }
    }
  }

  &--recipes-search {
    @include media("<=m") {
      flex-wrap: wrap;
    }

    fieldset {
      @include media("<=m") {
        margin-right: $aria-half;
      }
    }
  }
}

.search-input {
  input {
    @include media("<=xs") {
      height: $search-input-sizes-mobile;
      margin-bottom: 0;
    }
    @include media("<=s") {
      @include hpadding($search-input-sizes-tablet, $search-input-sizes-tablet);
      height: $search-input-sizes-tablet;
      margin-bottom: 0;
    }
    @include media("<m") {
      background-size: 4%;
    }
    @include hpadding($search-input-sizes-desktop, $search-input-sizes-desktop);
    background-image: url("/static/img/icons/search.png");
    background-position: left;
    background-position-x: $aria-one-third;
    background-repeat: no-repeat;
    background-size: 5%;
    border: $border-half;
    border-color: $grey-l;
    border-radius: $border-radius;
    height: $search-input-sizes-desktop;
    margin-bottom: 0;

    &:not(:valid):focus {
      @include hpadding($gutter-half, $gutter-half);
      background: $white;
      transition: ease-in-out 0.3s;
    }
  }
}

.filters {
  &__container {
    @include media("<=s") {
      padding-top: $gutter-half;
    }
    @include media("<=xs") {
      margin-top: $gutter-one-third;
      padding-top: $gutter-one-third;
    }

    &--hidden {
      @include media("<=xs") {
        padding-bottom: 0;
      }
      padding-bottom: $gutter-one-third;
    }
    border-top: $border-half $white;
    margin-top: $aria-one-sixth;
    padding-top: $gutter-two-thirds;
  }

  &__labels-filters,
  &__exclusion-criterias-filters,
  &__positive-criterias-filters,
  &__functionality-filters,
  &__sustainability-filters {
    .search-options__option {
      &:first-child {
        margin-left: 0;
      }
    }

    .filters__labels-filter,
    .filters__exclusion-criterias-filter,
    .filters__positive-criterias-filter,
    .filters__functionality-filter,
    .filters__sustainability-filter {
      color: $blue;
    }
  }

  &__options {
    @include media("<=s") {
      margin-left: $gutter;
    }
    @include media("<=xs") {
      margin: $gutter-one-third $gutter 0 0;
    }
    color: $blue;
    display: flex;
    margin-left: $gutter-double;

    svg {
      fill: $blue;
      height: auto;
      margin-left: $aria-one-sixth;
      width: $size-half;
    }

    &--recipes-options {
      @include media("<m") {
        margin: $gutter-one-third $gutter 0 0;
      }
    }
  }

  &__clean {
    cursor: pointer;

    svg {
      width: $size-one-third;
    }
  }

  &__toggle {
    cursor: pointer;
    display: flex;
    margin-left: $aria;

    .toggle__show-filters,
    .toggle__hide-filters {
      display: none;

      &.active {
        display: block;
      }
    }

    .toggle__show-filters {
      &.active {
        ~ svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__clean {
    @include media("<=s") {
      margin-left: $gutter-half;
    }
  }

  &__applications-filters,
  &__categories-filters {
    @include media("<=xs") {
      margin-top: $gutter-half;
    }
    margin-top: $aria-two-thirds;

    input {
      display: none;

      &:checked + .filters__applications-filter,
      &:checked + .filters__categories-filter {
        background: $blue;
        box-shadow: $box-shadow-h;
        color: $white;
      }
    }
  }

  &__applications-filter,
  &__categories-filter {
    background: $white;
    border-radius: $size-two-thirds;
    box-shadow: $box-shadow;
    color: $grey-d-1;
    margin-bottom: $aria-one-third;
    margin-right: $aria-one-sixth;
    padding: $gutter-one-third $gutter-half;

    &:hover {
      background: $grey-l;
      box-shadow: $box-shadow-h;
      color: $blue;
    }
  }

  &__categories-select-all {
    background: $blue;
    box-shadow: $box-shadow-h;
    color: $white;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 20px;
  }
}

.recipes-search {
  &__form {
    display: flex;
    flex-wrap: wrap;
  }

  &__filters {
    @include media("<=xs") {
      flex-wrap: wrap;
    }

    display: flex;
    justify-content: space-between;
    list-style: none;
  }

  &__filter {
    @include media("<=xs") {
      margin-right: 0;
      margin-top: $aria-one-third;
    }

    background: $white;
    margin-right: $aria-two-thirds;
    position: relative;
    width: 100%;

    &:last-child {
      margin-right: 0;
    }

    select {
      appearance: none;
      background-color: transparent;
      border: $border-half;
      border-color: $grey-l;
      margin: 0;
      padding: $size-one-third $size-half;
      position: relative;
      transition: none;
      z-index: 1;

      &:focus + svg {
        transform: rotate(-180deg);
      }
    }

    svg {
      fill: $blue;
      position: absolute;
      right: $aria-half;
      top: $aria-half;
      transform: rotate(180deg);
      width: $size-half;
    }
  }
}

.product-overview-form,
.product-selection-tool-form {
  .search-container {
    @include media("<=s") {
      padding: $gutter-half 0 $aria-one-sixth;
    }
    @include media("<=xs") {
      padding: $gutter-one-third 0 0;
    }
    padding: $gutter-two-thirds 0 $gutter-one-third;
  }

  .search-input input {
    @include media("<=s") {
      margin-bottom: $gutter-one-third;
    }
    @include media("<=xs") {
      margin-bottom: 0;
    }
    margin-bottom: $gutter-half;
  }

  .search-options {
    margin-top: 0;
  }
}

.product-selection-tool-disclaimer {
  @include media("<s") {
    display: none;
  }
}

.product-selection-tool-small-viewport {
  @include media("<s") {
    display: block;
  }

  display: none;
}

.product-selection-tool-form {
  @include media("<s") {
    display: none;
  }

  &--energycuring {
    .filters__container {
      padding-top: 0;
    }

    .filters__categories-filters {
      margin-top: 0;
    }
  }

  .filters__container {
    border-top: 0;
    padding-top: 0;
  }

  .search-input {
    align-items: baseline;
    display: flex;

    &--container {
      @include media("<=l") {
        width: 60%;
      }
      margin-top: $aria-half;
      width: 50%;
    }
  }

  .filters__country-container {
    display: flex;
    flex-direction: column;

    .filters__country-label {
      color: $blue;
      margin-bottom: $aria-half;
    }

    .filters__country-filter {
      @include media("<=l") {
        width: 30%;
      }
      width: 20%;
    }
  }

  .categories-field-header,
  .applications-field-header,
  .functionality-field-header,
  .sustainability-field-header,
  .exclusion-criterias-field-header {
    align-items: center;
    color: $blue;
    display: flex;
    margin-bottom: $aria-half;
  }

  .filters__country-container {
    display: flex;
    justify-content: space-between;
    list-style: none;
  }

  .filters__country-filter {
    background: $white;
    margin-right: $aria-two-thirds;
    position: relative;
    width: 100%;

    select {
      appearance: none;
      background-color: transparent;
      border: $border-half;
      border-color: $grey-l;
      margin: 0;
      padding: $size-one-third $size-half;
      position: relative;
      transition: none;
      z-index: 1;

      &:focus + svg {
        transform: rotate(-180deg);
      }
    }

    svg {
      fill: $blue;
      position: absolute;
      right: $aria-half;
      top: $aria-half;
      transform: rotate(180deg);
      width: $size-half;
    }
  }

  .filters__exclusion-criterias-filters,
  .filters__functionality-filters,
  .filters__sustainability-filters,
  .filters__positive-criterias-filters {
    @include media("<m") {
      flex-wrap: wrap;

      .search-options__option {
        @include hmargin(0, $aria);
        margin-top: $aria-one-third;
      }
    }

    margin: $aria-half 0;
  }

  .filters__functionality-filters,
  .filters__sustainability-filters,
  .filters__exclusion-criterias-filters,
  .filters__positive-criterias-filters {
    align-items: flex-start;
    flex-direction: column;

    .functionality-field-options,
    .sustainability-field-options,
    .exclusion-criterias-field-options,
    .positive-criterias-field-options {
      display: flex;
    }
  }

  .positive-criterias-field-options {
    margin-top: $aria-half;
  }
}

.table-container {
  overflow-x: auto;
  transform: rotateX(180deg);
  min-height: 70px;
}

.product-selection-tool-table {
  display: block;
  text-align: left;
  transform: rotateX(180deg);

  &-section {
    @include media("<s") {
      display: none;
    }
  }

  .table-cosmos,
  .table-natrue,
  .table-vegan,
  .table-palmoilfree,
  .table-halal,
  .table-iecic,
  .table-biodegradability,
  .table-reach,
  .table-tsca,
  .table-iecsc,
  .table-swiss-ordinance,
  .table-iso,
  .table-feedstock,
  .table-palmcontent,
  .table-rspostatus {
    display: none;

    &.show {
      display: table-cell !important;
    }
  }

  thead {
    background: $blue;
    color: $white;
  }

  tbody {
    tr:nth-child(odd) {
      background-color: $white;
    }

    tr:nth-child(even) {
      background-color: $grey-l;
    }

    .table-inci,
    .table-feedstock,
    .table-applications,
    .table-keyfeatures,
    .table-description {
      white-space: normal;
    }

    .table-palmoilfree,
    .table-palmcontent,
    .table-rspostatus,
    .table-iecic,
    .table-functionality,
    .table-viscosity,
    .table-reach,
    .table-tsca,
    .table-iecsc,
    .table-swiss-ordinance {
      text-align: center;
    }
  }

  th,
  td {
    border: $border-half $grey-d;
    padding: $aria-half;
  }
}

.search-results {
  &__title {
    @include responsive-margin-bottom($aria, $aria-two-thirds, $aria-one-third);
    color: $blue;
    text-align: center;
  }

  &__section {
    @include responsive-margin-bottom($aria, $aria-two-thirds, $aria-one-third);

    &-title {
      @include responsive-margin-bottom($aria-two-thirds, $aria-one-third, $aria-quarter);
      color: $blue;
    }
  }

  &__pages {
    &-list {
      list-style: none;
      margin-left: $aria;

      li + li {
        @include responsive-margin-top($aria-one-third, $aria-one-third, $aria-one-sixth);
      }

      a {
        color: $blue;

        &:hover {
          color: $orange;
        }
      }

      .highlight {
        color: $orange;
      }
    }

    &-item:nth-child(n + 11) {
      display: none;
    }
  }

  .toggle-buttons {
    @include responsive-margin-top($aria-half, $aria-one-third, $aria-one-sixth);
  }

  .products__list-item:nth-child(n + 11) {
    display: none;
  }

  .document-item--documentation:nth-child(n + 13) {
    display: none;
  }
}

.rca-styling {
  &.search-container {
    background-color: #f6f6f6;

    @include media("<=tablet") {
      padding-top: 12px;
      padding-bottom: 16px;

      .xs-24 {
        padding-left: 15px;
        padding-right: 15px;
      }

      .search-btn-flex {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    &__selection-tools__mt {
      @include media("<=tablet") {
        margin-bottom: 6px;
      }
    }
  }

  .search-btn-flex {
    padding-top: 5px;
  }

  .search-input {
    input {
      border: 1px solid #ababab;
      border-radius: 10px;
      background-color: #f6f6f6;
      color: #565656;
      background-image: url("/static/img/icons/search.svg");

      @include media("<m") {
        background-size: unset;
      }
    }
  }

  .search-container__selection {
    @include media("<=xs") {
      border-top: unset;
    }
  }

  .documents-download__icon__white {
    display: none;
  }

  .filters__applications-filters {
    display: none;
    margin-top: 0;
    padding-top: 22px;
  }

  .filters__labels-filters {
    padding-top: 22px;
  }

  .filters__container {
    margin: 0;
    padding-bottom: 19px;
    padding-top: 0;
    border-bottom: 1px solid #d5d5d5;

    .grid {
      .xs-24 {
        padding-right: 43px;
        padding-left: 42px;
      }
    }

    .filters__toggle,
    .filters__clean {
      svg {
        margin-left: 12px;
        width: 14px;
      }
    }

    &--hidden {
      border-bottom: 0;

      .filters__labels-filters {
        padding-bottom: 19px;
        border-bottom: 1px solid #d5d5d5;
      }

      .grid {
        .xs-24:first-child {
          box-shadow: 16px -12px 84px rgba(137, 137, 137, 0.21);
        }

        .xs-24:nth-child(2) {
          box-shadow: 16px 12px 84px rgba(137, 137, 137, 0.21);
        }
      }
    }
  }

  .search-container {
    &__selection.xs-24 {
      padding-left: 30px;
      padding-right: 0;

      @include media("<=tablet") {
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }

  .search-box {
    padding-right: 30px;

    @include media("<=tablet") {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .filters__options {
    color: $grey-d-1;
    margin-left: 0;
  }

  .filters {
    &__applications-filter,
    &__labels-filter,
    &__categories-filter {
      border: 1px solid $grey-d-1;
      border-radius: $aria-half;
      box-shadow: unset;
      color: $grey-d-1;
      margin-bottom: 22px;
      margin-right: 20px;
      padding: 2px 12px;

      &:hover {
        background: $grey-l;
        box-shadow: unset;
        color: $grey-d-1;
      }

      input {
        display: none;

        &:checked + .filters__applications-filter,
        &:checked + .filters__categories-filter {
          background: $blue;
          box-shadow: $box-shadow-h;
          color: $white;
        }
      }
    }

    &__toggle,
    &__clean {
      margin-left: 0;
      color: $grey-d-1;
      font-weight: $normal;
      font-size: 20px;
      line-height: 33px;

      span {
        color: $grey-d-1;
        font-weight: $normal;
        font-size: 20px;
        line-height: 33px;
      }
    }

    &__clean {
      margin-left: 51px;
    }

    &__labels-filter {
      margin-bottom: 26px;
    }

    &__applications-category {
      color: $blue;
      font-weight: $semi-bold;
      font-size: 20px;
      line-height: 43px;
      margin-bottom: 12px;

      svg {
        margin-left: 10px;
        transform: rotate(180deg);
      }
    }
  }

  .search-options {
    &__option {
      margin-left: 0;
      input {
        display: none;

        &:checked + .filters__labels-filter,
        &:checked + .filters__labels-filter {
          background: $blue;
          box-shadow: $box-shadow-h;
          color: $white;
        }
      }
    }
  }
}

.rca-styling {
  .product-overview-form {
    .search-container {
      padding-bottom: 0;

      .filters__container {
        background-color: $white;
      }
    }
  }
}

.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute;
  bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
