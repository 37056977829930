.footer {
  &__container {
    @include media("<=s") {
      padding: $gutter 0;
    }
    @include media("<=xs") {
      padding: $gutter-two-thirds 0;
    }
    border-top: $border-half $grey-l;
    display: flex;
    padding: $gutter-double 0;
    width: 100%;

    a:hover {
      font-weight: $normal;
    }
  }

  &__hover-remove {
    a:hover {
      color: #1b508e !important;
    }
  }

  &__non-rca-container {
    @include media("<=s") {
      padding: $gutter 0;
    }
    @include media("<=xs") {
      padding: $gutter-two-thirds 0;
    }
    @include media("<=s") {
      .footer-btn-flex {
        display: inline-grid;
        margin-top: 35px;
        justify-content: stretch;
        margin: auto;
        margin-top: 35px;
        margin-bottom: 15px;
        width: 360px;
      }
    }
    border-top: $border-half $grey-l;
    display: flex;
    flex-direction: column;
    background: #1b508e;
    padding: $gutter 0;
    width: 100%;

    a {
      color: white;
    }

    a:hover {
      color: #fefefe;
      font-weight: $semi-bold;
    }

    h3 {
      color: $blue;
    }
  }

  &__copyright-flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    @include responsive-margin-top(0px, 0px, $aria);
    div > a {
      text-decoration: underline;
      margin: 0 26px 13px 15px;
    }
  }

  &__hr {
    margin: 0 auto;
    @include responsive-margin-top($aria, $aria-two-thirds, $aria-one-third);
    @include responsive-margin-bottom($aria, $aria-two-thirds, $aria-one-third);
    width: 97%;
    max-width: 1300px;
    @include media(">=m") {
      width: 93%;
    }
    @include media(">=xl") {
      width: 1250px;
    }
  }

  &__hr-line {
    border-bottom: 1px solid #537aa6;
  }

  &__top-section {
    display: flex;
    background: #1b508e;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    h3 {
      color: #ffffff;
    }
  }

  &__logo-arrow-up-section {
    @include responsive-margin-top($aria, $aria-two-thirds, $aria-one-third);
  }

  &__middle-section {
    display: flex;
    background: #1b508e;
    align-items: center;
    justify-content: center;
    @include responsive-margin-top($aria, $aria-two-thirds, $aria-one-third);
    @include responsive-margin-bottom($aria, $aria-two-thirds, $aria-one-third);
    h3 {
      color: #ffffff;
    }
  }

  &__bottom-section {
    ul {
      display: flex;
      flex-direction: row;
    }
    li,
    a,
    p {
      color: #b2d6ff;
    }

    li {
      list-style: none;
    }
  }

  &__options-container {
    display: flex;
    justify-content: flex-end;
    @include media("<=s") {
      display: flex;
      justify-content: flex-start;
    }
  }

  &__contact-info {
    color: $grey-d-1;
  }

  &__add_border {
    border-right: 1px solid #b2d6ff;
    height: 26px;
    margin-right: 11px;
    @include media("<=s") {
      border-right: 0px;
      height: 0px;
      margin-right: 0px;
      border-bottom: 1px solid #b2d6ff;
      width: 26px;
      margin-bottom: 11px;
    }
  }

  &__logo-container {
    display: flex;
    justify-content: flex-start;
  }

  &__back-to-top-container {
    display: flex;
    justify-content: flex-end;
  }

  &__logo {
    @include media("<=m") {
      align-items: flex-end;
      flex: $logo-width-tablet 0 0;
      padding: $gutter-three-quarters 0 0;
    }
    @include media("<=s") {
      padding-bottom: $gutter-one-third;
    }
    @include media("<=xs") {
      flex: $logo-width-mobile 0 0;
      padding-bottom: $gutter-three-quarters;
    }
    display: flex;
    flex: $logo-width-mobile 0 0;
  }

  &__logo-section {
    align-items: center;
    justify-content: center;
  }

  &__contact-details-section {
    @include media("<=xs") {
      justify-content: space-between;
    }

    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
  }

  &__footer__options-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    @include media("<=s") {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
  }

  &__corporate-links-container {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    a {
      margin: 0 15px 6px 0;
      text-decoration: underline;
      display: inline-block; // ensure that we don't wrap within a link, but only entire links
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media("<=s") {
      order: -1;
    }
  }

  &__copyright-container {
    display: flex;
    @include media(">s") {
      text-align: right;
      justify-content: flex-end;
    }
  }

  &__copyright {
    @include media("<=s") {
      text-align: left;
    }
    @include media("<=xs") {
      margin-top: 0;
    }
    margin-top: calc(#{$aria} + #{$aria-quarter});
    text-align: right;

    a {
      display: block;

      &.company {
        display: inline-block;
      }
    }

    * {
      color: $grey-d-1;
    }
  }

  &__address {
    p {
      color: white;
    }
    p > a {
      color: white;
    }
  }

  &__languages {
    &-list {
      justify-content: flex-end;
    }

    &-element {
      margin: 0 13px 14px 0;

      &:last-of-type {
        margin-right: 0;
      }

      &.active {
        a {
          border: 0;
          font-weight: $semi-bold;
        }
      }
    }
  }

  &__section {
    @include media("<=xs") {
      margin-bottom: $gutter-two-thirds;
    }

    .phone-icon,
    .mail-icon {
      svg {
        fill: white;
        height: auto;
        margin-right: $aria-one-sixth;
        vertical-align: middle;
        width: 1em;
      }

      &:hover svg {
        fill: #fefefe;
      }
    }

    p {
      color: $grey-d-1;
    }
  }

  &__reverse-column-order {
    @include media("<=s") {
      order: -1;
    }
  }

  &__link-group {
    margin-right: 0px;
    @include media("<=s") {
      > div:not(:first-child) {
        margin-top: 15px;
      }
    }

    @include media(">s") {
      > div:not(:first-child) {
        margin-top: 0px;
      }
      display: flex;
      gap: 20px;
      align-items: center;
      align-content: center;
      text-align: center;
      justify-content: flex-end;
      width: 400px; // keeps the child buttons a consitent width at different breakpoints
    }
  }

  &__title-v2 {
    @include media("<=s") {
      text-align: center;
    }
  }

  &__section-non-rca {
    margin-bottom: $gutter-two-thirds;
    .address-block {
      margin-right: 30px;
    }

    .phone-icon,
    .mail-icon {
      svg {
        fill: white;
        height: auto;
        margin-right: $aria-one-sixth;
        vertical-align: middle;
        width: 1em;
      }

      &:hover svg {
        fill: #fefefe;
      }
    }

    p {
      color: white;
    }
  }

  &__rca-social-icons-container {
    margin-top: $aria-half;

    a + a {
      margin-left: $aria-half;
    }
  }

  &__social-icons-container {
    display: flex;
    padding-right: 15px;
    margin-bottom: 5px;
    @include media(">s") {
      justify-content: flex-end;
    }

    a + a {
      margin-left: $aria-half;
    }

    a img {
      height: 28px;
    }
  }
}

.footer.rca-styling {
  margin-top: -1px;
}

.rca-styling .footer {
  margin-top: -1px;

  &__social-icon {
    &:hover {
      svg {
        path {
          fill: $blue;
          stroke: $blue;
        }
      }
    }

    &.youtube {
      position: relative;

      &:hover {
        svg {
          path {
            fill: none;
            stroke: $blue;
          }
        }
      }

      svg {
        top: -17px;
        position: absolute;
      }
    }
  }

  &__social-icon-image {
    &.youtube {
      position: relative;
      top: -5px;
    }
  }

  &__container {
    background-image: url("/static/img/rca/rca-blue-gradient.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    border-top: unset;
    padding-top: 54px;
    display: block;

    @include media("<m") {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include media("<=tablet") {
      padding: 36px 0 58px 0;
    }

    .grid {
      @include media("<=xs") {
        width: 100%;
      }
    }

    p {
      color: $grey-v-l;

      a {
        color: $grey-v-l;

        svg {
          fill: $grey-v-l;
          margin-right: 12px;
        }

        &:hover svg {
          fill: $grey-v-l;
        }
      }
    }
  }

  &__copyright {
    margin-top: 0;

    a,
    p,
    p > a {
      color: $grey-v-l;
    }
  }

  &__section,
  &__copyright {
    p,
    a {
      margin-bottom: 3px;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #1163c2;
  color: #fff;
  text-align: center;
  border-radius: 128px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
  @media (max-width: $screen-s) {
    bottom: -4%;
    left: 45%;
    margin-left: 100px;
  }
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1163c2 transparent transparent transparent;
  @media (max-width: $screen-s) {
    position: absolute;
    top: 32%;
    left: -4%;
    rotate: 82deg;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
