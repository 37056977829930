.header {
  @include media("<=xs") {
    @include vpadding($gutter-one-third, $gutter-one-third);
  }
  padding-top: $gutter-half;

  &--home {
    @include media("<=s") {
      padding-bottom: $gutter;
    }
    @include media("<=xs") {
      padding-bottom: $gutter-one-third;
    }
    padding-bottom: $gutter-double;
  }

  &__container {
    @include media("<=xs") {
      align-items: center;
    }
    display: flex;
  }

  &__nav-container {
    @include media("<=xs") {
      margin-top: 0;
    }
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }

  &__logo {
    @include media("<=m") {
      align-items: flex-end;
      flex: $logo-width-tablet 0 0;
      padding: $gutter-three-quarters 0 0;
    }
    @include media("<=s") {
      padding-bottom: $gutter-one-third;
    }
    @include media("<=xs") {
      flex: $logo-width-mobile 0 0;
      padding-bottom: $gutter-three-quarters;
    }
    display: flex;
    flex: $logo-width-desktop 0 0;
    padding-top: $gutter;
  }

  &__top-container {
    @include media("<=s") {
      align-items: center;
    }
    display: flex;
    justify-content: flex-end;
  }

  &__user-login {
    &:hover {
      cursor: pointer;

      svg {
        fill: $orange;
      }
    }

    svg {
      fill: $blue;
      height: $size-three-quarters;
      width: $size-three-quarters;
    }

    &--authenticated {
      p {
        @include media("<=xs") {
          display: none;
        }

        color: $orange;
        display: inline-block;
      }

      svg {
        display: inline-block;
        fill: $orange;
        vertical-align: sub;
      }
    }
  }

  &__cart {
    position: relative;

    &-icon {
      @include media("<=s") {
        margin-left: $aria-two-thirds;
      }
      @include media("<=xs") {
        margin-left: $aria-one-third;
      }
      height: $size-three-quarters;
      margin-left: $aria;
      position: relative;
      width: $size-cart-icon;

      &:hover {
        cursor: pointer;
      }

      svg {
        fill: $orange;
        height: auto;
        width: 100%;
      }
    }

    &-count {
      @include media("<=m") {
        bottom: -1px;
      }
      @include media("<=s") {
        bottom: 0;
      }
      bottom: 0;
      color: $white;
      left: 12px;
      position: absolute;
    }

    &-box {
      background: $white;
      box-shadow: $box-shadow-o;
      cursor: auto;
      display: none;
      min-width: $size-header-boxes;
      padding: $gutter;
      position: absolute;
      right: 0;
      top: $aria;
      z-index: 2;
    }

    &-title {
      color: $blue;
    }

    &-info {
      @include vmargin($aria-half, $aria-two-thirds);
    }

    &-products-list li {
      border-bottom: $border-half $grey-l;

      &:first-of-type {
        border-top: $border-half $grey-l;
      }
    }

    &-product-item {
      display: flex;

      svg {
        fill: $grey-d;
        height: $size-two-thirds;
        width: $size-two-thirds;
      }

      &--name {
        color: $blue;
      }

      &--remove {
        align-items: center;
        display: flex;
        margin-left: auto;

        &:hover {
          svg {
            fill: $orange;
          }
        }
      }
    }

    &-products-list {
      list-style: none;
    }
  }

  &__hamburger-menu {
    @include media("<=s") {
      display: flex;
    }
    align-items: center;
    border: $border-half-and-half $btn-blue;
    border-radius: $border-radius;
    cursor: pointer;
    display: none;
    flex-direction: column;
    height: $hamburger-menu-width;
    justify-content: space-around;
    margin-left: $gutter-half;
    padding: $gutter-quarter;
    width: $hamburger-menu-width;

    &-stick {
      background: $blue;
      height: $stick-size;
      width: 100%;
    }
  }

  &__languages,
  &__main-nav {
    &-list {
      display: flex;
      flex-direction: row;
    }

    &-element {
      list-style: none;

      &.active > a {
        border-bottom: $border $orange;
        color: $orange;
        text-shadow: 0 0 1px $orange;
      }

      a {
        color: $blue;

        &:hover {
          color: $orange;
        }
      }
    }
  }

  &__main-nav {
    @include media("<=s") {
      display: none;
    }
    display: flex;

    &-list {
      margin-left: auto;

      &--top {
        @include media("<=s") {
          display: none;
        }
        @include hmargin(0, auto);

        .header__main-nav-element {
          @include hpadding($aria-one-third);

          &:last-child {
            padding-right: $aria-one-and-half;
          }

          a {
            padding-bottom: $aria-one-tenth;
          }
        }
      }
    }

    &-element {
      @include media("<=l") {
        padding: 0 $aria-half;
      }
      padding: 0 $aria-two-thirds;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &.ancestor > a,
      &.selected > a {
        border-bottom: $border $orange;
        color: $orange;
        font-weight: $normal;
      }

      > a {
        padding-bottom: $aria-one-third;

        &:hover {
          border-bottom: $border $orange;
        }
      }
    }

    &-submenu-container {
      @include media("<=xl") {
        left: 0;
        transform: translateX(0);
        width: 100%;
      }
      background: $white-t;
      display: none;
      left: 50%;
      padding-bottom: $aria;
      position: absolute;
      transform: translateX(-50%);
      z-index: 2;

      ul {
        list-style: none;
      }

      .header__main-nav-submenu-close {
        border-bottom: 0;
        padding-top: $aria-two-thirds;

        a {
          @include close-icon($aria-half, -$aria-half, 3px);

          &:hover {
            color: $orange;

            .close-icon:before,
            .close-icon:after {
              background-color: $orange;
            }
          }
        }
      }

      .products-menu {
        display: none;
      }

      .grid {
        @include media(">=xs") {
          margin: 0 auto;
          width: $grid-xs;
        }

        @include media(">=s") {
          margin: 0 auto;
          width: $grid-s;
        }

        @include media(">=m") {
          margin: 0 auto;
          width: $grid-m;
        }

        @include media(">=l") {
          margin: 0 auto;
          width: $grid-l;
        }

        @include media(">=xl") {
          margin: 0 auto;
          width: $grid-xl;
        }
      }
    }

    &-third-level-menu-container {
      display: none;
    }
  }

  &__sub-menu-element,
  &__third-level-menu-element {
    border-bottom: $border-half $grey-l;
    line-height: 1;
    padding: $aria-two-thirds 0;

    &:first-of-type {
      border-top: $border-half $grey-l;
    }

    &.selected > a,
    &.ancestor > a,
    > a:hover {
      border-left: $border $orange;
      color: $blue;
      font-weight: $semi-bold;
      padding-left: $aria-one-third;
    }
  }

  &__sub-menu-element {
    display: flex;

    &.active a {
      border-left: $border $orange;
      color: $blue;
      font-weight: $semi-bold;
      padding-left: $aria-one-third;
    }

    a {
      width: 100%;
    }
  }

  &__languages {
    @include media("<=s") {
      display: none;
    }

    &-list {
      justify-content: flex-end;
    }

    &-element {
      margin: 0 $aria-half $aria 0;

      &.active {
        a {
          border: 0;
          font-weight: $semi-bold;
        }
      }
    }
    &-element-footer {
      margin: 0 15px 6px 0;

      &.active {
        a {
          border: 0;
          font-weight: $semi-bold;
        }
      }
    }
  }

  &__user-login {
    position: relative;
  }

  &__login-box {
    background: $white;
    box-shadow: $box-shadow-o;
    cursor: auto;
    display: none;
    min-width: $size-header-boxes;
    padding: $gutter;
    position: absolute;
    right: 0;
    z-index: 3;

    &-input {
      background: $grey-l-t;
      border: $border-half $grey-l;
    }

    &-actions {
      list-style: none;
      margin-top: $aria;
    }

    &-action {
      color: $black-d-t;
    }

    &-title {
      color: $blue;
    }

    &-company {
      color: $grey-d-1;
    }
  }

  .product-menu {
    @include media(">=xs") {
      .grid {
        margin-left: -($gutter-xs / 2);
        width: calc(100% + #{$gutter-xs});
      }
    }

    @include media(">=s") {
      .grid {
        margin-left: -($gutter-s / 2);
        width: calc(100% + #{$gutter-s});
      }
    }

    @include media(">=m") {
      .grid {
        margin-left: -($gutter-m / 2);
        width: calc(100% + #{$gutter-m});
      }
    }

    @include media(">=l") {
      .grid {
        margin-left: -($gutter-l / 2);
        width: calc(100% + #{$gutter-l});
      }
    }

    @include media(">=xl") {
      .grid {
        margin-left: -($gutter-xl / 2);
        width: calc(100% + #{$gutter-xl});
      }
    }

    &__category {
      border-bottom: $border-half $grey-l;
      color: $blue;
      line-height: 1;
      padding: $aria-two-thirds 0;
      position: relative;

      &:first-of-type {
        border-top: $border-half $grey-l;
      }
    }

    &__element {
      border-bottom: $border-half $grey-l;
      padding: $aria-one-tenth 0;

      &.selected a,
      a:hover {
        border-left: $border $orange;
        color: $blue;
        font-weight: $semi-bold;
      }

      a {
        padding-left: $aria-half;
      }
    }
  }

  .menu-arrow {
    cursor: pointer;
    fill: $blue;
    float: right;

    &:hover {
      fill: $orange;
    }

    svg {
      width: $aria-two-thirds;
    }

    &-back {
      float: left;
      transform: scaleX(-1);
    }
  }

  &__main-nav-mobile {
    background-color: $white;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;

    &-element--parent {
      @include vpadding($aria-half, $aria-half);
      @include media("<=s") {
        @include hpadding($aria-two-thirds, $aria-two-thirds);
      }

      @include media("<=xs") {
        @include hpadding($aria-one-third, $aria-one-third);
      }

      > a {
        @include media("<=s") {
          margin-left: $aria-one-and-half;
        }

        @include media("<=xs") {
          margin-left: $aria-one-third;
        }
        color: $blue;
      }

      &.home-link > a {
        margin-left: 0;
      }

      border-bottom: $border-half $grey-l;
      display: block;
      line-height: 1;
    }

    &-top,
    &-element,
    &-sub-menu-mobile-element,
    &-third-level-menu-element {
      @include vpadding($aria-one-third, $aria-one-third);

      @include media("<=s") {
        @include hpadding(0, $aria-two-thirds);

        > a {
          padding-left: $aria-two-thirds;
        }
      }

      @include media("<=xs") {
        @include hpadding(0, $aria-one-third);

        > a {
          padding-left: $aria-one-third;
        }
      }
      border-bottom: $border-half $grey-l;
      display: flex;

      &.selected > a,
      &.ancestor > a {
        border-left: $border $orange;
        font-weight: $semi-bold;
      }
    }

    &-top {
      @include media("<=s") {
        padding-left: $aria-two-thirds;
      }

      @include media("<=xs") {
        padding-left: $aria-one-third;
      }
    }

    &-close {
      @include close-icon($aria, -$aria-two-thirds, -7px);
      color: $blue;
      margin-left: auto;
      margin-right: $aria;
      margin-top: $aria-one-sixth;
    }

    &-submenu-container,
    &-third-level-menu-container {
      display: none;
    }

    &-element,
    &-sub-menu-mobile-element,
    &-third-level-menu-element {
      @include vpadding($aria-half, $aria-half);
      display: block;
      line-height: 1;

      a {
        color: $blue;
      }
    }

    .product-menu-mobile {
      overflow-y: auto;

      &__category,
      &__element {
        @include vpadding($aria-one-third, $aria-one-third);
        border-bottom: $border-half $grey-l;
        display: flex;
      }

      &__category {
        @include media("<=s") {
          @include hpadding($aria-two-thirds, $aria-two-thirds);
        }

        @include media("<=xs") {
          @include hpadding($aria-one-third, $aria-one-third);
        }

        color: $blue;
      }

      &__element {
        @include media("<=s") {
          > a {
            padding-left: $aria-one-and-half;
          }
        }

        @include media("<=xs") {
          > a {
            padding-left: $aria-two-thirds;
          }
        }

        &.selected > a,
        &.ancestor > a {
          border-left: $border $orange;
          font-weight: $semi-bold;
        }

        > a {
          color: $blue;
        }
      }
    }

    .header__languages {
      display: block;

      &-list {
        justify-content: flex-start;
      }

      &-element {
        margin-bottom: 0;
      }
    }
  }
}
