.card {
  @include media("<=s") {
    margin-bottom: $gutter-half;
  }
  @include media("<=xs") {
    flex-direction: row;
    margin-bottom: $gutter-one-third;
  }
  display: flex;
  flex-direction: column;
  margin-bottom: $gutter;

  &.centered {
    .card__info-title {
      text-align: center;
    }
  }

  &__new-icon {
    position: relative;

    svg {
      @include media("<=xs") {
        height: 2em;
        margin-top: 0.5em;
        width: 2em;
      }

      margin-left: 0.5em;
      position: absolute;
      width: 4em;
    }
  }

  &__image {
    @include media("<=xs") {
      width: 50%;
    }
  }

  &__info {
    @include media("<=xs") {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
    }
    background: $bg-light-grey;
    flex-grow: 1;
    padding: $gutter-two-thirds $gutter;

    &-title a {
      color: $blue;
    }

    &-title > .truncate {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      white-space: normal;
      height: 2.5em;
    }

    &-title {
      position: relative;
      display: inline-block;

      &-tooltip {
        display: none;
        position: absolute;
        background-color: #333;
        font-size: 12px;
        color: #fff;
        border-radius: 5px;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        visibility: hidden;
        text-align: center;
        padding: 5px;
        bottom: 125%;
        opacity: 0;
        transition: opacity 0.3s;
        width: 100%;
      }

      &-tooltip::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }
    }

    &-title:hover > &-title-tooltip {
      display: block;
    }

    &-title:hover &-title-tooltip {
      visibility: visible;
      opacity: 1;
    }

    &-link a {
      color: $grey-d-1;
    }

    &__image {
      @include media("<=xs") {
        width: 50%;
      }
    }
  }

  &--horizontal {
    @include media("<=xs") {
      flex-direction: column;
    }
    flex-direction: row;

    .card__image {
      @include media("<=xs") {
        flex: 1 0 0;
        width: 100%;
      }
      flex: 33% 0 0;

      a {
        background: $bg-light-grey;
        display: flex;
        height: 100%;

        img {
          @include media("<=xs") {
            padding-right: $gutter;
            width: 100%;
          }
          margin-bottom: auto;
          padding-left: $gutter;
          padding-top: $gutter-two-thirds;
        }
      }
    }

    .card__info {
      @include media("<=xs") {
        width: 100%;
      }
    }
  }
}

.rca-styling {
  .card-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 16px;

    .card__hover {
      &-description {
        width: 100%;
      }
    }

    &:nth-of-type(3n - 1) {
      padding-left: 10px;
      padding-right: 10px;
    }

    &:nth-of-type(3n) {
      padding-left: 10px;
      padding-right: 15px;
    }

    &:nth-of-type(3n - 2) {
      padding-left: 15px;
      padding-right: 10px;
    }

    @include media("<=tablet") {
      margin-bottom: 15px;
      width: 50%;

      &:nth-of-type(2n - 1) {
        padding-left: 20px;
        padding-right: 10px;
      }

      &:nth-of-type(2n) {
        padding-left: 10px;
        padding-right: 20px;
      }
    }

    @include media("<=xs") {
      margin-bottom: 15px;
      width: 100%;

      &:nth-of-type(3n - 1) {
        padding-left: 20px;
        padding-right: 20px;
      }

      &:nth-of-type(3n) {
        padding-left: 20px;
        padding-right: 20px;
      }

      &:nth-of-type(3n - 2) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    @include media(">tablet") {
      width: 33.333%;
    }

    .card {
      flex: 1 0 auto;
      flex-direction: column;
      width: 100%;
      position: relative;

      &__new-icon {
        svg {
          @include media("<=tablet") {
            height: auto;
            margin-left: -50px;
            margin-top: -40px;
            width: auto;
          }

          margin-left: -50px;
          margin-top: -25px;
          position: absolute;
          width: auto;
        }
      }

      &__hover {
        &-description {
          display: none;
          position: absolute;
          top: 0;
          padding: 65px 24px 20px 24px;
          border-radius: 10px;
          backdrop-filter: blur(10px);
          height: fit-content;
          min-height: 100%;
          opacity: 0.94;
          z-index: 2;

          @include media("<=s") {
            padding: 20px;
            font-size: 14px;
          }

          &--blue-with-pink-background {
            background: linear-gradient(107.41deg, rgba(27, 80, 142, 0.9) -9.55%, rgba(203, 0, 61, 1) 121.31%);
          }

          &--blue-with-green-background {
            background: linear-gradient(106.33deg, rgba(27, 80, 142, 0.9) 3.39%, #64b445 110.72%);
          }

          ul {
            padding-left: 15px;
          }

          &--title {
            margin-bottom: 29px;
          }

          &--title,
          ul > li,
          p {
            color: $grey-v-l;
          }
        }
      }
    }

    &:hover {
      .card__hover-description {
        display: block;
      }
    }
  }

  .card {
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    padding: 0;

    &__info {
      margin-bottom: 20px;
      padding: 15px 20px 40px 20px;
      position: relative;

      @include media("<=xs") {
        width: 100%;
      }

      &-title {
        margin-bottom: 7px;
      }

      &-title a {
        color: $blue;
      }

      &-link a {
        color: $grey-d-1;
      }

      .card__link-more {
        a {
          color: $blue;
        }

        &:hover {
          a {
            color: $blue-l;
          }
        }
      }
    }

    &__link-more {
      bottom: 0;
      position: absolute;

      @include media("<=xs") {
        display: none;
      }

      a {
        position: relative;
      }

      .icon-arrow-right {
        background-color: $blue;
        background-image: url("/static/img/icons/arrow-right-small.svg");
        height: 20px;
        margin-left: 9px;
        mask: url("/static/img/icons/arrow-right-small.svg") no-repeat center;
        position: absolute;
        right: -26px;
        top: 2px;
        width: 20px;
      }

      &:hover {
        .icon-arrow-right {
          background-color: $blue-l;
        }
      }
    }

    &__image {
      @include media("<=xs") {
        width: 100%;
      }

      img {
        border-radius: 10px 10px 0 0;
        height: 187px;
        object-fit: cover;
        overflow: hidden;
        width: 100%;
      }
    }

    @include media("<=xs") {
      display: block;
      flex-direction: unset;
      margin-bottom: $gutter-one-third;
    }

    &__info,
    &__info-link {
      background: transparent;
    }
  }

  .section--rca-styling-blue-with-pink-gradient {
    .card__link-more {
      a {
        color: $grey-v-l;
      }

      &:hover {
        a {
          color: $blue-d;

          .icon-arrow-right {
            background-color: $blue-d;
          }
        }
      }
    }
    .icon-arrow-right {
      background-color: $grey-v-l;
    }
  }
}
