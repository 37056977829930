.section {
  @include media("<=s") {
    padding: $gutter-two-thirds * 2 0;
  }
  @include media("<=xs") {
    padding: $gutter-two-thirds 0;
  }
  padding: $gutter-double 0;

  &--blue {
    background-color: $bg-light-blue;

    .visibility-button {
      align-self: flex-end;
    }
  }

  &--homepage-boxes {
    @extend .section--blue;
    @include media("<=m") {
      padding: $gutter-double 0;
    }
    @include media("<=xs") {
      padding: $gutter-two-thirds 0;
    }
    padding: $gutter-quadruple 0;

    .grid {
      @include media("<=s") {
        * + * {
          margin-top: $aria-two-thirds;
        }
      }
    }
  }

  &--grey {
    background-color: $bg-light-grey;

    + .section--grey {
      border-top: $border $white;
    }
  }

  &--intro-image {
    @include header-image();
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    z-index: -1;
  }

  &--sample-order {
    padding: $gutter 0;
  }

  &--product--no-image {
    @include media("<=s") {
      @include vpadding($gutter-double, $gutter-two-thirds);
    }
    @include media("<=xs") {
      padding-top: 0;
    }
    padding-top: $gutter-triple;

    & + .section {
      padding-top: 0;
    }
  }

  &--downloads,
  &--purchase,
  &--formulations {
    .grid {
      justify-content: center;
    }
  }

  &__title {
    @include section-title();

    &--no-capitalize {
      text-transform: none;
    }
  }

  &__subtitle {
    color: $blue;
    margin-bottom: $aria;
  }

  .section-news-block {
    margin-bottom: 40px;

    .section__description {
      color: #2a2a2a;
      font-size: 20px;
      font-weight: 300;
      padding: 0 20px 0 20px;
    }
  }
}

.rca-styling {
  h1:first-of-type {
    @include media("<=tablet") {
      padding-top: 50px;
    }
  }

  .block-2 {
    margin-top: 0;

    & > * {
      &:nth-child(n + 1) {
        margin-top: 0;
      }
    }
  }

  .publications-section {
    @include media("<=tablet") {
      margin-top: 40px;
    }

    .xs-24.document-item:nth-of-type(even) {
      padding-left: 15px;
      padding-right: 10px;

      @include media("<=tablet") {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .xs-24.document-item:nth-of-type(odd) {
      padding-left: 10px;
      padding-right: 15px;

      @include media("<=tablet") {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .events-section {
    @include media("<=tablet") {
      &.section {
        padding-top: 70px;
        margin-top: 50px;
        padding-bottom: 82px;
      }
    }

    .visibility-button {
      &:hover {
        color: $blue-d;

        svg {
          path {
            fill: $blue-d;
          }
        }
      }
    }
  }

  .section {
    &--rca-styling-blue {
      background-image: url("/static/img/rca/rca-blue-gradient.svg");
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 120px;
      background-position-y: bottom;
    }

    &--rca-styling-blue-with-pink-gradient {
      background-image: url("/static/img/rca/rca-blue-with-pink-gradient.svg");
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 120px;
      padding-top: 120px;
      background-position-y: bottom;
      height: fit-content;

      @include media("<=tablet") {
        &.section {
          padding-top: 76px;
          padding-bottom: 58px;
        }
      }
    }

    &--rca-styling-blue-with-green-gradient {
      background-image: url("/static/img/rca/rca-blue-with-green-gradient.svg");
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 120px;
      background-position-y: bottom;
      height: fit-content;

      .blue-text {
        color: #fff;

        a {
          color: #fff;
        }
      }
    }
  }

  .section-news-block {
    margin-bottom: 42px;

    b,
    strong {
      color: $btn-blue-l;
      font-weight: $bold;
    }

    .section__title {
      font-weight: $semi-bold;
      margin-bottom: 21px;
      text-align: left;

      @include media("<=tablet") {
        font-weight: $medium;
        margin-bottom: 18px;
        font-size: 25px;
        line-height: 36px;
      }
    }

    .section__description {
      font-weight: $normal;
      letter-spacing: 0.015em;
      line-height: 28px;
      padding: 0;
      width: 40%;

      @include media("<=tablet") {
        width: 100%;
        p {
          font-weight: $light;
          line-height: 24px;
          font-size: 18px;
        }
      }

      p {
        text-align: left;
      }
    }
  }

  .section.contact-person {
    padding: 56px 0 38px 0;

    &:not(:has(.grid-25)) {
      display: none;
    }

    @include media("<m") {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include media("<=s") {
      padding: 64px 20px 57px 20px;
    }

    .grid-75 {
      padding: 0;
    }
  }

  .section {
    padding: 100px 0 80px 0;

    @include media("<=tablet") {
      padding: 20px 0;
    }
  }

  .section-download {
    background-color: transparent;
  }
  .section-download {
    .document-item--documentation {
      @include media("<=tablet") {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        margin-right: 10px;
        margin-left: 20px;
      }
    }
  }

  .rca-styling-contact-person {
    display: none;
  }
}

.rca-styling.press-releases-section {
  padding-bottom: 62px;

  .slider-wrapper {
    @include media(">tablet") {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include media("<=tablet") {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @include media("<=tablet") {
    margin-top: 42px;
  }

  .grid {
    display: block;
  }

  .document-item__container {
    border: 1px solid #282828;
    border-radius: 10px;
    height: 100%;
    box-shadow: unset;
  }
}

.section--rca-styling-blue,
.section--rca-styling-blue-with-pink-gradient {
  .blue-text {
    color: #fff;

    a {
      color: #fff;
      text-decoration: none;
      font-weight: 600;
    }
  }

  .section-news-block {
    .section__title {
      color: $white;
    }

    .section__description {
      p {
        color: $white;
      }
    }
  }

  .events-section {
    @include media("<=tablet") {
      margin-top: 50px;
    }

    .section-news-block {
      margin-bottom: 54px;
      .section__title {
        margin-bottom: 30px;
      }
    }

    .visibility-button {
      &:hover {
        color: $blue-d;

        svg {
          path {
            fill: $blue-d;
          }
        }
      }
    }
  }

  .text-plugin {
    h2 {
      color: $grey-v-l;
      font-size: 48px;
      font-weight: $bold;
      font-family: "GT America";
      line-height: 65px;
      margin-bottom: $gutter-double;
      width: 54%;

      @include media("<=tablet") {
        color: $grey-v-l;
        font-size: 25px;
        line-height: 36px;
        margin-bottom: $gutter;
        width: 100%;
      }
    }
  }
}

// sass-lint:disable no-ids, id-name-format
#section-contact-person {
  .grid .grid {
    margin-left: 0;
  }
}

.category__list {
  #section-contact-person {
    .grid .grid {
      margin-left: -$gutter-half;
    }
  }
}

// sass-lint:enable no-ids, id-name-format

.section-grey {
  @extend .section;
  @extend .section--grey;
}

.section-blue {
  @extend .section;
  @extend .section--blue;
}

.section-download {
  @extend .section;
  @extend .section--grey;
  @extend .section--downloads;
}

@mixin block-element($items) {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;

  & > * {
    @include hpadding($gutter-half, $gutter-half);
    flex: calc(100% / #{$items});
    max-width: calc(100% / #{$items});

    &:nth-child(#{$items}n + 1) {
      @include media(">m") {
        padding-left: 0;
      }
    }

    &:nth-child(#{$items}n + 0) {
      @include media(">m") {
        padding-right: 0;
      }
    }

    &:nth-child(n + #{$items + 1}) {
      @include h-spacer;

      @include media("<=m") {
        margin-top: 0;
      }
    }

    &:nth-child(n + #{$items - 2}) {
      @include media("<=m") {
        margin-top: $aria-two-thirds * 2;
      }
    }

    &:nth-child(n + 2) {
      @include media("<=s") {
        margin-top: $aria-two-thirds;
      }
    }
  }

  & + .text-plugin,
  & + .grid,
  & + img {
    @include h-spacer;
  }

  .grid {
    margin-left: 0;
    width: 100%;

    .employee__wrapper--vertical {
      @include hpadding(0, 0);
    }
  }
}

.block-2 {
  @include block-element(2);

  & > * {
    @include media("<tablet") {
      flex: 100%;
      max-width: 100%;
      padding: 0;

      &:nth-child(n + 1) {
        @include h-spacer;
      }
    }

    @include media(">=tablet") {
      &:nth-child(2) {
        margin-top: 0 !important; // sass-lint:disable-line no-important
      }
    }
  }

  .employee__wrapper--vertical {
    width: 100%;
  }
}

.block-3 {
  @include block-element(3);

  & > * {
    @include media("<tablet") {
      flex: 100%;
      padding: 0;

      &:nth-child(n + 1) {
        @include h-spacer;
      }
    }

    @include media(">=tablet") {
      flex: 1 1 0;

      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 0 !important; // sass-lint:disable-line no-important
      }
    }

    max-width: inherit;
  }
}

.block-5 {
  @include block-element(5);

  & > * {
    @include media(">m") {
      @include hpadding($gutter-quarter, $gutter-quarter);
      flex: 100%;

      &:nth-child(-n + 5) {
        margin-top: 0 !important; // sass-lint:disable-line no-important
      }

      &:nth-child(5n + 1) {
        padding-left: $gutter-quarter;
      }

      &:nth-child(5n + 0) {
        padding-right: $gutter-quarter;
      }
    }

    @include media("<=m", ">tablet") {
      flex: 100%;
      max-width: calc(100% / 3);

      &:nth-child(-n + 3) {
        margin-top: 0 !important; // sass-lint:disable-line no-important
      }

      &:nth-child(3n) {
        padding-right: 0;
      }

      &:nth-child(3n + 2) {
        @include hpadding($gutter-quarter, $gutter-quarter);
      }

      &:nth-child(3n + 1) {
        padding-left: 0;
      }
    }

    @include media("<=tablet", ">xs") {
      flex: 100%;
      max-width: calc(100% / 2);

      &:nth-child(-n + 2) {
        margin-top: 0 !important; // sass-lint:disable-line no-important
      }

      &:nth-child(2n + 1) {
        padding-left: 0;
      }

      &:nth-child(2n) {
        padding-right: 0;
      }
    }

    @include media("<=xs") {
      flex: 100%;
      max-width: 100%;
      padding: 0;

      &:nth-child(n + 1) {
        @include h-spacer;
      }
    }
  }

  max-width: inherit;
}

.block-6 {
  @include block-element(6);

  & > * {
    @include media(">m") {
      @include hpadding($gutter-quarter, $gutter-quarter);
      flex: 100%;

      &:nth-child(-n + 6) {
        margin-top: 0 !important; // sass-lint:disable-line no-important
      }

      &:nth-child(6n + 1) {
        padding-left: $gutter-quarter;
      }

      &:nth-child(6n + 0) {
        padding-right: $gutter-quarter;
      }
    }

    @include media("<=m", ">tablet") {
      flex: 100%;
      max-width: calc(100% / 3);

      &:nth-child(-n + 3) {
        margin-top: 0 !important; // sass-lint:disable-line no-important
      }

      &:nth-child(3n) {
        padding-right: 0;
      }

      &:nth-child(3n + 2) {
        @include hpadding($gutter-quarter, $gutter-quarter);
      }

      &:nth-child(3n + 1) {
        padding-left: 0;
      }
    }

    @include media("<=tablet", ">xs") {
      flex: 100%;
      max-width: calc(100% / 2);

      &:nth-child(-n + 2) {
        margin-top: 0 !important; // sass-lint:disable-line no-important
      }

      &:nth-child(2n + 1) {
        padding-left: 0;
      }

      &:nth-child(2n) {
        padding-right: 0;
      }
    }

    @include media("<=xs") {
      flex: 100%;
      max-width: 100%;
      padding: 0;

      &:nth-child(n + 1) {
        @include h-spacer;
      }
    }
  }

  max-width: inherit;
}

[class^="block-"] + [class^="block-"] {
  @include h-spacer;
}

.grid {
  & + .grid,
  & + .text-plugin,
  & + [class^="block-"],
  & + img {
    @include h-spacer;
  }
}

.grid-15 {
  @extend .xs-24;
  @extend .s-6;
  @extend .m-4;
  @include style-grid();
}

.grid-25 {
  @extend .xs-24;
  @extend .s-6;
  @include style-grid();
}

.grid-33 {
  @extend .xs-24;
  @extend .s-8;
  @include style-grid();
}

.grid-50 {
  @extend .xs-24;
  @extend .s-12;
  @include style-grid();
}

.grid-75 {
  @extend .xs-24;
  @extend .s-18;
  @include style-grid();
}

.grid-85 {
  @extend .xs-24;
  @extend .s-18;
  @extend .m-20;
  @include style-grid();
}

.grid-100 {
  @extend .xs-24;
  @include style-grid();
}

.grid-narrow {
  @extend .xs-24;
  @extend .s-24;
  @extend .m-16;
  @include style-grid();

  margin: 0 auto;

  ul {
    @include list-style();
  }
}

.grid-25-with-border {
  @include style-grid();
  width: 25%;
  border-right: 1px solid $blue-d;
  padding-top: $aria-one-third;
  padding-right: 40px;
  padding-bottom: 20px;

  .blue-text {
    display: block;
  }

  @include media("<=tablet") {
    border-right: unset;
    width: 100%;
    border-bottom: 1px solid #153d6b;
    padding-bottom: 34px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 24px;

    &:last-of-type {
      border-bottom: none;
    }

    p > span {
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.015em;
    }

    .blue-text,
    .blue-text a {
      font-size: 20px;
      line-height: 28px;
      font-weight: 400;
      letter-spacing: 0.015em;
    }
  }
}

.grid-25-with-border:after {
  border-bottom: 1px solid $blue-d;
  contain: unset;
  display: inline-block;
  left: 0;
  position: absolute;
  top: 0;
  width: 50%;
}

.grid-25-with-border:last-of-type {
  border-right: unset;
}

.grid-25-with-border:first-of-type {
  padding-left: 20px;
}

.grid-25-with-border:not(:first-of-type) {
  padding-left: 33px;

  @include media("<=tablet") {
    padding-left: 20px;
  }
}

.tiles-small__container {
  @include media("<=tablet") {
    img {
      width: 100%;
    }
  }

  @include media("<=xs") {
    .card__image,
    .card__info {
      width: 100%;
    }
  }

  display: flex;
  flex: 100%;
  flex-direction: column;
}
