// Settings for the Jessica grid
// Jessica breakpoints look like:
//
// |----------|---------|---------|---------|---------- ...
// |<---XS--->|<---S--->|<---M--->|<---L--->|<---XL---- ...

/**
 * The base padding Jessica uses for spacing!
 * This gutter is to space the columns by Jessica.
 * Do not use this as a general spacing measure!
 *
 * @nuclide
 * @section Settings > Grid
 */
$gutter: 30px !default;

/**
 * It is possible to override columns gutter for xs size.
 * This gutter is valid only for xs size.
 *
 * @nuclide
 * @section Settings > Grid
 */
$gutter-xs: 30px !default;

/**
 * It is possible to override columns gutter for s size.
 * This gutter is valid only for s size.
 *
 * @nuclide
 * @section Settings > Grid
 */
$gutter-s: 30px !default;

/**
 * It is possible to override columns gutter for m size.
 * This gutter is valid only for m size.
 *
 * @nuclide
 * @section Settings > Grid
 */
$gutter-m: 30px !default;

/**
 * It is possible to override columns gutter for l size.
 * This gutter is valid only for s size.
 *
 * @nuclide
 * @section Settings > Grid
 */
$gutter-l: 30px !default;

/**
 * It is possible to override columns gutter for xl size.
 * This gutter is valid only for s size.
 *
 * @nuclide
 * @section Settings > Grid
 */
$gutter-xl: 30px !default;

/**
 * Variation of base padding: $padding * 2
 *
 * @nuclide
 * @section Settings > Grid
 */
$gutter-double: $gutter * 2;

/**
 * Variation of base padding: $padding * 3
 *
 * @nuclide
 * @section Settings > Grid
 */
$gutter-triple: $gutter * 3;

/**
 * Variation of base padding: $padding * 4
 *
 * @nuclide
 * @section Settings > Grid
 */
$gutter-quadruple: $gutter * 4;

/**
 * Variation of base padding: $padding / 2
 *
 * @nuclide
 * @section Settings > Grid
 */
$gutter-half: $gutter / 2;

/**
 * Variation of base padding: 1/4 of $padding
 *
 * @nuclide
 * @section Settings > Grid
 */
$gutter-quarter: $gutter / 4;

/**
 * Variation of base padding: 3/4 of $padding
 *
 * @nuclide
 * @section Settings > Grid
 */
$gutter-three-quarters: $gutter * 3 / 4;

/**
 * Variation of base padding: 1/3 of $padding
 *
 * @nuclide
 * @section Settings > Grid
 */
$gutter-one-third: $gutter / 3;

/**
 * Variation of base padding: 2/3 of $padding
 *
 * @nuclide
 * @section Settings > Grid
 */
$gutter-two-thirds: $gutter * 2 / 3;

/**
 * Variation of base padding: 1/6 of $padding
 *
 * @nuclide
 * @section Settings > Grid
 */
$gutter-one-sixth: $gutter / 6;

/**
 * The size of the extra small viewport
 *
 * @nuclide
 * @section Settings > Grid
 */
$screen-xs: 480px;

/**
 * The size of the tablet viewport, used for .block style plugins
 *
 * @nuclide
 * @section Settings > Grid
 */
$screen-tablet: 640px;

/**
 * The size of the small viewport
 *
 * @nuclide
 * @section Settings > Grid
 */
$screen-s: 1024px;

/**
 * The size of the medium viewport
 *
 * @nuclide
 * @section Settings > Grid
 */
$screen-m: 1200px;

/**
 * The size of the large viewport
 *
 * @nuclide
 * @section Settings > Grid
 */
$screen-l: 1300px;

/**
 * The size of the extra large viewport
 *
 * @nuclide
 * @section Settings > Grid
 */
$screen-xl: 1440px;

/**
 * The limit of the content
 *
 * @nuclide
 * @section Settings > Grid
 */
$content-limit: 960px;

/**
 * The limit of the content
 *
 * @nuclide
 * @section Settings > Grid
 */
$page-header-limit: 1084px;

/**
 * Breakpoint configuration
 * Defines the recognized include-media breakpoints.
 * By default the basic `xs`, `s`, `m`, `l`, `xl` are provided, but you can redefine `$breakpoints` in the project to override them
 *
 * @nuclide
 * @section Settings > Media queries
 */
$breakpoints: (
  "xs": $screen-xs,
  "s": $screen-s,
  "tablet": $screen-tablet,
  "m": $screen-m,
  "l": $screen-l,
  "xl": $screen-xl,
  "content": $content-limit,
) !default;

/**
 * The width of the grid when in the extra small viewport
 *
 * @nuclide
 * @section Settings > Grid
 */
$grid-xs: 100%;

/**
 * The width of the grid when in the small viewport
 *
 * @nuclide
 * @section Settings > Grid
 */
$grid-s: 100%;

/**
 * The width of the grid when in the medium viewport
 *
 * @nuclide
 * @section Settings > Grid
 */
$grid-m: 96%;

/**
 * The width of the grid when in the large viewport
 *
 * @nuclide
 * @section Settings > Grid
 */
$grid-l: 96%;

/**
 * The width of the grid when in the extra large viewport
 *
 * @nuclide
 * @section Settings > Grid
 */
$grid-xl: 1280px;

/////////////////////////////////////////////////
// Private

// Number of columns

$xs-grid: 24 !default; // Number of columns in the extra small grid
$s-grid: 24 !default; // Number of columns in the small grid
$m-grid: 24 !default; // Number of columns in the medium grid
$l-grid: 24 !default; // Number of columns in the large grid
$xl-grid: 24 !default; // Number of columns in the extra large grid
