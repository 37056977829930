@import "grid/00.grid.settings";
@import "grid/lib.include-media";
@import "grid/01.grid.generator";
@import "grid/02.grid.utilities";

//@import 'grid/99.grid.debug'; // Uncomment for debug check

@import "00_settings/global";

@import "01_tools/mixins";

@import "02_elements/reset";
@import "02_elements/general";
@import "02_elements/typography";

@import "03_components/forms";
@import "03_components/buttons";
@import "03_components/breadcrumbs";
@import "03_components/tabs";
@import "03_components/header";
@import "03_components/search";
@import "03_components/footer";
@import "03_components/products";
@import "03_components/documents";
@import "03_components/sections";
@import "03_components/contacts";
@import "03_components/home";
@import "03_components/texts";
@import "03_components/suppliers";
@import "03_components/content";
@import "03_components/slider";
@import "03_components/card";
@import "03_components/cookiebanner";
@import "03_components/messages";
@import "03_components/jobs";
@import "03_components/video";
@import "03_components/checkout";
@import "03_components/product_overview";
@import "03_components/add_to_cart_product";
@import "03_components/map_with_employees";
@import "03_components/tiles_slider";
@import "03_components/lead_magnet_form";
@import "03_components/before_after_slider";

@font-face {
  font-family: "GT America";
  src: url("/static/fonts/GT-America-Extended-Light.woff2");
}

@font-face {
  font-family: "GT America Medium";
  src: url("/static/fonts/GT-America-Extended-Medium.woff2");
}
