.rca-styling {
  .product-overview {
    &__header {
      @include media("<=tablet") {
        &.section--rca-styling-blue-with-pink-gradient {
          padding: 37px 20px 42px 20px;
        }
      }

      .grid {
        position: relative;
        border: 2px solid $grey-v-l;
        border-radius: 20px;
        height: 376px;
        overflow: unset;
        padding: $aria-one-and-half;

        .content-page--intro-image__border {
          @include media("<=tablet") {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
        }

        @include media("<=tablet") {
          padding: 97px 27px 42px 27px;
        }

        @include media("<=tablet") {
          height: 634px;
          border: unset;
        }

        @include media(">=xs") {
          width: calc(100% - 30px);
        }

        @include media(">=m") {
          width: calc(96% - 30px);
        }

        @include media(">=xl") {
          width: 1250px;
        }

        .text-plugin {
          margin: 0;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);

          @include media("<=tablet") {
            left: unset;
            position: relative;
            top: unset;
            transform: unset;

            h1 {
              padding-top: 0;
              font-size: 48px;
              line-height: 65px;
              font-weight: 300;
              margin-bottom: 25px;
            }

            p {
              font-size: 18px;
              line-height: 24px;
              font-weight: 300;
            }
          }
        }
      }

      &--bigger-image {
        border-radius: 0;
        bottom: -5%;
        position: absolute;
        right: 20%;
        height: 254px;
        z-index: 1;

        @include media("<=tablet") {
          bottom: -47px;
          left: -22px;
          max-width: unset;
          width: calc(100% + 44px);
          height: auto;
          max-height: 65%;
        }
      }

      &--smaller-image {
        border-radius: 0;
        position: absolute;
        right: 5%;
        top: -5%;
        height: 161px;

        @include media("<=tablet") {
          display: none;
        }
      }
    }

    .search-container {
      background-color: transparent;
    }
  }

  .product-list {
    .card-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;

      @include media("<=xs") {
        margin-bottom: 15px;
        width: 50%;
        &:nth-of-type(2n - 1) {
          padding-left: 20px;
          padding-right: 10px;
        }

        &:nth-of-type(2n) {
          padding-left: 10px;
          padding-right: 20px;
        }
      }

      .card {
        width: 100%;
      }
    }

    .card {
      border-radius: 10px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);

      &__image {
        img {
          border-radius: 10px 10px 0 0;
          height: 187px;
          object-fit: cover;
          overflow: hidden;
          width: 100%;
        }
      }

      &__info {
        @include media("<=xs") {
          width: 100%;
        }
      }

      &__info,
      &__info-link {
        background: transparent;
      }

      &__link-more {
        @include media("<=xs") {
          display: none;
        }

        .icon-arrow-right {
          margin-left: 9px;
          position: absolute;
        }
      }
    }
  }

  .product-cards {
    &__overview {
      .search-input {
        @include media("<=xs") {
          padding-bottom: 10px;
        }
      }
    }
  }
}
