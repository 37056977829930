@mixin text-styles() {
  h1,
  h2,
  h3,
  h4 {
    @include media("<=m") {
      margin-bottom: $aria-half;
    }
    color: $blue;
    margin-bottom: $aria-two-thirds;
  }

  p {
    color: $grey-d;

    & + * {
      @include responsive-margin-top($aria-two-thirds, $aria-half, $aria-one-third);
    }
  }

  ul,
  ol {
    @include list-style();

    & + * {
      @include responsive-margin-top($aria-two-thirds, $aria-half, $aria-one-third);
    }
  }

  a {
    @include link-style();
  }

  table {
    width: 100% !important; // sass-lint:disable-line no-important

    img {
      width: auto;
    }

    td + td {
      @include media("<=xs") {
        padding-bottom: $aria-one-third;
        padding-left: $aria-half;
      }
      padding-bottom: $aria-half;
      padding-left: $aria;
    }

    &.fixed-table {
      table-layout: fixed;

      td + td {
        padding-left: 0;
      }
    }
  }

  .blue-text,
  .blue-text a {
    color: $blue;
    font-weight: 600;
  }

  .white-text {
    color: #fff;
    font-weight: 600;
    font-size: 16px;

    a {
      font-size: 16px;
    }
  }
}

.blue-text,
.blue-text a {
  color: $blue;
  font-weight: 600;
}

.white-text {
  color: #fff;
  font-weight: 600;
  font-size: 16px;

  a {
    font-size: 16px;
  }
}

.normal-text {
  letter-spacing: 0.015em;
}

.text-plugin {
  @include text-styles();
}

.text-plugin {
  & + .text-plugin,
  & + .grid,
  & + [class^="block-"],
  & + img,
  & + form {
    @include h-spacer;
  }
}

.lead-text {
  @extend .m-16;
  @extend .s-24;
  @extend .xs-24;
  @include text-styles();
  @include media("<=s") {
    margin: $aria-two-thirds * 2 auto;
  }
  @include media("<=xs") {
    margin: $aria-two-thirds auto;
  }
  margin: $aria-double auto;
}

.rca-styling {
  p {
    color: $grey-d-1;
  }

  .text-plugin {
    h1 {
      margin-bottom: 36px;
    }

    a {
      color: $btn-blue-l;

      &:hover {
        color: $blue-d;
      }
    }
  }

  .arrow-link {
    border: 1px solid $btn-blue-l;
    border-radius: 16px;
    color: $btn-blue-l;
    font-size: 16px;
    font-weight: $semi-bold;
    padding: 11px 13px;

    &:hover {
      color: $blue-d;
      border: 1px solid $blue-d;
    }

    svg {
      display: none;
    }
  }

  .white-text {
    h1,
    p {
      color: $grey-v-l;
      margin-bottom: 0;
    }
  }

  .card__link-more > a {
    color: #153d6b;
    font-weight: $semi-bold;
  }

  .blue-text {
    u {
      text-decoration-color: $btn-blue-l;
    }

    u:hover {
      text-decoration-color: #153d6b;
    }

    a {
      font-size: 18px;
    }

    span {
      color: $btn-blue-l;
    }

    span:hover {
      text-decoration-color: #153d6b;
    }
  }

  .white-text {
    u {
      text-decoration-color: #fff;
    }

    u:hover {
      text-decoration-color: #fff;
    }

    a {
      font-size: 16px;
      color: #fff;
    }

    span {
      color: #fff;
    }
  }
}

.bullet-list-with-border {
  .text-plugin ul li:before {
    background: #353535;
    height: 10px;
    width: 10px;
  }

  li {
    border-bottom: 1px solid $blue;
    padding: $aria 0 $aria $aria-double;

    @include media("<=tablet") {
      padding: 17px 0 17px $aria;
    }
  }

  li:first-child {
    border-top: 1px solid $blue;
  }

  ul {
    margin-left: 0;
    padding-bottom: $aria-two-thirds;
  }

  a {
    border: 1px solid $btn-blue-l;
    border-radius: 16px;
    color: $btn-blue-l;
    padding: 11px 13px;
  }

  u {
    text-decoration: none;
  }

  @include media("<=tablet") {
    margin-bottom: 90px;
  }
}
