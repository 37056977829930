/**
 * The default blue.
 *
 * @color
 * @section Base Colors
 */
$blue: #1b508e;

/**
 * The dark blue.
 *
 * @color
 * @section Base Colors
 */
$blue-d: #153d6b;

/**
 * The pink.
 *
 * @color
 * @section Base Colors
 */
$pink: #e50064;

/**
 * The default text grey.
 *
 * @color
 * @section Base Colors
 */
$grey-d: #2a2a2a;

/**
 * The default white.
 *
 * @color
 * @section Base Colors
 */
$white: #fff;

/**
 * The dark transparent black.
 *
 * @color
 * @section Base Colors
 */
$black-d-t: rgba(40, 40, 40, 0.7);

/**
 * The transparent black.
 *
 * @color
 * @section Base Colors
 */
$black-t: rgba(0, 0, 0, 0.2);

/**
 * The light grey.
 *
 * @color
 * @section Base Colors
 */
$grey-l: #e1e1e0;

/**
 * The very light grey.
 *
 * @color
 * @section Base Colors
 */
$grey-v-l: #fefefe;

/**
 * The lesser dark grey.
 *
 * @color
 * @section Base Colors
 */
$grey-d-1: #282828;

/**
 * The light transparent grey - Used for input bg.
 *
 * @color
 * @section Base Colors
 */

$grey-l-t: rgba(225, 225, 224, 0.3);

/**
 * The transparent white.
 *
 * @color
 * @section Base Colors
 */
$white-t: rgba(255, 255, 255, 0.9);

/**
 * The lighter transparent white.
 *
 * @color
 * @section Base Colors
 */
$white-l-t: rgba(255, 255, 254, 0.3);

/**
 * The light blue.
 *
 * @color
 * @section Base Colors
 */
$blue-l: #68b4f0;

/**
 * The light blue background.
 *
 * @color
 * @section Base Colors
 */
$bg-light-blue: #d9effb;

/**
 * The default orange.
 *
 * @color
 * @section Base Colors
 */
$orange: #fb6118;

/**
 * The button blue
 *
 * @color
 * @section Base Colors
 */
$btn-blue: #5bc2e7;

/**
 * The button light blue.
 *
 * @color
 * @section Base Colors
 */
$btn-blue-l: #009cdc;

/**
 * The light grey background.
 *
 * @color
 * @section Base Colors
 */
$bg-light-grey: rgba(225, 225, 224, 0.5);

/**
 * The header grey background.
 *
 * @color
 * @section Base Colors
 */
$bg-header-grey: rgba(0, 0, 0, 0.1);

/**
 * The gradient blue with pink.
 *
 * @color
 * @section Base Colors
 */
$gradient-blue-with-pink: linear-gradient(90deg, $blue 0%, #cb003d 100%);

/**
 * The primary font. Usually we use only two different fonts
 *
 * @nuclide Main font family
 * @section Settings > Fonts
 */
$main-font: "Open Sans", sans-serif;

/**
 * The primary font for rca styling.
 *
 * @nuclide Main font family rca styling
 * @section Settings > Fonts
 */
$main-font-rca-styling: "GT America", sans-serif;

/**
 * The secondary font for rca styling.
 *
 * @nuclide Main font family rca styling
 * @section Settings > Fonts
 */
$secondary-font-rca-styling: "GT America Medium", sans-serif;

/**
 * The secondary font. Usually we use only two different fonts
 *
 * @nuclide Secondary font family
 * @section Settings > Fonts
 */
$secondary-font: serif;

/**
 * Font weight
 */

$light: 300;
$normal: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

/**
 * The main line-height. You will rarely need to use this variable.
 *
 * @nuclide Main line height
 * @section Settings > Fonts
 */
$line-height: 1.6;

/**
 * The line-height we use for headings.
 *
 * @nuclide Headings line height
 * @section Settings > Fonts
 */
$line-height-headings: 1.1;

/**
 * The border bold
 *
 */
$border-bold: 3px solid;

/**
 * The default border
 *
 */
$border: 2px solid;

/**
 * The half and half border
 *
 */
$border-half-and-half: 1.5px solid;

/**
 * The half border
 *
 */
$border-half: 1px solid;

/**
 * The border-radius
 *
 */
$border-radius: 3px;

/**
 * The box-shadow - used for input labels so hover won't mess things up
 *
 */
$box-shadow: inset 0 0 1px 1.5px $grey-l;

/**
 * The box-shadow hover
 *
 */
$box-shadow-h: inset 0 0 1px 2px $blue;

/**
 * The outer box shadow
 *
 */
$box-shadow-o: 0 0 7px 0.7px $grey-l;

/**
 * The spacing we use for elements.
 * Use this to space elements inside plugins ecc. on a per-project basis.
 * Use variants with sensible names, please.
 *
 * @nuclide Spacing variable
 * @section Settings > Aria
 */
$aria: 30px;
$aria-one-and-half: $aria * 1.5;
$aria-double: $aria * 2;
$aria-triple: $aria * 3;
$aria-quadruple: $aria * 4;
$aria-half: $aria / 2;
$aria-quarter: $aria / 4;
$aria-three-quarters: $aria * 3 / 4;
$aria-one-sixth: $aria / 6;
$aria-one-tenth: $aria / 10;
$aria-one-third: $aria / 3;
$aria-two-thirds: $aria * 2 / 3;

/**
 * The size used for width and heigh
*/

$size: 30px;
$size-double: $size * 2;
$size-triple: $size * 3;
$size-quadruple: $size * 4;
$size-half: $size / 2;
$size-quarter: $size / 4;
$size-three-quarters: $size * 3 / 4;
$size-one-fifth: $size / 5;
$size-one-sixth: $size / 6;
$size-one-tenth: $size / 10;
$size-one-third: $size / 3;
$size-two-thirds: $size * 2 / 3;
$size-header-boxes: 280px;
$size-cart-icon: 25px;

// Private
// Fonts - Heading sizes
$title-size-1: 48;
$title-size-2: 42;
$title-size-3: 32;
$title-size-4: 28;
$title-size-5: 12;
$title-size-6: 12;

/**
 * Header widths
*/

$logo-width-desktop: 178px;
$logo-width-tablet: 142px;
$logo-width-mobile: 107px;
$hamburger-menu-width: 41.5px;
$stick-size: 2px;
$stick-size-half: 1px;

/**
 * Product header image heights
*/

$header-breakpoint-min-xl: 1701px;
$header-breakpoint-max-l: 1701px;
$header-image-height-xl: 508px;
$header-image-height-l: 450px;
$header-image-height-m: 350px;
$header-image-height-xs: 195px;

/**
 * Search input
*/

$search-input-sizes-desktop: 50px;
$search-input-sizes-tablet: 40px;
$search-input-sizes-mobile: 40px;

/**
 * Colors messages
*/

$messages-muted: #069;
$messages-info: #069;
$messages-error: #d92400;
$messages-warning: #e5d200;
$messages-success: #0c0;
$messages-grey: #3f3e3e;

/**
 * Homepage boxes
*/

$homepage-box-min-height-desktop: 490px;
$homepage-box-min-height-tablet: 295px;

/**
 * Product awards and labels sizes
*/

$product-award-desktop-width: 140px;
$product-label-desktop-width: 65px;
$product-award-tablet-width: 65px;
$product-label-tablet-width: 50px;
$product-award-mobile-width: 50px;
$product-label-mobile-width: 35px;

/**
 * List margins
*/

$list-margin-left: 27px;
$list-dot-margin-left: -21px;
$list-dot-margin-right: 13px;

/**
 * Back to top button
*/
$back-to-top-button-size: 50px;
$back-to-top-button-margin-top: 17px;
