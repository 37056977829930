/**
 * @molecule .btn
 * @section Buttons
 * @modifiers
 *  .btn--primary the primary button style
 *  .btn--secondary the secondary button style
 *  .btn--block a button which goes all width
 *  .btn--small a button with less padding
 * @markup
 *  <button class="btn">button</button>
 *  <a class="btn">button link</a>
 *  <input type="button" class="btn" value="button input">
 *  <br><br>
 *  <button class="btn btn--primary">button primary</button>
 *  <a class="btn btn--primary">button link primary</a>
 *  <input type="button" class="btn btn--primary" value="button input primary">
 *  <br><br>
 *  <button class="btn btn--secondary">button secondary</button>
 *  <a class="btn btn--secondary">button link secondary</a>
 *  <input type="button" class="btn btn--secondary" value="button input secondary">
 *  <br><br>
 *  <button class="btn btn--block">button block</button>
 *  <br><br>
 *  <button class="btn btn--small">button small</button>
 */
.btn {
  -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-family: $main-font;
  font-size: 16px;
  padding: $gutter-three-quarters $gutter-double;
  text-align: center;
  text-decoration: none;
  transition: all 0.15s ease-in-out;

  &:hover {
    text-decoration: none;
  }
}

.btn--primary {
  color: $white;
}

.btn--secondary {
  background: transparent;
  box-shadow: inset 0 0 0 2px;

  &:hover {
    color: $white;
  }
}

.btn--block {
  display: block;
  width: 100%;
}

.btn--small {
  font-size: 14px;
  padding: $gutter-half $gutter-three-quarters;
}

.btn--main-btn {
  align-items: center;
  background-color: $white;
  border: $border-half $btn-blue;
  border-radius: $size;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: $size-one-sixth $size-half;
  position: relative;

  &:hover {
    background: $btn-blue;

    h3 {
      color: $white;
    }

    svg {
      fill: $white;
    }
  }

  &.disabled {
    border-color: $grey-l;

    h3 {
      color: $grey-l;
    }

    svg {
      fill: $grey-l;
    }

    &:hover {
      background: $white;
    }
  }
}

.rca-styling {
  .btn--main-btn {
    background-color: $white;
    border: $border-half $btn-blue-l;
    border-radius: 13px;
    justify-content: flex-end;
    padding: 8px 13px;
    position: relative;

    h3 {
      color: $btn-blue-l;
    }

    svg {
      display: none;
    }

    &:hover {
      background: transparent;

      h3 {
        color: $white;
      }

      svg {
        fill: $white;
      }
    }

    &.disabled {
      border-color: $grey-l;

      h3 {
        color: $grey-l;
      }

      svg {
        fill: $grey-l;
      }

      &:hover {
        background: $white;
      }
    }
  }

  .btn--secondary {
    background: transparent;
    border: 1px solid $grey-v-l;
    border-radius: 16px;
    box-shadow: none;
    color: $grey-v-l;
    font-weight: $semi-bold;
    padding: 8px 13px;

    &:hover {
      background-color: transparent;
      border: 1px solid $btn-blue-l;
      border-radius: 13px;
      justify-content: flex-end;
      position: relative;
      color: $btn-blue-l;

      h3 {
        color: $btn-blue-l;
      }
    }
  }

  .btn--with-gradient {
    border: 2px solid;
    border-image: linear-gradient(90deg, $blue 0%, #cb003d 100%);
    border-radius: 16px;
    padding: 11px 13px;

    h3 {
      background: linear-gradient(90deg, $blue 0%, #cb003d 100%);
    }

    &:hover {
      background: transparent;
      border: 1px solid $blue-d;

      h3 {
        color: $blue-d;
      }
    }
  }
}

.search-btn-flex {
  display: flex;
  flex-wrap: wrap;
}
.footer-btn-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  align-items: center;
}

.btn--download-btn,
.btn--inverse {
  align-items: center;
  background-color: $btn-blue;
  border: $border-half $btn-blue;
  border-radius: $size;
  color: $white;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  height: 38px;
  justify-content: flex-end;
  padding: $size-one-sixth $size-half;
  position: relative;

  &__search {
    @extend .btn--download-btn;
    justify-content: center;
    max-width: 420px;
    width: 100%;
  }

  &__footer {
    @extend .btn--download-btn;
    justify-content: center;
    @include media("<=m") {
      width: 100%;
      max-width: 400px;
    }
    &:hover {
      background: white;
      color: #1b508e;
      a {
        color: #1b508e;
      }
    }
  }

  &:hover {
    background: $white;
    color: $blue;

    h3 {
      color: $blue;
    }

    svg {
      fill: $blue;
    }
  }

  &.disabled {
    border-color: $grey-l;

    h3 {
      color: $grey-l;
    }

    svg {
      fill: $grey-l;
    }
  }
}

.btn-login,
.btn-checkout,
.btn-form {
  background: $blue;
  border: 0;
  color: $white;
  font-weight: $semi-bold;

  &:hover {
    background: $orange;
  }
}

.btn-login,
.btn-checkout {
  justify-content: center;
  width: 100%;
}

.btn-logout {
  @include responsive-margin-top($aria, $aria-two-thirds, $aria-one-third);
}

.btn-form {
  padding: $gutter-one-sixth $gutter;
  text-transform: capitalize;
}

.btn-newsletter {
  @include responsive-margin-top($aria, $aria-two-thirds, $aria-one-third);
}

.visibility-button {
  color: $blue;
  cursor: pointer;

  &.js-hide-button {
    display: none;
  }

  svg {
    fill: $blue;
    height: auto;
    margin-left: $gutter-one-third;
    vertical-align: middle;
    width: $size-two-thirds;
  }
}
