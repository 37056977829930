/* Product intro */

.product-intro {
  @include media("<=xs") {
    display: none;
  }

  &__title,
  &__subtitle {
    color: $blue;

    sup {
      font-size: 50%;
      top: -1em;
    }
  }

  &__title--category {
    line-height: $line-height-headings;
  }

  &__subtitle {
    @include media("<=s") {
      padding-left: $gutter-double;
      padding-top: 0;
    }
    padding-left: $gutter-quadruple;
    padding-top: $gutter-one-third;

    &--small {
      padding-top: 0;
    }
  }

  &--mobile {
    @include header-image-title-mobile();
  }
}

/* Product */

.product {
  &-description__details {
    h3 {
      color: $blue;
      margin-bottom: $aria-one-third;
    }

    ul {
      @include list-style();
    }

    p + *,
    ul + * {
      @include media("<=s") {
        margin-top: $aria-two-thirds;
      }
      @include media("<=xs") {
        margin-top: $aria-one-third;
      }
      margin-top: $aria;
    }
  }

  &-description__labels,
  &-description__awards {
    @include media("<=s") {
      margin-top: $aria-two-thirds;
    }
    @include media("<=xs") {
      margin-top: $aria-half;
    }
    margin-top: $aria;

    img {
      @include media("<=xs") {
        margin-right: $aria-one-third;
      }
      display: inline-block;
    }
  }

  &-description__description {
    @include responsive-margin-top($gutter-double, $gutter-two-thirds * 2, $gutter);

    iframe {
      @include media("<=s") {
        width: 100%;
      }
    }
  }

  &-description__labels img {
    @include max-width-and-margin-right(
      $product-label-desktop-width,
      $product-label-tablet-width,
      $product-label-mobile-width,
      $aria-half,
      $aria-one-third,
      $aria-one-third
    );
  }

  &-description__awards img {
    @include max-width-and-margin-right(
      $product-award-desktop-width,
      $product-award-tablet-width,
      $product-award-mobile-width,
      $aria-two-thirds,
      $aria-half,
      $aria-half
    );
  }

  &-list {
    .card__info-link a {
      color: $blue;
    }
  }

  .document-item__item-details {
    @include media("<=s") {
      padding: $gutter-one-third $gutter-half;
    }
    @include media("<=s") {
      padding: $gutter-one-third;
    }
    width: 100%;
  }
}

.text-placeholder-before {
  padding: 80px 15px 0;

  @include media("<=xs") {
    padding: 80px 15px 80px;
  }
}

.text-placeholder-after {
  padding: 0 15px 100px;

  @include media("<=xs") {
    padding: 80px 15px 100px;
  }
}

/* Product applications */

.product-applications {
  &__title {
    @include media("<=s") {
      margin-bottom: $aria-one-third;
      margin-top: $aria-two-thirds * 2;
    }
    @include media("<=xs") {
      margin-bottom: $aria-half;
      margin-top: $aria-two-thirds;
    }
    margin-bottom: $aria;

    a {
      color: $blue;
    }
  }

  &__list {
    @include media("<=s") {
      display: flex;
      flex-wrap: wrap;
    }
    list-style: none;

    &-item {
      @include media("<=s") {
        width: 50%;

        &:nth-child(odd) {
          padding-right: $gutter-half;
        }

        &:nth-child(2) {
          .product-applications__application {
            border-top: $border-half $grey-l;
          }
        }
      }

      @include media("<=xs") {
        width: 100%;

        &:nth-child(2) {
          .product-applications__application {
            border-top: 0;
          }
        }
      }

      &:first-child {
        .product-applications__application {
          border-top: $border-half $grey-l;
        }
      }
    }
  }

  &__application {
    @include media("<=s") {
      padding: $aria-one-third 0;
    }
    @include media("<=xs") {
      padding: $aria-one-sixth 0;
    }
    border-bottom: $border-half $grey-l;
    color: $grey-d;
    display: block;
    padding: $aria 0;
  }
}

/* Products list */

.products__list {
  list-style: none;

  &-item {
    border-bottom: $border-half $grey-l;

    &:first-child {
      border-top: $border-half $grey-l;
    }
  }

  &-item-details {
    display: none;
    padding-bottom: $gutter;
  }

  &-item-name {
    align-items: center;
    color: $blue;
    cursor: pointer;
    display: flex;
    padding: $gutter-half $gutter-one-third;

    .highlight {
      color: $orange;
    }

    .visibility-button {
      margin-left: auto;
    }

    .visibility-button--close {
      display: none;
    }

    &.active {
      font-weight: $semi-bold;

      + .products__list-item-details {
        display: flex;
        flex-wrap: wrap;
      }

      .visibility-button--open {
        display: none;
      }

      .visibility-button--close {
        display: block;
      }
    }

    &.direct-link-item-container {
      cursor: default;
    }
  }

  &-item-characteristics + .products__list-item-subtitle {
    margin-top: $aria;
  }

  &-download-btns {
    list-style: none;

    li {
      margin-bottom: $aria-half;

      &.documents-download__language {
        margin-bottom: 0;
      }
    }

    .documents-download__button.sidebar-download-btn {
      max-width: 100%;
      width: 260px;
    }
  }
}

.grey-box__container {
  @include media("<=s") {
    margin-top: $aria-two-thirds;
  }

  > .grid-25 {
    @extend .m-6;
    @extend .s-12;
    @include media(">s") {
      margin-bottom: 0;
    }
    @include media("<=s") {
      &:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
    }
    @include media("<=xs") {
      &:nth-last-child(2) {
        margin-bottom: $aria-one-third;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.grid {
    margin-left: 0;
  }

  display: flex;
  margin-top: $aria-two-thirds * 2;
}

.grey-box {
  background: $grey-l-t;
  height: 100%;
  padding: $aria-two-thirds;

  h2 {
    color: $blue;
  }

  p {
    color: $grey-d;
  }
}

.rca-styling.product-details {
  .product-description-wrapper {
    position: relative;
  }

  .contact-person {
    display: none;
  }

  h2 {
    font-family: "GT America Medium";
    font-weight: 600;
  }

  .product-intro {
    &__title,
    &__subtitle {
      color: $grey-v-l;
    }

    &__header {
      @include media("<=tablet") {
        margin-bottom: 40px;
        padding-left: 11px;
        padding-right: 11px;
        position: relative;
        top: unset;
        transform: unset;
        width: 100%;
        left: 0;
        padding-top: 115px;
      }
    }

    &__subtitle {
      @include media("<=s") {
        padding-left: 0;
      }
      padding-left: 0;
    }
  }

  .product-intro.content-page__header {
    @include media("<=tablet") {
      margin-bottom: 40px;
      padding-left: 11px;
      padding-right: 11px;
      padding-top: 115px;
    }
  }

  .rca-btn-add-to-cart {
    margin-top: 33px;

    .btn--main-btn {
      background-color: transparent;
      border: 1px solid $grey-v-l;
      border-radius: $aria-half;
      padding: 11px 13px;

      h3 {
        color: $grey-v-l;
      }

      &:hover {
        background-color: transparent;
        border: 1px solid $btn-blue-l;

        h3 {
          color: $btn-blue-l;
        }
      }
    }
  }

  .product-description {
    &__awards {
      img {
        max-width: 94px;
        margin-bottom: 20px;
      }
    }

    &__subtitle {
      color: $blue;
      margin-top: 72px;

      @include media("<=s") {
        padding-left: 20px;
        padding-right: 20px;
      }

      @include media("<=tablet") {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__details {
      background: $grey-v-l;
      box-shadow: 6px -9px 84px rgba(198, 198, 198, 0.26);
      padding: 50px 50px 50px 70px;
      width: 67%;
      margin-left: -115px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;

      @include media("<=xl") {
        margin-left: 0;
        padding: 20px;
        width: 56%;
      }

      @include media("<=s") {
        margin-left: 0;
      }

      &__read-more,
      &__read-less {
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 35px;
        color: $blue-d;
        cursor: pointer;

        svg {
          margin-bottom: -4px;
          margin-left: 4px;
        }

        &:hover {
          color: $btn-blue-l;

          svg {
            path {
              fill: $btn-blue-l;
            }
          }
        }
      }

      &__read-less {
        svg {
          transform: rotate(180deg);
        }
      }

      .grid {
        border: 2px solid $blue;
        border-radius: 20px;
        padding: $aria $aria-double;
        width: 100%;
      }

      &--full {
        display: none;
      }
    }

    &__labels {
      margin-top: 18px;
      width: 100%;

      img {
        border-radius: 50%;
        max-width: 60px;
        margin-bottom: 20px;
        margin-right: 5px;
      }
    }

    &__extended {
      margin-top: 0;
      width: 100%;
      > p {
        margin-top: 30px;
      }
    }

    &__grid {
      margin-bottom: 200px;
      padding-bottom: 0;
      position: relative;

      @include media(">=xl") {
        margin-left: auto;
        margin-right: auto;
        width: 1280px;
      }

      .product-description__details > .grid > span > p > span.blue-text,
      .product-description__details > .grid > span > p > span.blue-text a {
        color: $btn-blue-l;
        font-weight: 400;
      }

      .product-description__details > .grid > span > p > span.white-text {
        color: #fff;
        font-weight: 400;
      }
    }

    &__placeholder {
      width: 100%;

      .grid-50 {
        margin-bottom: 60px;

        @include media("<=tablet") {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .slick-slider {
        width: 100%;
      }

      .slick-slideshow-arrow {
        margin-bottom: 150px;
      }

      .product-overview-slider {
        .card {
          border: 1px solid $grey-d-1;

          &__info-title a {
            color: $blue;
          }

          &__info-link a {
            color: $grey-d-1;
          }

          &__link-more {
            a {
              color: $blue;
            }

            &:hover {
              a {
                color: $blue-l;
              }
            }
          }
        }

        .slick-dots > li > button {
          background: rgba(0, 94, 172, 0.66);
          border: unset;
          border-radius: 6px;
          height: 10px;
          width: 72px;
        }

        .slick-dots > .slick-active > button {
          background: $blue-d;
        }

        .slick-prev {
          background-image: url(/static/img/icons/slider-arrow-blue-right.svg);
        }

        .slick-next {
          background-image: url(/static/img/icons/slider-arrow-blue-right.svg);
        }
      }
    }
  }

  .product-downloads {
    padding-top: 0;
    padding-bottom: 0;

    @include media("<=tablet") {
      padding-top: 92px;
      padding-bottom: 46px;

      .toggle-buttons {
        text-align: center;
      }
    }
  }

  .product-formulations {
    @include media("<=tablet") {
      padding-bottom: 126px;

      .toggle-buttons {
        text-align: center;
      }
    }
  }

  .product-downloads,
  .product-formulations {
    .visibility-button {
      &:hover {
        color: $btn-blue-l;

        svg {
          path {
            fill: $btn-blue-l;
          }
        }
      }

      svg {
        fill: $white;
      }
    }

    h2 {
      text-align: left;
    }

    .document-item {
      border: 1px solid $blue-d;
      border-radius: 10px;
      height: 153px;
      margin-bottom: $aria;
      max-width: 400px;
      padding: 16px 20px;
      width: 100%;

      @include media("<=tablet") {
        height: unset;
        min-height: 153px;
      }

      &__item-details {
        padding: 0;
      }

      &__item-info {
        h3 {
          color: $blue-d;
          font-weight: $light;
        }
      }
    }
  }

  .product-effects {
    &__grid {
      border: 2px solid $grey-v-l;
      border-radius: 20px;
      padding: 57px 30px 57px 78px;
      width: calc(100% - 30px);
      font-weight: 300;

      .grid-100 {
        margin-bottom: 28px;

        @include media("<=tablet") {
          padding: 0;
        }
      }

      .grid {
        margin-top: 0;
      }

      .grid-left {
        width: 65%;
        padding-right: 25px;
        word-break: break-word;
      }

      .grid-left:first-of-type {
        margin-bottom: 34px;
      }

      .grid-left:last-of-type {
        margin-bottom: 48px;
      }

      .grid-right {
        width: 35%;
        padding-left: 25px;
        word-break: break-word;
      }

      @include media("<=tablet") {
        border: unset;
        width: 100%;
        padding: 65px 20px 75px 20px;
      }

      ul {
        margin-left: 40px;
        margin-top: 32px;

        li {
          margin-bottom: 15px;
        }
      }

      p,
      h3,
      span,
      li {
        color: $grey-v-l;

        span {
          color: $grey-v-l;
        }
      }

      .grid {
        margin-top: $aria;
      }
    }

    &__title {
      color: $grey-v-l;
      margin-bottom: 17px;
    }
  }

  .product-description-section {
    margin-right: 0;
    margin-bottom: 209px;

    @include media(">xl") {
      margin-right: auto;
    }

    @include media("<=tablet") {
      margin-bottom: 0;
    }

    .section__title {
      margin-bottom: 34px;
    }

    .section--rca-styling-blue-with-pink-gradient,
    .section--rca-styling-blue-with-green-gradient {
      padding: 50px;

      .section {
        padding: 0;
      }
    }

    .visibility-button {
      svg {
        width: 12px;
        height: 15px;
      }

      &.arrow-up {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .section:has(.grid-with-blue-border) {
    padding: 0 20px;
  }

  .grid-with-blue-border {
    border: 2px solid $blue;
    border-radius: 23px;
    position: relative;
    overflow: initial;

    .grid-50 {
      margin-bottom: 0;
      padding: 0;

      @include media("<=s") {
        width: 100%;
      }
    }

    @include media(">=s") {
      width: 100%;
    }

    @include media(">=m") {
      width: calc(96% - 30px);
    }

    @include media(">=xl") {
      width: 1250px;
    }

    .add-to-cart {
      left: 48px;
      margin: 0;
      position: absolute;
      text-align: left;
      top: 50%;
      transform: translate(0%, -50%);
      width: 40%;

      h2 {
        margin-bottom: 29px;
        text-align: left;
        margin-left: -1px;

        @include media("<=tablet") {
          font-size: 25px;
          line-height: 36px;
          font-family: "GT America Medium";
          font-weight: 500;
          margin-bottom: 11px;
        }
      }

      p {
        color: $blue;
        margin-bottom: 29px;
        margin-left: 1px;
      }
    }

    .documents-download {
      &__cart-added {
        background: $btn-blue-l;

        & > p {
          margin-bottom: 0;
          color: $grey-l;
        }
      }
    }

    margin-bottom: 17px;

    @include media("<=s") {
      margin-bottom: 108px;
    }

    .add-to-cart-img {
      img {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        width: 100%;

        @include media("<=s") {
          border-top-right-radius: unset;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }

    .btn--main-btn {
      border: 1px solid $blue;
      border-radius: 16px;
      padding: 11px 13px;

      h3 {
        color: $blue;
      }

      &:hover {
        background-color: transparent;
        border: 1px solid $btn-blue-l;

        h3 {
          color: $btn-blue-l;
        }
      }
    }
  }

  .video-iframe-container,
  .image-description-container {
    top: 182px;
    position: absolute;
    right: 0;
    width: 50%;

    img {
      border-radius: unset;
    }

    iframe,
    img {
      height: 370px;
      width: 100%;
    }
  }

  .product-description-section {
    @include media("<=xl") {
      width: 98%;
    }

    @include media("<=m") {
      width: 100%;
    }

    @include media("<=tablet") {
      width: 100%;
    }

    @include media(">xl") {
      width: 1280px;
    }
  }

  .product-downloads {
    .document-item--documentation:nth-child(n + 4):not(.document-item--toggled) {
      display: none;
    }
  }

  .product-formulations {
    .document-item--documentation:nth-child(n + 3):not(.document-item--toggled) {
      display: none;
    }
  }

  .document-item--toggled {
    display: flex;
  }

  .product-intro--mobile {
    display: none;
  }

  .section--intro-image-mobile {
    display: none;
  }

  .section--intro-image:not(.section--intro-image-mobile) {
    display: block;
  }

  @include media("<=xl") {
    .video-iframe-container,
    .image-description-container {
      padding-left: 15px;
    }
  }

  @include media("<=s") {
    .grid-with-blue-border {
      .add-to-cart {
        left: unset;
        padding: 44px 20px 64px 20px;
        position: relative;
        top: unset;
        transform: unset;
        width: 100%;

        h2 {
          margin-bottom: 0;
        }
      }
    }

    .video-iframe-container,
    .image-description-container {
      padding-left: 0;
    }
  }

  @include media("<=tablet") {
    .section {
      &--intro-image-mobile {
        display: block;
      }

      .section--intro-image:not(.section--intro-image-mobile) {
        display: none;
      }

      &--product.content-page--intro-image {
        padding: 20px;
        position: relative;
      }

      &--intro-image {
        height: 260px;
        left: 0;
        top: 0;
        margin-bottom: -22px;
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
      }
    }

    .video-iframe-container,
    .image-description-container {
      width: calc(100% + 40px);
      position: relative;
      top: 0;
      margin: 70px -20px -10px;

      iframe,
      img {
        height: 230px;
      }
    }

    .product-description {
      &__details {
        box-shadow: unset;
        padding: 0;
        width: 100%;

        p {
          font-size: 20px;
        }

        .grid {
          border: unset;
          border-radius: unset;
          padding: 20px 20px 0 20px;
          width: 100%;
        }
      }

      &__grid {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      &__labels {
        img {
          height: 60px;
          width: 60px;
        }
      }
    }

    .product-description-section {
      flex-direction: column-reverse;

      .section--rca-styling-blue-with-pink-gradient,
      .section--rca-styling-blue-with-green-gradient {
        padding: 0;
      }
    }

    .card__info {
      width: 100%;
    }

    .section__title {
      margin-bottom: 20px;
    }

    .grid-with-blue-border {
      .add-to-cart {
        h2 {
          margin-bottom: 11px;
        }
      }
    }
  }

  .product-overview-slider {
    padding-left: 15px;
    padding-right: 15px;
  }

  .section__title {
    margin-top: 0;
  }
}
