.tiles-slider {
  margin-left: -10px;
  margin-right: -10px;

  @media (max-width: 1024px) and (min-width: 640px) {
    padding-left: 23px;
    padding-right: 23px;
  }

  .card {
    width: 100%;
  }

  .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;

    &__info {
      @media (max-width: 1024px) and (min-width: 640px) {
        padding: 20px 10px;
      }
    }

    &__image {
      img {
        height: 100%;
        width: 100%;
      }
    }

    &__info-title {
      hyphens: auto;
    }
  }

  .slick-arrow {
    @media (max-width: 1440px) {
      display: inline !important;
    }
    background-position: center;
    background-repeat: no-repeat;
    background-size: $gutter;
    cursor: pointer;
    display: block;
    height: $gutter-double;
    position: absolute;
    top: calc(50% - #{$gutter});
    width: 33px;
    z-index: 2;
    background-image: none !important;
    background-color: rgba(0, 0, 0, 0.5);

    @media (max-width: 1024px) and (min-width: 640px) {
      width: 40px;
      height: 40px;
      background-size: 20px;
    }
  }

  .slick-prev {
    -webkit-mask-image: url("/static/assets/right-arrow.svg");
    mask-image: url("/static/assets/right-arrow.svg");
    mask-repeat: no-repeat;
    left: $aria;
    transform: rotateY(180deg);

    @media (max-width: 640px) {
      left: 15px !important;
      background-color: #5bc2e7;
      -webkit-mask-image: url("/static/assets/right-arrow-without-opacity.svg");
      mask-image: url("/static/assets/right-arrow-without-opacity.svg");
    }

    @media (max-width: 1024px) and (min-width: 640px) {
      left: -7px !important;
    }

    &:hover {
      background-image: url("/static/assets/right-arrow-orange.svg");
    }
  }

  .slick-next {
    -webkit-mask-image: url("/static/assets/right-arrow.svg");
    mask-image: url("/static/assets/right-arrow.svg");
    mask-repeat: no-repeat;
    right: $aria;

    @media (max-width: 640px) {
      right: 15px !important;
      -webkit-mask-image: url("/static/assets/right-arrow-without-opacity.svg");
      mask-image: url("/static/assets/right-arrow-without-opacity.svg");
      background-color: #5bc2e7;
    }

    @media (max-width: 1024px) and (min-width: 640px) {
      right: -7px !important;
    }

    &:hover {
      background-image: url("/static/assets/right-arrow-orange.svg");
    }
  }

  .slick-prev,
  .slick-next {
    background-image: url("/static/assets/right-arrow.svg");

    &:hover {
      background-image: url("/static/assets/right-arrow.svg");
    }
  }

  .slick-prev {
    left: -60px;
  }

  .slick-next {
    right: -60px;
  }

  .tiles-small {
    &__container {
      max-width: 100%;
    }
  }

  @media (max-width: 1440px) {
    .slick-arrow {
      display: inline !important;
    }
  }
}
