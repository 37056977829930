body {
  font-family: $main-font;
  font-size: 16px;
  line-height: $line-height;
}

#pi_tracking_opt_in_div {
  display: none;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  width: auto;
}

a {
  font-family: $main-font;
  text-decoration: none;
}

b,
strong {
  font-weight: bold;
}

i,
em {
  font-style: italic;
}

// Utilities TODO Carlo spostare?

.align-center {
  text-align: center;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.margin-bottom {
  margin-bottom: $gutter;
}

.block {
  display: block;
  width: 100%;
}

.custom-list {
  @include list-style();
}

.products__list-item-name > a {
  color: #1b508e;
}

.three-d-printing {
  color: #2a2a2a;
  line-height: 32px;
  padding-top: 62px;

  h1,
  h2,
  h3 {
    color: #1b508e;
  }

  h1 {
    line-height: 80px;
    margin: 40px 0 8px 0;

    @include media("<=m") {
      line-height: 60px;
      margin: 30px 0 8px 0;
    }
  }

  h2:not(.card__info-title) {
    color: #1b508e;
    font-weight: 400px;
    line-height: 56px;
    margin: 0 0 40px 0;

    @include media("<=m") {
      line-height: 60px;
      margin: 0 0 20px 0;
    }
  }

  h4 {
    color: #1b508e;
    font-weight: 400px;
  }

  p:not(.card__info-link) {
    font-weight: 300;
    margin: 16px 0 32px 0;
  }

  .box-columns {
    color: #1b508e;
    display: flex;
    flex-wrap: wrap;
    line-height: 27px;
    margin-bottom: 20px;

    .box-column {
      align-items: flex-start;
      border-right: 1px solid #f5f5f5;
      flex-direction: column;
      padding: 24px;
      width: 226px;

      .icon-number {
        display: inline-block;
        height: auto;
        margin-bottom: 40px;
        width: 40px;
      }

      .icon-arrow-right {
        display: inline-block;
        height: auto;
        margin: 14px 0 0 16px;
        vertical-align: top;
        width: 24px;
      }

      @include media(">m") {
        min-height: 323px;

        &:last-child {
          border-right: 0;
        }
      }

      @include media("<=m") {
        width: 50%;

        &:nth-child(even) {
          border-right: 0;
        }
      }

      @include media("<=s") {
        border-bottom: 1px solid #f5f5f5;
        border-right: 0;
        height: auto;
        width: 100%;
      }
    }
  }

  .btn {
    font-size: 20px;
    font-weight: 700;
    height: 60px;
    line-height: 24px;
    padding: 18px 24px;
    text-transform: uppercase;
  }

  .clearfix {
    clear: both;
  }

  .container {
    margin: 0 auto;
    max-width: 1188px;
    padding: 0 20px;
  }

  .container-xl {
    margin: 0 auto;
    max-width: 1440px;
  }

  .container-sm {
    margin: 0 auto;
    max-width: 580px;

    p {
      font-size: 21px;
      line-height: 32px;
    }
  }

  .checkbox {
    appearance: none;
    border: 1px solid #1b508e;
    display: inline-block;
    height: 20px;
    padding: 0;
    /*outline: 1px solid #1b508e;*/
    width: 20px;

    &:checked {
      background: #fff url("../img/landing_pages/3d_printing_landing_page/checkmark.png") no-repeat center center;
      background-size: 80%;
      color: #000;
    }
  }

  .privacy-policy-checkbox {
    display: block;
    float: left;
    margin: 6px 10px 0 0;
  }

  .discover-more {
    line-height: 32px;
    margin-bottom: 87px;

    .discover-more-form {
      padding: 32px 40px 40px 40px;

      .checkbox-container {
        width: 100%;

        .checkbox {
          float: right;
          margin: 3px 0 0 0;
        }
      }

      .form-fields {
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;

        .text-field {
          border: 1px solid #e1e1e1;
          display: flex;
          flex-direction: column;
          height: 60px;
          margin: 0 16px 24px 0;
          padding: 14px 24px;
          width: 340px;

          @include media("<=m") {
            margin-right: 0;
            width: 100%;
          }
        }
      }
    }
  }

  .header-image {
    height: auto;
    width: 100%;
  }

  .large-text {
    font-size: 24px;
  }

  .small-text {
    font-size: 16px;
  }

  .image-scientist-container {
    margin: 124px 0 56px 0;
    padding: 64px 0;

    .image-scientist {
      display: block;
      height: auto;
      margin: -128px auto 0 auto;
      max-width: 100%;
      width: 640px;
    }
  }

  .white-boxes {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;

    p {
      font-size: 15px;
      line-height: 23px;
    }

    .white-box {
      align-items: flex-start;
      background-color: #fff;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      padding: 32px;

      @include media(">m") {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }

      .white-box-icon {
        height: auto;
        margin-bottom: 31px;
        width: 64px;
      }

      @include media("<=m") {
        width: 100%;
      }
    }
  }

  .white-box-with-icon {
    color: #1b508e;
    height: 214px;
    width: 270px;
  }

  .white-box-with-checkbox {
    height: 204px;
    width: 509px;

    @include media("<=m") {
      height: auto;
    }
  }

  .webinar-section {
    padding: 40px;

    .webinar-title {
      margin-bottom: 8px;
    }

    .webinar-box {
      height: 295px;
      line-height: 32px;
      width: 509px;

      @include media(">m") {
        &:not(:last-child) {
          margin-right: 40px;
        }

        p {
          min-height: 96px;
        }
      }

      @include media("<=m") {
        height: auto;
        margin-right: 0;
        width: 100%;
      }
    }
  }

  .why-rahn {
    margin-bottom: 60px;
  }

  .section-book-a-call {
    display: flex;
    flex-wrap: wrap;
    margin-top: 120px;

    .book-a-call-info-section {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      padding: 40px;

      h2 {
        margin: 8px 0;
      }

      .book-a-call-info-section-content {
        margin: 0 auto;
        max-width: 322px;
      }
    }

    .book-a-call-form {
      background-image: url("../img/landing_pages/3d_printing_landing_page/book-a-call-form-background.png");
      color: #2a2a2a;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      padding: 80px 20px;

      form {
        background-color: #ffffff;
        margin: 0 auto;
        max-width: 550px;
        padding: 40px;

        .text-input-fields {
          border: 1px solid #f5f5f5;
          padding: 24px;
        }
      }
    }
  }

  input,
  textarea {
    color: #2a2a2a;
    font-size: 20px;

    &::placeholder {
      color: #2a2a2a;
    }
  }

  .check-out-webinar {
    margin: 0 0 32px 0;
  }

  .section {
    @include media("<=s") {
      padding: 40px 0;
    }
    @include media("<=m") {
      padding: 40px 0;
    }
  }

  .submit-btn {
    margin-top: 15px;
  }

  .captcha-error-message {
    color: #ff0000;
    display: none;
    font-size: 16px;
  }
}

.rca-styling {
  .image-with-link {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position-x: center;

    &.grid-50 {
      margin-bottom: 0;
    }

    &__container {
      left: 90px;
      position: absolute;
      top: 75%;
      transform: translate(0, -50%);

      @include media("<=s") {
        margin-left: 40px;
      }

      @include media("<=m") {
        margin-left: 80px;
      }

      @include media("<=tablet") {
        left: 0;
        transform: unset;
        top: 0;
        margin-left: 20px;
      }

      .white-text > h1 {
        margin-bottom: 11px;
      }

      .white-text > p {
        margin-bottom: 32px;
      }

      h1,
      p {
        color: $grey-v-l;
      }
    }

    &::before {
      background-color: rgba(10, 10, 10, 0.3);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.block-with-images {
  height: 700px;
  margin-top: 36px;

  @include media("<=s") {
    height: 400px;
  }
  @include media("<=m") {
    height: 500px;
  }

  @include media(">xl") {
    max-width: $grid-xl;
    margin-left: auto;
    margin-right: auto;
  }
}

.text-transform-none {
  text-transform: none !important;
}
