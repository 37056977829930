.home-box {
  @include media("<=m") {
    min-height: $homepage-box-min-height-tablet;
  }
  min-height: $homepage-box-min-height-desktop;

  &__title {
    color: $blue;
    padding: $gutter-two-thirds * 2 $gutter;
  }
}
