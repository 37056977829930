$contact-picture-size: 180px;
$contact-picture-size-v2: 638px;
$contact-picture-size-l: 140px;
$contact-picture-size-m: 180px;
$contact-picture-size-s: 180px;
$contact-picture-size-s-v2: 638px;
$contact-picture-size-xs: 180px;
$contact-picture-size-xs-v2: 360px;

.employee {
  @include media("<=s") {
    @include vmargin($aria-one-third, $aria-one-third);
  }
  @include media("<=xs") {
    @include vmargin($aria-one-sixth, $aria-one-sixth);
  }
  @include vmargin($aria-half, $aria-half);
  display: flex;

  &__wrapper {
    @include hmargin(auto, auto);

    &.employee__wrapper--vertical {
      @include hmargin(0, 0);
    }
  }

  &__wrapper-v2 {
    margin-bottom: 72px;
    max-width: 690px;
    @include hmargin(auto, auto);

    &.employee__wrapper--vertical {
      @include hmargin(0, 0);
    }
  }

  &__img_wrapper {
    display: flex;
    max-width: 638px !important;
    flex-direction: column;
    height: 100% !important;
  }

  &__image {
    @include media("<=l") {
      min-width: $contact-picture-size-l;
      width: $contact-picture-size-l;
    }
    @include media("<=m") {
      min-width: $contact-picture-size-m;
      width: $contact-picture-size-m;
    }
    @include media("<=s") {
      min-width: $contact-picture-size-xs;
      width: $contact-picture-size-xs;
    }
    @include media("<=xs") {
      min-width: $contact-picture-size-s;
      width: $contact-picture-size-s;
    }

    background: $bg-light-blue;
    height: auto;
    min-width: $contact-picture-size;
    width: $contact-picture-size;

    img {
      height: auto;
      width: 100%;
    }
  }

  &__image_v2 {
    background: $bg-light-blue;
    height: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    img {
      width: 100%;
      height: auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  &__info {
    background: $bg-light-blue;
    flex-grow: 1;
    padding: $aria-half $aria;
    word-wrap: break-word;

    &-slogan {
      color: $blue-d;
      display: block;
      font-size: 19px;
      font-weight: $light;
      line-height: 24px;
      margin-bottom: 26px;
      position: relative;

      @include media(">=m") {
        width: 525px;
      }

      &-quote-left {
        position: absolute;
      }

      &-quote-left {
        left: -18px;
        top: 0px;
        width: 12px;
      }

      &-quote-right {
        margin-bottom: 0px;
        width: 12px;
      }
    }

    svg {
      fill: $blue;
      height: auto;
      vertical-align: middle;
      width: 1em;
    }

    &-name,
    &-mail {
      color: $blue;
    }

    &-detail {
      color: $grey-d-1;
    }

    &-mail {
      display: block;
      text-decoration: underline;
    }

    &-phone {
      display: block;

      &--number {
        @include media("<=tablet") {
          display: block;
        }

        color: $blue;
        text-decoration: underline;

        &-secondary {
          @include media("<=tablet") {
            margin-left: 0;
          }

          margin-left: $aria-half;
        }
      }
    }
  }

  .team-page-icon {
    display: none;
  }

  & + .employee {
    @include media("<=m") {
      margin-bottom: 0;
      margin-top: $aria-half;
    }
    @include media("<=xs") {
      margin-bottom: 0;
      margin-top: $aria-one-third;
    }
  }

  &__vertical-box {
    @include media("<=xs") {
      width: 100%;
    }
    flex-direction: column;

    .employee__image {
      width: 100%;
    }

    .employee__info {
      background: $white;
    }

    height: 95%;
    background: #fefefe;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
}

.person-about-us {
  @include media("<=xs") {
    flex-direction: column;
  }

  align-items: center;
  border-bottom: 1px solid $blue;
  display: flex;
  flex-direction: row;
  padding: $gutter-two-thirds 0;

  &:last-child {
    border: 0;
  }

  > * {
    @include media("<=xs") {
      flex: auto;
    }

    flex: 1 1 0px; // sass-lint:disable-line zero-unit
    min-width: 0;
  }

  .text-plugin {
    @include media("<=xs") {
      margin-top: $aria-half;
      order: 2;
    }

    p {
      color: $blue;
    }

    img {
      float: right;
      width: 50%;
    }

    + img {
      margin-top: 0;
    }
  }
}

.rca-styling {
  .grid {
    .grid {
      margin-left: 0;
      width: 100%;
    }
  }

  .employee__wrapper {
    @include media(">=l") {
      max-width: 690px;
    }

    @media (max-width: 1500px) {
      max-width: 690px;
    }

    @media (min-width: 1500px) {
      max-width: unset;
    }
  }

  .contact-person {
    .section__title {
      color: $grey-v-l;
    }

    .employee {
      &__info {
        border-left: 1px solid $grey-v-l;
        position: relative;
        display: block;

        @include media("<=xs") {
          border-top: 1px solid $grey-v-l;
        }

        svg {
          fill: none;
        }

        &-name,
        &-mail,
        &-detail,
        &-phone--number {
          color: $grey-v-l;
        }
      }

      &__image {
        @include media("<=xs") {
          width: 70%;
          border-bottom: 1px solid $grey-v-l;
          padding-bottom: 20px;
        }

        img {
          @include media("<=xs") {
            width: 160px;
          }
        }
      }
    }
  }

  .collapsible-employee {
    margin-bottom: 92px;
    width: 50%;

    @media (max-width: 860px) {
      & {
        margin-bottom: 0;
        padding-top: 22px;
        padding-bottom: 22px;
        border-bottom: 1px solid #d5d5d5;
        margin-left: -20px;
        margin-right: -20px;
      }

      &.grid-50 {
        width: calc(100% + 40px);
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    @media (min-width: 860px) {
      &:last-of-type {
        margin-bottom: 125px;
      }
    }

    &__title {
      color: $blue;
      margin-bottom: 32px;
      position: relative;
      pointer-events: none;
      font-family: "GT America Medium";
      font-weight: 300;

      @media (max-width: 860px) {
        & {
          margin-bottom: 0;
          pointer-events: auto;
          padding-right: 24px;
          font-family: "GT America";
        }
      }

      .collapsible-employee__arrow {
        position: absolute;
        right: 0;
        top: 0;
      }

      @media (max-width: 860px) {
        &.open {
          margin-bottom: 29px;

          .collapsible-employee__arrow {
            transform: rotate(-90deg);
          }
        }
      }
    }

    &__arrow {
      display: none;
    }

    @media (max-width: 860px) {
      &__arrow {
        display: block;
      }
    }

    .employee {
      position: relative;

      @media (max-width: 860px) {
        &__wrapper {
          height: 100%;
          display: none;
        }
      }

      &__info {
        border-left: 1px solid #d2d2d2;

        a {
          color: #68b4f0;
          font-size: 16px;
          text-decoration: none;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
        }

        @include media("<=tablet") {
          border-top: 1px solid #d2d2d2;
        }

        svg {
          fill: $grey-d-1;
        }

        &-name,
        &-detail {
          color: $grey-d-1;
        }

        &-mail,
        &-phone--number {
          color: $blue-l;
          text-decoration: none;

          img {
            margin-right: 9px;
          }
        }

        @media (min-width: 1100px) {
          &-mail,
          &-phone {
            position: absolute;
          }

          &-mail {
            bottom: -9px;
          }

          &-phone {
            bottom: 26px;
          }
        }
      }
    }
  }

  .collapsible-employee,
  .footer__container,
  .contact-person {
    .section__title {
      text-align: left;
    }

    .grid {
      @include media("<=tablet") {
        width: 100%;
      }
    }

    .employee {
      @include media("<=xs") {
        display: block;
      }

      &__image {
        img {
          border-radius: 50%;
        }

        background: transparent;
      }

      &__info {
        background: transparent;
        border-top: unset;
        margin-left: 23px;
        margin-top: 0;
        padding: 0 23px;

        @include media("<=xs") {
          border-left: unset;
          margin-left: 0;
          padding: 24px 0;
        }

        &-phone {
          margin-top: 21px;
        }

        svg {
          margin-right: 12px;
        }

        &-name {
          margin-top: -9px;
        }

        &-slogan {
          display: none;
        }
      }

      &__wrapper {
        padding: 0;
      }
    }
  }

  .team-page {
    margin-top: 140px;

    @include media("<=tablet") {
      margin-top: 44px;
    }

    .grid-100 {
      margin-bottom: 0;
    }

    .employee {
      background: #fefefe;
      border-radius: 10px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      margin-bottom: 72px;
      padding-bottom: 20px;
      margin-top: 0;
      display: flex;
      flex-direction: column;

      @include media("<=tablet") {
        &__image {
          text-align: center;
          padding: 20px;
        }
      }

      &__wrapper {
        padding: 0 15px;

        @include media("<=tablet") {
          padding: 0;
        }
      }

      &__image {
        background: transparent;
        min-width: unset;
        width: unset;

        img {
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          min-height: 348px;
          height: 100%;
          max-width: unset;
          object-fit: cover;
          width: 278px;

          @include media("<=tablet") {
            border-radius: 10px;
            height: 200px;
            min-height: 200px;
            width: 100%;
          }
        }
      }

      &__info {
        background-color: transparent;
        padding: 24px 45px;
        position: relative;

        &-detail {
          &--company {
            margin-bottom: 30px;
          }

          &-contacts {
            position: absolute;
            bottom: 20px;
            @include media("<=s") {
              bottom: 0px;
            }

            svg {
              display: none;
            }

            .team-page-icon {
              display: inline-block;
            }
          }
        }

        svg {
          fill: $grey-d-1;
          height: 18px;
          margin-right: 12px;
          width: 18px;
        }

        &-name,
        &-details {
          color: $grey-d-1;
        }

        &-phone--number,
        &-mail {
          color: $blue;
          text-decoration: none;
          display: block;
          margin-bottom: 5px;

          img {
            margin-right: 9px;
          }

          &-secondary {
            @include media("<=tablet") {
              margin-left: 0;
            }

            margin-left: 0;
          }
        }

        &-phone {
          display: block;

          @include media("<=tablet") {
            position: relative;
            bottom: unset;
            margin-top: 20px;
          }
        }

        &-mail {
          @include media("<=tablet") {
            position: relative;
            bottom: unset;
          }
        }

        &-phone--number {
          letter-spacing: 0.015em;
        }

        &-slogan {
          color: $blue-d;
          display: block;
          font-family: $main-font-rca-styling;
          font-size: 18px;
          font-weight: $light;
          line-height: 24px;
          margin-bottom: 26px;
          position: relative;

          &-quote-left {
            position: absolute;
          }

          &-quote-left {
            left: -18px;
            top: 0px;
            width: 12px;
          }

          &-quote-right {
            margin-bottom: 0px;
            width: 12px;
          }
        }
      }
    }
  }
}
