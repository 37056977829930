.messages {
  .message {
    background-color: $messages-grey;
    border-bottom: $border-half $messages-grey;
    border-top: $border-half $messages-grey;
    padding: $aria-one-third $aria-half;
  }

  .text-error {
    @include color-messages($messages-error, 10%);
  }

  .text-success {
    @include color-messages($messages-success, 10%);
  }

  .text-warning {
    @include color-messages($messages-warning, 20%);
  }

  .text-info {
    @include color-messages($messages-info, 10%);
  }
}
