.document-item {
  @include media("<=s") {
    margin-bottom: $gutter-half;
  }

  @include media("<=xs") {
    margin-bottom: $gutter-one-third;
  }

  display: none;
  margin-bottom: $aria;

  + .toggle-buttons > .show-button {
    display: block;
  }

  &:nth-child(-n + 5) {
    display: flex;

    + .toggle-buttons > .show-button {
      display: none;
    }
  }

  &__container {
    @include media("<=tablet") {
      flex-direction: column;
      padding: 20px;
    }

    background: $white;
    display: flex;
    width: 100%;
  }

  &.document-item--toggled {
    display: flex;
  }

  &__item-image + .document-item__item-details {
    @include media("<=l") {
      width: 60%;
    }
    @include media("<=m") {
      width: auto;
    }
    @include media("<=s") {
      width: 70%;
    }
    @include media("<=xs") {
      padding: 0;
      width: 100%;
    }
    width: 70%;
  }

  &__item-details {
    @include media("<=s") {
      padding: $gutter-half;
    }
    @include media("<=xs") {
      padding: $gutter-one-third;
    }
    background: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: $gutter-two-thirds;
    width: 100%;

    .btn--main-btn {
      @include media("<=xs") {
        margin-top: $aria-two-thirds;
      }
      align-self: flex-start;
      margin-top: $aria-half;

      &:hover {
        border: 1px solid $blue-d;

        h3 {
          color: $blue-d;
        }
      }
    }
  }

  &__item-info {
    h3 {
      @include media("<=s") {
        margin-bottom: 0;
      }
      color: $blue;
      margin-bottom: $aria;
    }

    &--with-description h3 {
      margin-bottom: 0;
    }

    .highlight {
      color: $orange;
    }
  }

  &__item-description {
    @include responsive-margin-bottom($aria, 0, 0);
  }

  &__item-image {
    @include media("<=l") {
      width: 40%;
    }
    @include media("<=m") {
      width: auto;
    }
    @include media("<=tablet") {
      display: none;
    }
    width: 30%;
  }

  &--events {
    &:nth-child(-n + 7) {
      display: flex;
    }

    .document-item__item-details {
      @include media("<=xs") {
        padding: $gutter-one-third;
      }
      width: 100%;
    }

    .document-item__item-info {
      background-color: $white;
    }
  }

  &--press {
    &:nth-child(-n + 7) {
      display: flex;
    }

    .document-item__item-details {
      background-color: $bg-light-grey;
    }
  }

  &--documentation {
    display: flex;
  }
}

.documents-download {
  &__info {
    h3 {
      color: $blue;
    }

    &__white {
      h3 {
        color: $white;
      }
    }
  }

  &__icon {
    margin-left: $aria-half;
    max-width: $size;

    svg {
      fill: $blue;
      height: auto;
      width: $size-two-thirds;
    }
  }

  &__icon__white {
    svg {
      fill: $white;
    }
  }

  &__languages,
  &__permission,
  &__cart-added {
    border-radius: $size;
    box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.2);
    display: none;
    left: 50%;
    list-style: none;
    margin-top: -$aria-half;
    position: absolute;
    top: -100%;
    transform: translateX(-50%);

    &.active {
      display: flex;
    }
  }

  &__permission,
  &__cart-added {
    @include media("<s") {
      left: 0;
      min-width: 250px;
      transform: translateX(0%);
      white-space: normal;
    }

    a {
      color: $white;
      text-decoration: underline;
    }

    background: $orange;
    color: $white;
    flex-direction: column;
    margin-top: -$aria;
    padding: $gutter-half;
    white-space: nowrap;
  }

  &__languages {
    &:before {
      border-left: $size-one-sixth solid transparent;
      border-right: $size-one-sixth solid transparent;
      border-top: $size-one-sixth solid $white;
      bottom: -$aria-one-tenth;
      content: "";
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      z-index: 2;
    }

    &:after {
      border-left: $size-one-fifth solid transparent;
      border-right: $size-one-fifth solid transparent;
      border-top: $size-one-fifth solid $btn-blue;
      bottom: -$aria-one-sixth;
      content: "";
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      z-index: 1;
    }
  }

  &__language {
    background: $white;
    border: $border $btn-blue;
    border-left: 0;
    border-right: 0;
    box-shadow: inset -1px 0 0 0 $grey-l;
    padding: $gutter-one-third;

    a {
      color: $blue;
    }

    &--first {
      border-bottom-left-radius: 50%;
      border-left: $border $btn-blue;
      border-top-left-radius: 50%;
      box-shadow: inset -2px 0 0 0 $grey-l;
      padding-left: $gutter-two-thirds;
    }

    &--last {
      border-bottom-right-radius: 50%;
      border-right: $border $btn-blue;
      border-top-right-radius: 50%;
      box-shadow: none;
      padding-right: $gutter-two-thirds;
    }

    &:only-child {
      border: $border $btn-blue;
      border-radius: $size;
      box-shadow: none;
      padding: $gutter-one-third $gutter;
    }

    &:hover {
      background: $btn-blue;

      a {
        color: $white;
      }
    }
  }
}

.section-download {
  .text-plugin {
    h1,
    h2,
    h3 {
      @include section-title();
    }
  }
}

.rca-styling {
  .documents-download__icon {
    display: none;
  }

  .documents-download {
    &__cart-added {
      background: $btn-blue-l;

      & > p {
        margin-bottom: 0;
        color: $grey-l;
      }
    }

    &__permission,
    &__cart-added {
      @media (max-width: 1500px) {
        left: 0;
        min-width: 250px;
        transform: translateX(0%);
        white-space: normal;
      }

      a {
        color: $white;
        text-decoration: underline;
      }

      background: $orange;
      color: $white;
      flex-direction: column;
      margin-top: -$aria;
      padding: $gutter-half;
      white-space: nowrap;
    }
  }

  .publications-section {
    .toggle-buttons {
      margin-top: 20px;

      .visibility-button {
        &:hover {
          color: $btn-blue-l;

          svg {
            path {
              fill: $btn-blue-l;
            }
          }
        }
      }
    }
  }

  .document-item {
    background: transparent;
    border: 1px solid $blue-d;
    border-radius: 10px;
    margin-bottom: 0;
    overflow: hidden;
    padding: 15px 10px;
    overflow: initial;

    img {
      border-radius: 10px 0 0 10px;

      @include media("<s") {
        border-radius: 10px 10px 0 0;
        width: 100%;
      }
    }

    .publications-section {
      .document-item {
        &__item-image {
          img {
            @include media("<s") {
              border-radius: 10px 0 0 10px;
              width: 100%;
            }
          }
        }
      }
    }

    &__container {
      border: 1px solid rgba(236, 236, 236, 0.13);
      border-radius: 10px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);

      @include media("<=tablet") {
        border: 1px solid $blue-d;
      }
    }

    &__item-details {
      border-radius: 10px;
    }

    &__item-info {
      h3 {
        color: $grey-d-1;
        margin-bottom: 12px;

        @include media("<=xs") {
          margin-bottom: $aria;
        }
      }
    }

    &__events-container {
      position: relative;
      width: 100%;

      .toggle-buttons {
        margin-top: $aria;
      }

      .visibility-button {
        color: $white;
        margin-left: 25px;

        svg {
          fill: $white;
        }
      }

      .document-item__item-details,
      .document-item__item-info {
        background: unset;
        padding: 0;

        h3 {
          color: $white;
        }

        p {
          color: $white;
        }

        p:nth-of-type(2) {
          font-weight: $light;
          margin-top: 7px;
        }
      }

      .document-item--events {
        background-color: inherit;
        margin-bottom: 40px;
        padding: 0 48px;
        position: relative;
        width: 100%;
      }

      .document-item--events::after,
      .toggle-buttons::after {
        background-color: white;
        border-radius: 50%;
        content: "";
        height: 17px;
        left: 12px;
        position: absolute;
        top: 40px;
        width: 17px;
        z-index: 1;
      }

      .toggle-buttons::after {
        margin-top: -16px;
        top: unset;
      }
    }

    &__events-container::after {
      background-color: $white;
      bottom: 0;
      content: "";
      margin-left: 20px;
      position: absolute;
      top: 40px;
      width: 3px;
    }
  }

  .cosmetopolitan-section,
  .events-section,
  .publications-section,
  .press-releases-section {
    .document-item {
      border: unset;
    }
  }

  .slick-slide {
    .document-item__item-info {
      p {
        font-size: 16px;
      }

      em {
        font-weight: 400;
        font-size: 18px;
        font-style: normal;
        line-height: 38px;
        letter-spacing: 0.015em;
      }

      @include media("<=xs") {
        margin-bottom: 0;
      }
    }
  }
}

.hide-all-arrow {
  svg {
    transform: rotate(-180deg);
  }
}
