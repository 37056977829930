.cookiebanner {
  background: $blue;
  bottom: 0;
  box-shadow: $box-shadow;
  color: $white;
  left: 0;
  opacity: 1;
  padding: $aria-two-thirds;
  position: fixed;
  width: 100%;
  z-index: 999;

  &-close {
    display: none;
  }

  .btn {
    @include media("<=m") {
      @include hmargin(0, 0);
      @include vmargin($aria-half, 0);
      display: block;
    }
    margin: 0 $aria-half;

    &:after {
      content: none;
    }
  }

  a {
    color: $white;
    text-decoration: underline;
  }
}
