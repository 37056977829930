/**
 * @atom Forms
 * @section Forms
 * @markup
 *  <form action="#" method="post">
 *    <div>
 *      <label>A label</label>
 *      <input type="text" value="Type text">
 *      <input type="password" value="Type password">
 *      <input type="email" value="Type email">
 *      <input type="number" value="42">
 *      <input type="file">
 *    </div>
 *    <div>
 *      <input type="radio" name="r" id="r1" value="Radio 1">
 *      <label for="r1">Choice 1</label>
 *      <input type="radio" name="r" id="r2" value="Radio 2">
 *      <label for="r2">Choice 2</label>
 *    </div>
 *    <div>
 *      <select>
 *        <option value="volvo">Volvo</option>
 *        <option value="saab">Saab</option>
 *        <option value="fiat">Fiat</option>
 *      </select>
 *      <select name="cars" size="3" multiple>
 *        <option value="volvo">Volvo</option>
 *        <option value="saab">Saab</option>
 *        <option value="fiat">Fiat</option>
 *      </select>
 *    </div>
 *    <div>
 *      <textarea cols="40" rows="8">A textarea</textarea>
 *    </div>
 *    <div>
 *      <input type="checkbox" id="c">
 *      <label for="c">A checkbox</label>
 *    </div>
 *    <div>
 *      <input type="submit" value="Submit">
 *      <input type="button" value="input type button">
 *      <button>a plain button</button>
 *    </div>
 *  </form>
 */

button,
select,
option,
label,
[type="submit"],
[type="button"],
[type="radio"],
[type="checkbox"] {
  cursor: pointer;
  font-family: $main-font;
  font-weight: normal;
  margin-bottom: $gutter-half;
  padding: $gutter-half;
}

input,
textarea,
select {
  font-family: $main-font;
  font-weight: normal;
}

[type="text"],
[type="email"],
[type="file"],
[type="number"],
[type="email"],
[type="password"],
textarea {
  border: 1px solid;
  margin-bottom: $gutter-half;
  padding: $gutter-half;
  width: 100%;
}

input {
  &::placeholder {
    overflow: visible;
  }
}

textarea {
  height: auto;
  resize: vertical;
}

label {
  display: inline-block;
  padding: 0;
}

select {
  width: 100%;
}

// sass-lint:disable id-name-format no-ids
#mc_embed_signup {
  padding-top: $gutter;

  #mc-embedded-subscribe {
    font-size: 22px;
  }

  .mc-field-group {
    margin: 0 auto;
    padding-bottom: $gutter;
    width: 25%;

    @media screen and (max-width: $screen-m) {
      width: 50%;
    }

    @media screen and (max-width: $screen-s) {
      width: 70%;
    }
  }
}
// sass-lint:enable id-name-format no-ids

.cms .cms-form-login input {
  vertical-align: middle;
}

/**
 * @molecule .form-field
 * @section Django Form Field
 * @modifiers
 *  .form-field--error The field has errors
 *  .form-field--required The field is required
 * @markup
 *  <form action="#" method="post">
 *    <div class="form-field form-field--name form-field--required form-field--error">
 *      <label>Field label</label>
 *      <div class="form-field__input-wrapper">
 *        <input type="text" value="Inserisci il tuo nome">
 *      </div>
 *      <p class="form-field__help">Field help text</p>
 *      <ul class="errorlist">
 *        <li>Il valore inserito e' troppo corto</li>
 *        <li>Il valore inserito non e' valido</li>
 *      </ul>
 *    </div>
 *  </form>
 */

.form-field {
  &--required {
    > label:after {
      content: " *";
    }
  }

  &--error {
    border-left: 2px solid $orange;
    padding-left: $gutter-half;
  }
}

.errorlist {
  color: $orange;
  list-style-type: none;
}

.rahn-form {
  @include media("<=s") {
    margin-bottom: $aria-double;
  }

  margin-bottom: $aria;

  ul,
  ol {
    list-style: none;

    li {
      &:before {
        /* we need this to prevent grid-narrow ul li before to display */
        display: none !important; // sass-lint:disable-line no-important
      }
    }
  }

  a:not(.btn-form) {
    color: $blue;

    &:hover {
      color: $orange;
    }
  }

  select,
  textarea,
  [type="text"],
  [type="email"] [type="password"],
  [type="number"],
  [type="email"],
  [type="password"],
  [type="file"] {
    background: $white;
    border: $border-half $grey-l;
    margin-bottom: $aria-two-thirds;
    padding: $gutter-one-sixth;

    &:focus {
      background: $white;
    }

    + .text-error {
      // This negative margin is needed to move the error message near the input without compromising the input margin
      margin-top: -$aria-half;
    }
  }

  label {
    color: $blue;
    margin-bottom: $aria-one-third;
  }

  abbr {
    text-decoration: none;
  }

  input:disabled {
    cursor: default;
    opacity: 0.5;

    &:hover {
      background: $blue;
      color: $white;
    }
  }

  .captcha {
    margin-bottom: $aria-half;
    max-width: $size-double * 2;
  }

  // sass-lint:disable class-name-format
  .item_newsletter_agreement,
  .item_policy_agreement {
    margin-bottom: $aria-two-thirds;

    .field.relative {
      align-items: baseline;
      display: flex;
    }
  }
  // sass-lint:enable class-name-fortmat

  .text-error {
    @include color-messages($messages-error, 10%);
    display: block;
    margin-bottom: $aria-half;
    padding: $gutter-one-sixth;
  }

  .file-clear {
    position: absolute;
    right: $aria;
    top: $aria-one-sixth;

    a {
      @include close-icon($aria-half, -$aria-half, 3px);
    }
  }

  .field--privacy-policy {
    @include responsive-margin-top($aria, $aria-half, $aria-one-third);
  }

  .relative {
    position: relative;
  }

  &__actions {
    display: inline-block;
    margin-right: $aria-half;

    a {
      color: $blue;

      &:hover {
        color: $orange;
      }
    }
  }

  p {
    strong {
      color: $blue;
    }
  }

  .btn-form {
    @include responsive-margin-top($gutter-double, $gutter-two-thirds * 2, $gutter);
  }
}

.aldryn-form {
  @include responsive-margin-top($gutter-double, $gutter-two-thirds * 2, $gutter);
}

option:disabled {
  color: #e5e5e5 !important;
}
