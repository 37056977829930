.jobs__list {
  margin-bottom: $aria-double;

  &-item {
    @include text-styles();

    border-bottom: $border-half $grey-l;
    margin-bottom: $aria;
    padding-bottom: $gutter;

    &:before {
      /* This is needed to remove the before common to grid-narrow list */
      display: none !important; // sass-lint:disable-line no-important
    }

    &:last-child {
      border: 0;
    }
  }
}

.tpl-jobs-list {
  .section__subtitle {
    text-align: center;
    text-transform: uppercase;
  }
}

.job-detail-form {
  &__attachment-disclaimer {
    margin-bottom: $aria-two-thirds;
  }

  .input-radiocheck {
    margin-left: 0;

    label {
      align-items: baseline;
      color: inherit;
      display: flex;

      input {
        margin-right: $aria-one-third;
      }
    }
  }
}

.job-detail-modal {
  background-color: $black-d-t;
  display: none;
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  &__content {
    @include media("<=s") {
      margin: 50% auto;
      width: 80%;
    }
    background-color: $white;
    border: $border $blue;
    margin: 15% auto;
    padding: $aria;
    width: 50%;

    p {
      margin-bottom: $aria;
    }

    button {
      margin-left: $aria-half;
    }
  }

  &__buttons-container {
    text-align: right;
  }
}
