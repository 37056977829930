body {
  &.no-scroll {
    overflow: hidden;
  }
}

.site-nav-submenu-overlay {
  background: $black-t;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.content-page {
  &--intro-image {
    margin-bottom: 0;
    padding: 0;
    position: relative;

    .section--intro-image {
      @include media("<=s") {
        mask-image: linear-gradient(
          90deg,
          rgba(254, 254, 254, 0) 0%,
          rgba(254, 254, 254, 1) 30%,
          rgba(254, 254, 254, 1) 100%
        );
        padding-left: 0;
        padding-right: 0;
      }
      @include media("<=xs") {
        mask-image: none;
      }
      mask-image: linear-gradient(
        90deg,
        rgba(254, 254, 254, 0) 0%,
        rgba(254, 254, 254, 1) 37%,
        rgba(254, 254, 254, 1) 75%,
        rgba(254, 254, 254, 0) 100%
      );
    }

    .grid {
      position: relative;
    }

    &-background {
      @include media("<=xs") {
        background: none;
      }
      background: $bg-header-grey;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__header {
    @include media("<=m") {
      width: 75%;
    }
    @include media("<=s") {
      width: 50%;
    }
    @include media("<=xs") {
      display: none;
      padding-left: 0;
      position: inherit;
      transform: translateY(0);
      width: 100%;
      z-index: 0;
    }
    padding-left: $aria-half;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 58%;
    z-index: 1;

    &--no-image {
      position: inherit;
      transform: inherit;
      width: 100%;
    }
  }

  &__header--mobile {
    @include header-image-title-mobile();
  }

  &__header-title,
  &__header-subtitle {
    color: $blue;
  }

  &__header-subtitle {
    @include media("<=s") {
      margin-left: $gutter-double;
      margin-top: $gutter-one-third;
    }
    margin-left: $gutter-quadruple;
    margin-top: $gutter-half;

    &--small {
      margin-top: 0;
    }
  }

  &__header-link {
    @include link-style();
    @include media("<=s") {
      margin-top: $aria-one-third;
    }
    @include media("<=xs") {
      margin-top: $aria-one-sixth;
    }
    display: block;
    margin-top: $aria-two-thirds;

    &--with-subtitle {
      @include media("<=s") {
        margin-left: $gutter-double;
        margin-top: $aria-one-sixth;
      }
      margin-left: $gutter-quadruple;
      margin-top: $aria-one-third;
    }
  }
}

sup {
  font-size: 75%;
  position: relative;
  top: -0.5em;
  vertical-align: baseline;
}

sub {
  font-size: 75%;
  position: relative;
  top: 0.5em;
  vertical-align: baseline;
}

/* this is needed to have reasonably sized icons in the text editor */
cms-plugin svg {
  max-width: 1em;
}

.back-to-top-button-footer {
  border-radius: 50%;
  bottom: $aria;
  display: inline-block;
  opacity: 0;
  right: $aria;
  text-align: center;
  transition: all 0.5s;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    cursor: pointer;

    .arrow-up-footer {
      background-color: $blue;

      &:after {
        border-color: $white;
      }
    }
  }

  .arrow-up-footer {
    border: 2px solid white;
    border-radius: 50%;
    display: inline-block;
    height: 32px;
    width: 32px;

    &:after {
      border-right: 2px solid white;
      border-top: 2px solid white;
      content: "";
      display: inline-block;
      height: 14px;
      margin-top: 10px;
      transform: rotate(-45deg);
      width: 14px;
    }
  }
}

.back-to-top-button {
  border-radius: 50%;
  bottom: $aria;
  display: inline-block;
  opacity: 0;
  position: fixed;
  right: $aria;
  text-align: center;
  transition: all 0.5s;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    cursor: pointer;

    .arrow-up {
      background-color: $blue;

      &:after {
        border-color: $white;
      }
    }
  }

  .arrow-up {
    background-color: $white;
    border: $border-bold $blue;
    border-radius: 50%;
    display: inline-block;
    height: $back-to-top-button-size;
    width: $back-to-top-button-size;

    &:after {
      border-right: $border-bold $blue;
      border-top: $border-bold $blue;
      content: "";
      display: inline-block;
      height: $aria-two-thirds;
      margin-top: $back-to-top-button-margin-top;
      transform: rotate(-45deg);
      width: $aria-two-thirds;
    }
  }
}

.rca-styling {
  overflow: initial;

  .grid-50 {
    @include media("<=tablet") {
      width: 100%;
    }

    h2 {
      margin-top: 73px;

      @include media("<=tablet") {
        margin-top: 35px;
      }
    }
  }

  .xs-24 {
    @include media("<=tablet") {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .product-downloads,
  .product-formulations {
    .xs-24.document-item {
      padding: 20px 16px;
    }
  }

  .content-page {
    &--intro-image {
      background-image: url("/static/img/rca/rca-blue-gradient.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: center;
      padding-bottom: 72px;
      padding-top: 77px;
      z-index: 0;
      display: flex;
      align-items: center;
      @include media(">=m") {
        height: 649px;
      }

      .section--intro-image {
        z-index: 0;
      }

      &.section {
        &--rca-styling-blue-with-pink-gradient {
          background-image: url("/static/img/rca/rca-blue-with-pink-gradient.svg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position-y: bottom;
        }

        &--rca-styling-blue-with-green-gradient {
          background-image: url("/static/img/rca/rca-blue-with-green-gradient.svg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position-y: bottom;
        }
      }

      .grid {
        @include media(">s") {
          border: 2px solid $grey-v-l;
          border-radius: 20px;
          overflow: hidden;
          height: 376px;
          transform-style: preserve-3d;
          z-index: 1;
        }

        @include media(">=s") {
          width: calc(100% - 30px);
        }

        @include media(">=m") {
          width: calc(96% - 30px);
        }

        @include media(">=xl") {
          width: 1250px;
        }
      }

      @include media("<=tablet") {
        padding-bottom: 0;
        padding-top: 0;
      }
    }

    &--intro-image-skincare {
      background-image: url("/static/img/rca/rca-blue-with-pink-gradient.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: bottom;
    }

    &--intro-image-haircare {
      background-image: url("/static/img/rca/rca-blue-with-green-gradient.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: bottom;
    }

    &__header-title,
    &__header-subtitle {
      color: $grey-v-l;
      margin-left: 0;
      margin-top: 26px;
    }

    &__header-subtitle {
      letter-spacing: 0.015em;
    }

    &__header-title {
      margin-top: 0;
    }

    &__header-link {
      border: 1px solid $grey-v-l;
      border-radius: 16px;
      color: $grey-v-l;
      display: inline-block;
      font-size: 16px;
      line-height: 19px;
      margin-top: $aria;
      padding: 11px 13px;

      &:hover {
        background: transparent;
        border: 1px solid $blue-d;
        color: $blue-d;
      }

      &--with-subtitle {
        @include media("<=s") {
          margin-left: 0;
          margin-top: $aria-half;
        }
        margin-left: 0;
        margin-top: 26px;
      }
    }

    &__header {
      left: 49px;
      height: fit-content;
      padding-left: 0;
      position: relative;
      top: 50%;
      transform: translate(0, -50%);
      width: 50%;

      @include media("<tablet") {
        width: 100%;
      }

      @include media("<tablet") {
        display: block;
        margin-bottom: 34px;
        padding-left: 47px;
        padding-right: 47px;
        position: relative;
        top: unset;
        transform: unset;
        width: 100%;
        left: 0;
      }

      @include media("<=tablet") {
        padding-top: 115px;
      }
    }
  }

  .slick-slide .content-page {
    &__header {
      @include media("<tablet") {
        padding-left: 21px;
        padding-right: 21px;
      }
    }

    &--intro-image {
      &__border {
        display: none;
      }
    }
  }

  .content-page {
    &--intro-image {
      @include media(">=tablet", "<s") {
        .grid {
          height: 200px;
        }
      }

      &__border {
        @include media("<=tablet") {
          border: 2px solid $grey-v-l;
          border-radius: 20px;
          position: absolute;
          left: 20px;
          right: 20px;
          top: 37px;
          bottom: 42px;
          z-index: 2;
        }

        @include media(">tablet") {
          display: none;
        }
      }
    }
  }

  .slick-slide .content-page,
  .section--product.content-page {
    &--intro-image {
      .grid {
        overflow: initial;

        @include media(">=xs") {
          width: calc(100% - 30px);
        }

        @include media(">=m") {
          width: calc(96% - 30px);
        }

        @include media(">=xl") {
          width: 1250px;
        }
      }

      @include media(">tablet") {
        .grid {
          border: 2px solid $grey-v-l;
          border-radius: 20px;
          overflow: initial;
          height: 500px;
          transform-style: preserve-3d;
          z-index: 1;
          min-height: unset;
        }
      }
    }
  }

  .section--product.content-page {
    &--intro-image {
      @include media(">tablet") {
        .grid {
          height: 376px;
        }
      }
    }
  }

  .rca-styling.section--intro-image {
    height: 100%;
    mask-image: unset;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 40%;
    z-index: 0;
    background-position-x: center;

    @include media("<tablet") {
      height: 300px;
      position: inherit;
      width: 100%;
      margin: 0;
    }

    @include media(">xl") {
      right: calc(((100vw - 1250px) / 2) - 96px);
      width: 660px;
    }
  }

  .img {
    border-radius: 20px;
  }

  .slick-slideshow-arrow {
    margin-bottom: 60px;

    @include media("<=tablet") {
      margin-bottom: 46px;
    }
  }

  .section__title {
    margin-bottom: 44px;
    text-align: left;
  }

  .page-header-without-image-wrapper {
    padding-bottom: 52px;
    padding-top: 60px;

    .text-plugin > h1 {
      @include media("<=tablet") {
        padding-top: 0;
      }
    }

    .grid {
      border: 2px solid $grey-v-l;
      border-radius: 20px;
      overflow: hidden;
      padding: 100px 44px 108px 44px;
      position: relative;

      @include media("<=tablet") {
        width: calc(100% - 40px);
      }

      @include media(">=xl") {
        width: 1250px;
      }

      .text-plugin {
        width: 58%;

        @include media("<tablet") {
          width: 100%;
        }
      }
    }
  }

  .lead-text {
    @extend .grid;

    display: flex;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 85px;

    @include media("<=tablet") {
      padding-top: 0;
      padding-bottom: 70px;
    }

    h2 {
      margin-bottom: 41px;
      letter-spacing: 0.015em;
    }

    p:first-of-type {
      margin-bottom: 50px;
      letter-spacing: 0.015em;
      padding-right: 61px;
    }

    .grid:first-of-type {
      width: 45%;
      margin-left: 0;

      @include media("<tablet") {
        width: 100%;
      }

      &:nth-of-type(odd) {
        padding-left: 0;
        padding-right: 0;
      }

      &:nth-of-type(even) {
        padding-right: 0;
      }
    }

    .lead-text-image {
      @extend .xs-24;
      @extend .s-12;
      @include style-grid();

      img {
        width: 100%;
      }

      padding-left: 0;
      padding-right: 0;
      width: 55%;

      @include media("<=tablet") {
        display: none;
      }
    }
  }

  .text-with-read-more {
    &__read-more,
    &__read-less {
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 35px;
      color: $blue-d;
      cursor: pointer;

      svg {
        margin-bottom: -4px;
        margin-left: 4px;
      }

      &:hover {
        color: $btn-blue-l;

        svg {
          path {
            fill: $btn-blue-l;
          }
        }
      }
    }

    &__read-less {
      svg {
        transform: rotate(180deg);
      }
    }

    &__full {
      display: none;
    }
  }
}

.rca-styling.grid {
  padding: 145px 0 105px 0;
}

.product-overview-slider {
  .slick-slide {
    .card-wrapper {
      width: 100%;
      height: 100%;

      &:nth-of-type(odd) {
        padding-left: 0;
        padding-right: 0;
      }

      &:nth-of-type(even) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .slick-slide {
    .card {
      border: 1px solid $grey-v-l;
      border-radius: 10px;
      height: 100%;
      padding: 0;
      width: 100%;

      &__image {
        img {
          border-bottom: 1px solid $grey-v-l;
          border-radius: 10px 10px 0 0;
          height: 187px;
          object-fit: cover;
          overflow: hidden;
          width: 100%;
        }
      }

      &__info {
        padding: 10px 20px 0 20px;
      }

      &__info,
      &__info-link {
        background: transparent;

        h2 a,
        p a {
          color: #f6f6f6;
        }

        a {
          letter-spacing: 0.015em;
        }
      }
    }
  }
}

.image-with-border {
  margin-bottom: 60px;
  position: relative;

  @include media("<=xs") {
    margin-bottom: unset;
  }

  &__border-blue-with-pink-gradient,
  &__border-green-with-blue-gradient {
    .image-with-border__background {
      background-position: 0 0, 0 100%;
      background-repeat: no-repeat;
      background-size: 100% 1px;
      border-radius: 10px;
      height: 100%;
      position: absolute;
      top: 35px;
      width: 100%;

      @include media("<=xs") {
        height: 0;
        width: 0;
      }

      &.left {
        left: 0;
        margin-left: 36px;
        margin-right: 36px;
        width: calc(100% - 72px);
      }

      &.right {
        right: 30px;
      }
    }

    &.left {
      margin-right: 30px;
      padding-right: 0;

      @include media("<=xs") {
        left: 0;
      }
    }

    &.right {
      margin-left: 30px;

      @include media("<=xs") {
        margin-left: 0;
        right: 0;
      }
    }
  }

  &__border-blue-with-pink-gradient {
    .image-with-border__background {
      background-image: linear-gradient(90deg, $blue-d, $pink), linear-gradient(90deg, $blue-d, $pink);
      border-left: 1px solid $blue-d;
      border-right: 1px solid $pink;
    }
  }

  &__border-green-with-blue-gradient {
    .image-with-border__background {
      background-image: linear-gradient(90deg, $blue-d, #36aa48), linear-gradient(90deg, $blue-d, #36aa48);
      border-left: 1px solid $blue-d;
      border-right: 1px solid #36aa48;
    }
  }

  &__border-background-blue-with-pink-gradient,
  &__border-background-green-with-blue-gradient {
    .image-with-border__background {
      height: 100%;
      position: absolute;
      top: 60px;
      width: calc(100% - 15px);
      z-index: -1;

      @include media("<=xs") {
        height: 0;
        width: 0;
      }

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }
    }

    &.left {
      padding-right: 75px;

      @include media("<=xs") {
        left: 0;
        padding-right: 0;
      }
    }

    &.right {
      padding-left: 75px;

      @include media("<=xs") {
        padding-left: 0;
        right: 0;
      }
    }
  }

  &__border-background-blue-with-pink-gradient {
    .image-with-border__background {
      background: linear-gradient(185deg, rgba(27, 80, 142, 1) 40%, rgba(229, 0, 100, 0.6) 100%);
    }
  }

  &__border-background-green-with-blue-gradient {
    .image-with-border__background {
      background: linear-gradient(185deg, rgba(27, 80, 142, 1) 40%, rgb(54, 170, 72) 100%);
    }
  }

  &__border-blue {
    border: 1px solid $blue;
    border-radius: 10px;

    @include media("<=xs") {
      border: unset;
      border-radius: unset;
    }

    img {
      border-radius: 10px;

      @include media("<=xs") {
        border-radius: unset;
      }
    }
  }

  img {
    @include media("<=xs") {
      margin-left: -20px;
      margin-right: -20px;
      max-width: 100vw;
      width: 100vw;
    }
  }

  &__border-radius-img {
    border-radius: 20px;

    img {
      border-radius: 20px;
    }
  }

  &:hover {
    .image-with-border-disappear-on-hover {
      display: none !important;
    }
    .image-with-border-show-on-hover {
      display: inline !important;
    }
  }
}
