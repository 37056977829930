/**
 * Add horizontal margin to elements.
 *
 * @mixin
 * @param $sx the margin left value. Default value is `auto`
 * @param $dx the margin right value. Default value is `auto`
 * @example
 *  @include hmargin(3px);
 *  @include hmargin(3px, 4px);
 */
@mixin hmargin($sx: auto, $dx: auto) {
  margin-left: $sx;
  margin-right: $dx;
}

/**
 * Add vertical margin to elements.
 *
 * @mixin
 * @param $top the margin top value. Default value is `auto`
 * @param $bottom the margin bottom value. Default value is `auto`
 * @example
 *  @include vmargin(3px);
 *  @include vmargin(3px, 4px);
 */
@mixin vmargin($top: auto, $bottom: auto) {
  margin-bottom: $bottom;
  margin-top: $top;
}

/**
 * Add horizontal padding to elements.
 *
 * @mixin
 * @param $sx the padding left value. Default value is `auto`
 * @param $dx the padding right value. Default value is `auto`
 * @example
 *  @include hmargin(3px);
 *  @include hmargin(3px, 4px);
 */
@mixin hpadding($sx: auto, $dx: auto) {
  padding-left: $sx;
  padding-right: $dx;
}

/**
 * Add vertical padding to elements.
 *
 * @mixin
 * @param $top the margin left value. Default value is `auto`
 * @param $bottom the margin right value. Default value is `auto`
 * @example
 *  @include hmargin(3px);
 *  @include hmargin(3px, 4px);
 */
@mixin vpadding($top: auto, $bottom: auto) {
  padding-bottom: $bottom;
  padding-top: $top;
}

/**
 * Add a font size css rule in px. Only for retrocompatibility.
 *
 * @mixin
 * @deprecated
 * @param $size the font size without unit
 * @example
 *  @include font-size(18);
 */
@mixin font-size($size) {
  font-size: $size + px;
}

// Private mixin

@mixin h-like($fontsize) {
  color: $black;
  font-family: $main-font;
  font-size: $fontsize;
  font-weight: bold;
  line-height: $line-height-headings;
  margin-bottom: $gutter / 2;
  word-wrap: break-word;
}

// Undocumented staff... TODO VVV

%image-vertical-aligned {
  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

/// Placeholder to center element content using flexbox.
%centered-content {
  align-items: center;
  display: flex;
  justify-content: center;
}

// Carlo TODO consider removing this
%display-flex {
  display: flex;
}

@mixin list-style() {
  list-style: none;
  margin-left: $list-margin-left;

  li {
    color: $grey-d;
    position: relative;

    &:before {
      background: $blue;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: $size-one-tenth;
      margin-left: $list-dot-margin-left;
      margin-right: $list-dot-margin-right;
      vertical-align: middle;
      width: $size-one-tenth;
    }

    p {
      display: inline;
    }
  }
}

@mixin close-icon($height, $right, $top) {
  position: relative;

  .close-icon:before,
  .close-icon:after {
    background-color: $blue;
    content: " ";
    height: $height;
    position: absolute;
    right: $right;
    top: $top;
    width: $stick-size;
  }

  .close-icon:before {
    transform: rotate(45deg);
  }

  .close-icon:after {
    transform: rotate(-45deg);
  }
}

@mixin header-image() {
  @media only screen and (min-width: $header-breakpoint-min-xl) {
    height: $header-image-height-xl;
  }
  @media only screen and (max-width: $header-breakpoint-max-l) {
    height: $header-image-height-l;
  }
  @include media("<=s") {
    height: $header-image-height-m;
  }
  @include media("<=xs") {
    height: $header-image-height-xs;
  }
}

@mixin header-image-title-mobile() {
  @include media(">xs") {
    display: none;
  }
  @include vpadding($gutter-one-third, $gutter-one-third);
  background: $bg-light-grey;
  display: block;
  margin-top: 1px;
}

@mixin link-style() {
  color: $blue;

  svg {
    fill: $blue;
    height: $size-half;
    vertical-align: middle;
    width: $size;
  }

  &:hover {
    color: $orange;

    svg {
      fill: $orange;
    }
  }

  &.btn-form {
    color: $white;
  }
}

@mixin color-messages($color, $darken-percentage) {
  background-color: lighten($color, 50%);
  border-color: $color;
  color: darken($color, $darken-percentage);
}

@mixin style-grid() {
  @include media("<=s") {
    margin-bottom: $aria-half;
  }
  @include media("<=xs") {
    img {
      max-width: 100%;
      width: auto;
    }

    margin-bottom: $aria-one-third;
  }
  margin-bottom: $aria;
}

@mixin max-width-and-margin-right(
  $width-desktop,
  $width-tablet,
  $width-mobile,
  $margin-desktop,
  $margin-tablet,
  $margin-mobile
) {
  @include media("<=s") {
    margin-right: $margin-tablet;
    max-width: $width-tablet;
  }
  @include media("<=xs") {
    margin-right: $margin-mobile;
    max-width: $width-mobile;
  }
  margin-right: $margin-desktop;
  max-width: $width-desktop;
}

@mixin responsive-margin-top($margin-top-desktop, $margin-top-tablet, $margin-top-mobile) {
  @include media("<=s") {
    margin-top: $margin-top-tablet;
  }
  @include media("<=xs") {
    margin-top: $margin-top-mobile;
  }
  margin-top: $margin-top-desktop;
}

@mixin responsive-margin-bottom($margin-bottom-desktop, $margin-bottom-tablet, $margin-bottom-mobile) {
  @include media("<=s") {
    margin-bottom: $margin-bottom-tablet;
  }
  @include media("<=xs") {
    margin-bottom: $margin-bottom-mobile;
  }
  margin-bottom: $margin-bottom-desktop;
}

@mixin responsive-margin-left($margin-left-desktop, $margin-left-tablet, $margin-left-mobile) {
  @include media("<=s") {
    margin-left: $margin-left-tablet;
  }
  @include media("<=xs") {
    margin-left: $margin-left-mobile;
  }
  margin-left: $margin-left-desktop;
}

@mixin responsive-margin-right($margin-right-desktop, $margin-right-tablet, $margin-right-mobile) {
  @include media("<=s") {
    margin-right: $margin-right-tablet;
  }
  @include media("<=xs") {
    margin-right: $margin-right-mobile;
  }
  margin-right: $margin-right-desktop;
}

@mixin h-spacer() {
  @include media("<=m") {
    margin-top: $aria-two-thirds * 2;
  }
  @include media("<=s") {
    margin-top: $aria;
  }
  margin-top: $aria-double;
}

@mixin section-title() {
  @include media("<=s") {
    margin-bottom: $gutter-two-thirds * 2;
  }
  @include media("<=xs") {
    margin-bottom: $gutter-two-thirds;
  }
  color: $blue;
  margin-bottom: $aria-double;
  text-align: center;
  text-transform: capitalize;
}
