@mixin typography($map) {
  @each $mq, $args in $map {
    @include media($mq) {
      font-family: nth($args, 1);
      font-size: nth($args, 3);
      font-weight: nth($args, 2);
      line-height: nth($args, 4);
    }
  }
}

// Typography map
$typography-wysiwyg-map: (
  "p": (
    "<=s": $main-font $light 14px 1.6,
    ">s": $main-font $light 14px 1.6,
  ),
  "li": (
    "<=s": $main-font $light 14px 24px,
    ">s": $main-font $light 14px 24px,
  ),
  "h1": (
    "<=s": $main-font $semi-bold 20px 1,
    ">s": $main-font $semi-bold 26px 1,
  ),
  "h2": (
    "<=s": $main-font $semi-bold 18px 24px,
    ">s": $main-font $semi-bold 22px 24px,
  ),
  "h3": (
    "<=s": $main-font $semi-bold 16px 21px,
    ">s": $main-font $semi-bold 20px 21px,
  ),
  "h4": (
    "<=s": $main-font $semi-bold 14px 21px,
    ">s": $main-font $semi-bold 18px 21px,
  ),
  "h5": (
    "<=s": $main-font $semi-bold 14px 21px,
    ">s": $main-font $semi-bold 14px 21px,
  ),
);

.wysiwyg {
  @each $element, $tmap in $typography-wysiwyg-map {
    #{$element} {
      @include typography($tmap);
    }
  }
}

$typography-classes-map: (
  "footer__title": (
    ">s": $main-font $semi-bold 20px 30px,
    "<=s": $main-font $semi-bold 17px 24px,
    "<=xs": $main-font $semi-bold 15px 20px,
  ),
  "footer__title__main__v2": (
    ">s": $main-font $light 36px 43.2px,
    "<=s": $main-font $light 36px 43.2px,
    "<=xs": $main-font $light 36px 43.2px,
  ),
  "footer__title__span__v2": (
    ">s": $main-font $semi-bold 36px 43.2px,
    "<=s": $main-font $semi-bold 36px 43.2p,
    "<=xs": $main-font $semi-bold 36px 43.2p,
  ),
  "footer__section p": (
    ">s": $main-font $light 15px 23px,
    "<=s": $main-font $light 14px 20px,
    "<=xs": $main-font $light 13px 18px,
  ),
  "footer__section__v2 p": (
    ">s": $main-font $normal 16px 21.79px,
    "<=s": $main-font $normal 16px 21.79px,
    "<=xs": $main-font $normal 16px 21.79px,
  ),
  "footer__section__v2 p.first": (
    ">s": $main-font $semi-bold 16px 21.79px,
    "<=s": $main-font $semi-bold 16px 21.79px,
    "<=xs": $main-font $semi-bold 16px 21.79px,
  ),
  "footer__section__v2 p > a": (
    ">s": $main-font $normal 16px 21.79px,
    "<=s": $main-font $normal 16px 21.79px,
    "<=xs": $main-font $normal 16px 21.79px,
  ),
  "footer__footer__options-list > a": (
    ">s": $main-font $semi-bold 18px 24.51px,
    "<=s": $main-font $light 18px 24.51px,
    "<=xs": $main-font $light 18px 24.51px,
  ),
  "rca-styling .footer__section p": (
    ">=xs": $main-font $light 15px 20px,
    "<=xs": $main-font $light 15px 20px,
  ),
  "footer__languages-element > span": (
    ">s": $main-font $bold 12px 16.34px,
    "<=s": $main-font $bold 12px 16.34px,
    "<=xs": $main-font $bold 12px 16.34px,
  ),
  "footer__languages-element > a": (
    "<=s": $main-font $normal 18px 24.51px,
    "<=xs": $main-font $normal 18px 24.51px,
  ),
  "footer__copyright-flex > p": (
    "<=s": $main-font $normal 14px 19.07px,
    ">s": $main-font $normal 14px 19.07px,
  ),
  "footer__copyright-flex > div > a": (
    "<=s": $main-font $normal 14px 19.07px,
    ">s": $main-font $normal 14px 19.07px,
  ),
  "header__languages-element > a": (
    "<=s": $main-font $light 15px 1,
    ">s": $main-font $light 15px 1,
  ),
  "header__languages-element-footer > a": (
    "<=s": $main-font $normal 14px 19.07px,
    ">s": $main-font $normal 14px 19.07px,
  ),
  "header__main-nav-element > a": (
    ">=l": $main-font $light 22px 1,
    "<l": $main-font $light 20px 1,
    "<m": $main-font $light 18px 1,
  ),
  "header__main-nav-list--top a": (
    "<=m": $main-font $light 14px 1,
    ">m": $main-font $light 16px 1,
  ),
  "header__main-nav-mobile-element--parent > a": (
    "<=m": $main-font $semi-bold 17px 1,
    ">m": $main-font $semi-bold 17px 1,
  ),
  "header__main-nav-mobile-element > a": (
    "<=m": $main-font $light 15px 1,
    ">m": $main-font $light 15px 1,
  ),
  "header__sub-menu-element > a": (
    "<=m": $main-font $light 20px 1,
    ">m": $main-font $light 20px 1,
  ),
  "header__main-nav-mobile-sub-menu-mobile-element > a": (
    "<=m": $main-font $light 15px 1,
    ">m": $main-font $light 15px 1,
  ),
  "header__third-level-menu-element > a": (
    "<=m": $main-font $light 20px 1,
    ">m": $main-font $light 20px 1,
  ),
  "header__main-nav-mobile-third-level-menu-element > a": (
    "<=m": $main-font $light 15px 1,
    ">m": $main-font $light 15px 1,
  ),
  "product-menu__category": (
    "<=m": $main-font $light 20px 1,
    ">m": $main-font $light 20px 1,
  ),
  "product-menu-mobile__category": (
    "<=m": $main-font $semi-bold 15px 1,
    ">m": $main-font $semi-bold 15px 1,
  ),
  "product-menu__element > a": (
    "<=m": $main-font $light 15px 1,
    ">m": $main-font $light 15px 1,
  ),
  "product-menu-mobile__element > a": (
    "<=m": $main-font $light 15px 1,
    ">m": $main-font $light 15px 1,
  ),
  "header__main-nav-submenu-close": (
    "<=m": $main-font $semi-bold 14px 1,
    ">m": $main-font $semi-bold 14px 1,
  ),
  "header__main-nav-mobile-close": (
    "<=m": $main-font $semi-bold 14px 1,
    ">m": $main-font $semi-bold 14px 1,
  ),
  "search-options > p": (
    ">s": $main-font $semi-bold 15px 20px,
    "<=s": $main-font $semi-bold 14px 20px,
    "<=xs": $main-font $semi-bold 13px 18px,
  ),
  "search-options__option": (
    ">s": $main-font $semi-bold 15px 20px,
    "<=s": $main-font $semi-bold 14px 20px,
    "<=xs": $main-font $semi-bold 13px 18px,
  ),
  "search-box__input": (
    "<=s": $main-font $light 25px 1,
    ">s": $main-font $light 25px 1,
  ),
  "search-input > input": (
    ">s": $main-font $light 25px 34px,
    "<=s": $main-font $light 20px 27px,
    "<=xs": $main-font $light 17px 23px,
  ),
  "search-input--container > input": (
    ">s": $main-font $light 25px 34px,
    "<=s": $main-font $light 20px 27px,
    "<=xs": $main-font $light 17px 23px,
  ),
  "search-input > input::placeholder": (
    ">s": $main-font $light 25px 0.8,
    "<=s": $main-font $light 20px 0.8,
    "<=xs": $main-font $light 17px 0.8,
  ),
  "search-input--container > input::placeholder": (
    ">s": $main-font $light 25px 0.8,
    "<=s": $main-font $light 20px 0.8,
    "<=xs": $main-font $light 17px 0.8,
  ),
  "footer__contact-info": (
    "<=s": $main-font $light 15px 23px,
    ">s": $main-font $light 15px 23px,
  ),
  "footer__copyright": (
    ">s": $main-font $light 15px 23px,
    "<=s": $main-font $light 14px 20px,
    "<=xs": $main-font $light 13px 18px,
  ),
  "filters__options": (
    "<=s": $main-font $light 14px 19px,
    ">s": $main-font $light 14px 19px,
  ),
  "filters__labels-filter": (
    "<=s": $main-font $semi-bold 14px 19px,
    ">s": $main-font $semi-bold 14px 19px,
  ),
  "filters__applications-filter": (
    "<=s": $main-font $light 14px 19px,
    ">s": $main-font $light 14px 19px,
  ),
  "rca-styling .filters__applications-filter": (
    "<=s": $main-font $normal 20px 33px,
    ">s": $main-font $normal 18px 25px,
  ),
  "rca-styling .filters__labels-filter": (
    "<=s": $main-font $normal 20px 33px,
    ">s": $main-font $normal 18px 25px,
  ),
  "filters__categories-filter": (
    "<=s": $main-font $light 14px 19px,
    ">s": $main-font $light 14px 19px,
  ),
  "filters__categories-select-all": (
    "<=s": $main-font $light 14px 19px,
    ">s": $main-font $light 14px 19px,
  ),
  "filters__categories-label > h3": (
    ">s": $main-font $semi-bold 19px 21px,
    "<=s": $main-font $semi-bold 22px 24px,
  ),
  "filters__applications-label > h3": (
    ">s": $main-font $semi-bold 19px 21px,
    "<=s": $main-font $semi-bold 22px 24px,
  ),
  "filters__functionality-label > h3": (
    ">s": $main-font $semi-bold 19px 21px,
    "<=s": $main-font $semi-bold 22px 24px,
  ),
  "filters__sustainability-label > h3": (
    ">s": $main-font $semi-bold 19px 21px,
    "<=s": $main-font $semi-bold 22px 24px,
  ),
  "filters__exclusion-criterias-label > h3": (
    ">s": $main-font $semi-bold 19px 21px,
    "<=s": $main-font $semi-bold 22px 24px,
  ),
  "filters__country-label > h3": (
    ">s": $main-font $semi-bold 19px 21px,
    "<=s": $main-font $semi-bold 22px 24px,
  ),
  "documents-download__info > h3": (
    ">s": $main-font $semi-bold 14px 19px,
    "<=s": $main-font $semi-bold 14px 20px,
    "<=xs": $main-font $semi-bold 13px 18px,
  ),
  "rca-styling .documents-download__info > h3": (
    ">s": $main-font $semi-bold 16px 19px,
    "<=s": $main-font $semi-bold 16px 19px,
    "<=xs": $main-font $semi-bold 16px 19px,
  ),
  "rca-styling .documents-download__info > h3 > span": (
    ">s": $main-font $semi-bold 14px 19px,
    "<=s": $main-font $semi-bold 14px 19px,
    "<=xs": $main-font $semi-bold 14px 19px,
  ),
  "documents-download__info__white > h3": (
    ">s": $main-font $semi-bold 14px 19px,
    "<=s": $main-font $semi-bold 14px 20px,
    "<=xs": $main-font $semi-bold 13px 18px,
  ),
  "documents-download__language a": (
    "<=s": $main-font $semi-bold 14px 19px,
    ">s": $main-font $semi-bold 14px 19px,
  ),
  "documents-download__permission > p": (
    "<=s": $main-font $semi-bold 14px 19px,
    ">s": $main-font $semi-bold 14px 19px,
  ),
  "document-item__item-info > h3": (
    ">s": $main-font $semi-bold 20px 25px,
    "<=s": $main-font $semi-bold 17px 24px,
    "<=xs": $main-font $semi-bold 15px 20px,
  ),
  "rca-styling .press-releases-section .document-item__item-info > h3": (
    ">s": $secondary-font-rca-styling $light 20px 25px,
    "<=s": $secondary-font-rca-styling $light 20px 25px,
    "<=xs": $secondary-font-rca-styling $light 20px 25px,
  ),
  "rca-styling .document-item__events-container .document-item__item-info > h3": (
    ">s": $main-font-rca-styling $light 75px 84px,
    "<=s": $main-font-rca-styling $light 50px 70px,
    "<=xs": $main-font-rca-styling $light 40px 36px,
  ),
  "document-item__item-info > p": (
    ">s": $main-font $light 15px 23px,
    "<=s": $main-font $light 14px 20px,
    "<=xs": $main-font $light 13px 18px,
  ),
  "rca-styling .document-item__events-container .document-item__item-info > p": (
    ">s": $main-font $semi-bold 20px 28px,
    "<=s": $main-font $semi-bold 18px 20px,
    "<=xs": $main-font $semi-bold 16px 18px,
  ),
  "rca-styling .document-item--documentation .document-item__item-info > h3": (
    ">s": $main-font-rca-styling $light 20px 25px,
    "<=s": $main-font-rca-styling $light 20px 25px,
    "<=xs": $main-font-rca-styling $light 20px 25px,
  ),
  "document-item__item-description p": (
    ">s": $main-font $light 15px 25px,
    "<=s": $main-font $light 14px 20px,
    "<=xs": $main-font $light 13px 18px,
  ),
  "section__title": (
    ">s": $main-font $light 35px 47px,
    "<=s": $main-font $light 30px 40px,
    "<=xs": $main-font $light 25px 35px,
  ),
  "rca-styling .section__title": (
    ">s": $secondary-font-rca-styling $medium 48px 65px,
    "<=s": $secondary-font-rca-styling $medium 42px 60px,
    "<=xs": $secondary-font-rca-styling $medium 36px 55px,
  ),
  "section__subtitle": (
    "<=s": $main-font $semi-bold 18px 22px,
    ">s": $main-font $semi-bold 20px 26px,
  ),
  "section-download .text-plugin h1": (
    ">s": $main-font $light 35px 47px,
    "<=s": $main-font $light 30px 40px,
    "<=xs": $main-font $light 25px 35px,
  ),
  "section-download .text-plugin h2": (
    ">s": $main-font $light 35px 47px,
    "<=s": $main-font $light 30px 40px,
    "<=xs": $main-font $light 25px 35px,
  ),
  "section-download .text-plugin h3": (
    ">s": $main-font $light 35px 47px,
    "<=s": $main-font $light 30px 40px,
    "<=xs": $main-font $light 25px 35px,
  ),
  "rca-styling .contact-person .section__title": (
    ">s": $main-font-rca-styling $bold 48px 65px,
    "<=s": $main-font-rca-styling $bold 25px 36px,
    "<=xs": $main-font-rca-styling $bold 25px 36px,
  ),
  "visibility-button": (
    "<=s": $main-font $semi-bold 14px 19px,
    ">s": $main-font $semi-bold 14px 19px,
  ),
  "employee__info-name": (
    ">l": $main-font $semi-bold 20px 28px,
    "<=l": $main-font $semi-bold 18px 24px,
    "<=m": $main-font $semi-bold 20px 28px,
    "<=s": $main-font $semi-bold 17px 24px,
    "<=xs": $main-font $semi-bold 15px 20px,
  ),
  "rca-styling .employee__info-name, .map-distributor__info-name, .map-distributor__info-address-label": (
    ">l": $main-font $semi-bold 20px 33px,
    "<=l": $main-font $semi-bold 20px 33px,
  ),
  "rca-styling .team-page .employee__info-name": (
    ">l": $main-font $normal 18px 28px,
    "<=l": $main-font $normal 18px 28px,
  ),
  "employee__info-detail": (
    ">l": $main-font $light 15px 24px,
    "<=l": $main-font $light 14px 20px,
    "<=m": $main-font $light 15px 24px,
    "<=xs": $main-font $light 12px 18px,
  ),
  "rca-styling .employee__info-detail, .map-distributor__info-address": (
    ">l": $main-font $light 18px 24px,
    "<=l": $main-font $light 18px 24px,
  ),
  "rca-styling .team-page .employee__info-detail": (
    ">l": $main-font $light 16px 24px,
    "<=l": $main-font $light 16px 24px,
  ),
  "employee__info-mail": (
    ">l": $main-font $light 15px 24px,
    "<=l": $main-font $light 14px 20px,
    "<=m": $main-font $light 15px 24px,
    "<=xs": $main-font $light 12px 18px,
  ),
  "rca-styling .employee__info-mail, .map-distributor__info-mail, .map-distributor__info-website": (
    ">l": $main-font $light 16px 28px,
    "<=l": $main-font $light 16px 28px,
  ),
  "rca-styling .team-page .employee__info-mail": (
    ">l": $main-font $normal 16px 22px,
    "<=l": $main-font $normal 16px 22px,
  ),
  "employee__info-phone--number": (
    ">l": $main-font $light 15px 24px,
    "<=l": $main-font $light 14px 20px,
    "<=m": $main-font $light 15px 24px,
    "<=xs": $main-font $light 12px 18px,
  ),
  "rca-styling .employee__info-phone--number, .map-distributor__info-phone--number": (
    ">l": $main-font $light 16px 33px,
    "<=l": $main-font $light 16px 33px,
  ),
  "rca-styling .team-page .employee__info-phone--number": (
    ">l": $main-font $normal 16px 22px,
    "<=l": $main-font $normal 16px 22px,
  ),
  "product__info-title": (
    "<=s": $main-font $semi-bold 15px 20px,
    ">s": $main-font $semi-bold 20px 25px,
  ),
  "card__info-title > a": (
    ">s": $main-font $semi-bold 20px 25px,
    "<=s": $main-font $semi-bold 17px 20px,
    "<=xs": $main-font $semi-bold 15px 20px,
  ),
  "rca-styling .card__info-title > a": (
    ">s": $main-font-rca-styling $light 20px 25px,
    "<=s": $main-font-rca-styling $light 17px 20px,
    "<=xs": $main-font-rca-styling $light 15px 20px,
  ),
  "product-overview-slider .card__info-title > a": (
    ">s": $main-font-rca-styling $light 20px 25px,
    "<=s": $main-font-rca-styling $light 17px 20px,
    "<=xs": $main-font-rca-styling $light 15px 20px,
  ),
  "card__info-link > a": (
    ">s": $main-font $light 14px 23px,
    "<=s": $main-font $light 14px 20px,
    "<=xs": $main-font $light 13px 18px,
  ),
  "rca-styling .card__info-link > a": (
    ">s": $main-font $normal 16px 22px,
    "<=s": $main-font $normal 14px 20px,
    "<=xs": $main-font $normal 14px 18px,
  ),
  "product-overview-slider .card__info-link > a": (
    ">s": $main-font $light 16px 24px,
    "<=s": $main-font $light 14px 20px,
    "<=xs": $main-font $light 13px 18px,
  ),
  "product__info-link": (
    "<=s": $main-font $light 14px 25px,
    ">s": $main-font $light 13px 18px,
  ),
  "product-description__details h1": (
    ">s": $main-font $light 35px 45px,
    "<=s": $main-font $light 30px 40px,
    "<=xs": $main-font $light 25px 35px,
  ),
  "product-description__details h2": (
    ">s": $main-font $light 35px 45px,
    "<=s": $main-font $light 30px 40px,
    "<=xs": $main-font $light 25px 35px,
  ),
  "product-description__details h3": (
    ">s": $main-font $semi-bold 24px 40px,
    "<=s": $main-font $semi-bold 17px 25px,
    "<=xs": $main-font $semi-bold 15px 20px,
  ),
  "product-description__details > p": (
    ">s": $main-font $light 21px 32px,
    "<=s": $main-font $light 17px 23px,
    "<=xs": $main-font $light 15px 20px,
  ),
  "rca-styling .product-description__subtitle": (
    ">s": $secondary-font-rca-styling $medium 48px 65px,
    "<=s": $secondary-font-rca-styling $medium 25px 36px,
    "<=xs": $secondary-font-rca-styling $medium 25px 36px,
  ),
  "supplier__link": (
    ">s": $main-font $semi-bold 20px 30px,
    "<=s": $main-font $semi-bold 17px 25px,
    "<=xs": $main-font $semi-bold 15px 20px,
  ),
  "product-description__details li": (
    ">s": $main-font $light 21px 32px,
    "<=s": $main-font $light 17px 23px,
    "<=xs": $main-font $light 15px 20px,
  ),
  "breadcrumbs__list-item": (
    "<=s": $main-font $light 15px 25px,
    ">s": $main-font $light 15px 25px,
  ),
  "breadcrumbs__list-item--active": (
    "<=s": $main-font $semi-bold 15px 25px,
    ">s": $main-font $semi-bold 15px 25px,
  ),
  "product-applications__title > a ": (
    ">s": $main-font $semi-bold 24px 40px,
    "<=s": $main-font $semi-bold 17px 25px,
    "<=xs": $main-font $semi-bold 15px 20px,
  ),
  "product-applications__application": (
    ">s": $main-font $light 21px 25px,
    "<=s": $main-font $light 17px 25px,
    "<=xs": $main-font $light 15px 20px,
  ),
  "product-description__details > ul > li ": (
    ">s": $main-font $light 21px 32px,
    "<=s": $main-font $light 17px 25px,
    "<=xs": $main-font $light 15px 20px,
  ),
  "content-page__header-title": (
    ">s": $main-font $light 64px 64px,
    "<=s": $main-font $light 30px 40px,
    "<=xs": $main-font $light 25px 35px,
  ),
  "rca-styling.content-page__header-title": (
    ">s": $main-font-rca-styling $light 75px 84px,
    "<=s": $main-font-rca-styling $medium 48px 65px,
    "<=xs": $main-font-rca-styling $medium 48px 65px,
  ),
  "content-page__header-subtitle": (
    ">s": $main-font $light 35px 45px,
    "<=s": $main-font $light 20px 27px,
    "<=xs": $main-font $light 17px 23px,
  ),
  "rca-styling .content-page__header-subtitle": (
    ">s": $main-font $normal 20px 28px,
    "<=s": $main-font $normal 20px 28px,
    "<=xs": $main-font $normal 20px 28px,
  ),
  "content-page__header-title--small": (
    ">s": $main-font $light 35px 45px,
    "<=s": $main-font $light 30px 40px,
    "<=xs": $main-font $light 25px 35px,
  ),
  "content-page__header-subtitle--small": (
    ">s": $main-font $light 22px 32px,
    "<=s": $main-font $light 18px 24px,
    "<=xs": $main-font $light 16px 20px,
  ),
  "content-page__header-link": (
    ">xs": $main-font $semi-bold 14px 19px,
    "<=xs": $main-font $semi-bold 13px 18px,
  ),
  "product-intro__title": (
    ">s": $main-font $light 64px 64px,
    "<=s": $main-font $light 30px 40px,
    "<=xs": $main-font $light 25px 35px,
  ),
  "rca-styling .product-intro__title": (
    ">s": $main-font-rca-styling $light 75px 84px,
    "<=s": $main-font-rca-styling $light 64px 70px,
    "<=xs": $main-font-rca-styling $medium 43px 65px,
  ),
  "product-intro__title--small": (
    ">s": $main-font $light 35px 45px,
    "<=s": $main-font $light 30px 40px,
    "<=xs": $main-font $light 25px 35px,
  ),
  "product-intro__subtitle": (
    ">s": $main-font $light 35px 45px,
    "<=s": $main-font $light 20px 27px,
    "<=xs": $main-font $light 17px 23px,
  ),
  "rca-styling .product-intro__subtitle": (
    ">s": $secondary-font-rca-styling $medium 48px 65px,
    "<=s": $secondary-font-rca-styling $medium 30px 45px,
    "<=xs": $secondary-font-rca-styling $medium 25px 36px,
  ),
  "product-intro__subtitle--small": (
    ">s": $main-font $light 22px 32px,
    "<=s": $main-font $light 18px 24px,
    "<=xs": $main-font $light 16px 20px,
  ),
  "products__list-item-name": (
    "<=s": $main-font $light 17px 23px,
    ">s": $main-font $light 24px 40px,
  ),
  "products__list-item-characteristics": (
    "<=s": $main-font $light 17px 23px,
    ">s": $main-font $light 21px 32px,
  ),
  "products__list-item-subtitle": (
    "<=s": $main-font $semi-bold 17px 23px,
    ">s": $main-font $semi-bold 21px 32px,
  ),
  "custom-list > li": (
    "<=s": $main-font $light 17px 23px,
    ">s": $main-font $light 21px 32px,
  ),
  "home-box__title": (
    ">s": $main-font $light 50px 68px,
    "<=s": $main-font $light 40px 45px,
    "<=xs": $main-font $light 35px 40px,
  ),
  "btn-login": (
    "<=s": $main-font $semi-bold 17px 23px,
    ">s": $main-font $semi-bold 14px 25px,
  ),
  "header__login-box-action": (
    "<=s": $main-font $light 17px 23px,
    ">s": $main-font $light 14px 25px,
  ),
  "header__login-box-title": (
    "<=s": $main-font $semi-bold 18px 25px,
    ">s": $main-font $semi-bold 20px 27px,
  ),
  "header__login-box-company": (
    "<=s": $main-font $semi-bold 13px 22px,
    ">s": $main-font $semi-bold 15px 25px,
  ),
  "header__cart-title": (
    "<=s": $main-font $light 17px 23px,
    ">s": $main-font $light 20px 27px,
  ),
  "header__cart-product-item--name": (
    "<=s": $main-font $light 12px 40px,
    ">s": $main-font $light 14px 40px,
  ),
  "header__cart-count": (
    "<=s": $main-font $semi-bold 9px 25px,
    ">s": $main-font $semi-bold 9px 25px,
  ),
  "lead-text h1": (
    ">s": $main-font $light 35px 45px,
    "<=s": $main-font $light 30px 40px,
    "<=xs": $main-font $light 25px 35px,
  ),
  "rca-styling lead-text h1": (
    ">s": $main-font-rca-styling $light 35px 45px,
    "<=s": $main-font-rca-styling $light 30px 40px,
    "<=xs": $main-font-rca-styling $light 25px 35px,
  ),
  "lead-text h2": (
    ">s": $main-font $light 35px 45px,
    "<=s": $main-font $light 30px 40px,
    "<=xs": $main-font $light 25px 35px,
  ),
  "lead-text h3": (
    ">s": $main-font $semi-bold 24px 40px,
    "<=s": $main-font $semi-bold 22px 35px,
    "<=xs": $main-font $semi-bold 20px 30px,
  ),
  "lead-text h4": (
    ">s": $main-font $semi-bold 22px 35px,
    "<=s": $main-font $semi-bold 20px 30px,
    "<=xs": $main-font $semi-bold 18px 25px,
  ),
  "lead-text p": (
    ">s": $main-font $light 20px 30px,
    "<=s": $main-font $light 17px 25px,
    "<=xs": $main-font $light 15px 20px,
  ),
  "text-plugin a.arrow-link": (
    ">xs": $main-font $semi-bold 14px 19px,
    "<=xs": $main-font $semi-bold 13px 18px,
  ),
  "text-plugin h1": (
    ">s": $main-font $light 35px 45px,
    "<=s": $main-font $light 30px 40px,
    "<=xs": $main-font $light 25px 35px,
  ),
  "rca-styling .text-plugin h1": (
    ">s": $main-font-rca-styling $light 75px 84px,
    "<=s": $main-font-rca-styling $light 48px 65px,
    "<=tablet": $secondary-font-rca-styling $medium 25px 36px,
    "<=xs": $secondary-font-rca-styling $medium 25px 36px,
  ),
  "text-plugin h2": (
    ">s": $main-font $light 35px 45px,
    "<=s": $main-font $light 30px 40px,
    "<=xs": $main-font $light 25px 35px,
  ),
  "rca-styling .text-plugin h2": (
    ">s": $main-font-rca-styling $bold 48px 65px,
    "<=s": $main-font-rca-styling $bold 25px 36px,
    "<=tablet": $secondary-font-rca-styling $bold 25px 36px,
    "<=xs": $secondary-font-rca-styling $bold 23px 30px,
  ),
  "text-plugin h3": (
    ">s": $main-font $semi-bold 24px 40px,
    "<=s": $main-font $semi-bold 22px 35px,
    "<=xs": $main-font $semi-bold 20px 30px,
  ),
  "text-plugin h4": (
    ">s": $main-font $semi-bold 22px 35px,
    "<=s": $main-font $semi-bold 20px 30px,
    "<=xs": $main-font $semi-bold 18px 25px,
  ),
  "text-plugin p": (
    ">s": $main-font $light 20px 30px,
    "<=s": $main-font $light 17px 25px,
    "<=xs": $main-font $light 15px 20px,
  ),
  "rca-styling .text-plugin p": (
    ">s": $main-font $light 18px 24px,
    "<=s": $main-font $light 20px 28px,
    "<=xs": $main-font $normal 20px 28px,
  ),
  "rca-styling .text-plugin p span": (
    ">s": $main-font $light 18px 24px,
    "<=s": $main-font $light 20px 28px,
    "<=xs": $main-font $normal 20px 28px,
  ),
  "rca-styling .text-with-read-more__full p": (
    ">s": $main-font $light 18px 24px,
    "<=s": $main-font $light 20px 28px,
  ),
  "rca-styling .text-with-read-more__truncated p": (
    ">s": $main-font $light 18px 24px,
    "<=s": $main-font $light 20px 28px,
  ),
  "product-selection-tool-table p": (
    ">s": $main-font $light 20px 30px,
    "<=s": $main-font $light 17px 25px,
    "<=xs": $main-font $light 15px 20px,
  ),
  "table-keyfeatures > p": (
    ">s": $main-font $normal 16px 20px,
    "<=s": $main-font $normal 14px 18px,
  ),
  "product-selection-tool-table th": (
    ">s": $main-font $bold 16px 20px,
    "<=s": $main-font $bold 14px 18px,
  ),
  "product-selection-tool-table td": (
    ">s": $main-font $normal 16px 20px,
    "<=s": $main-font $normal 14px 18px,
  ),
  "text-plugin .blue-text ": (
    ">s": $main-font $semi-bold 20px 30px,
    "<=s": $main-font $semi-bold 17px 25px,
    "<=xs": $main-font $semi-bold 15px 20px,
  ),
  "text-plugin .blue-text a ": (
    ">s": $main-font $semi-bold 20px 30px,
    "<=s": $main-font $semi-bold 17px 25px,
    "<=xs": $main-font $semi-bold 15px 20px,
  ),
  "text-plugin ul li ": (
    ">s": $main-font $light 20px 30px,
    "<=s": $main-font $light 17px 25px,
    "<=xs": $main-font $light 15px 20px,
  ),
  "rca-styling .text-plugin ul li ": (
    ">s": $main-font $light 20px 30px,
    "<=s": $main-font $light 20px 28px,
  ),
  "text-plugin p a ": (
    ">s": $main-font $semi-bold inherit inherit,
    "<=s": $main-font $semi-bold inherit inherit,
    "<=xs": $main-font $semi-bold inherit inherit,
  ),
  "rca-styling .text-plugin p a": (
    ">s": $main-font $semi-bold 16px 19px,
    "<=s": $main-font $semi-bold 14px 16px,
    "<=xs": $main-font $semi-bold 14px 14px,
  ),
  "rca-styling text-plugin .blue-text ": (
    ">s": $main-font $light 20px 30px,
    "<=s": $main-font $normal 20px 28px,
    "<=xs": $main-font $normal 20px 28px,
  ),
  "rca-styling p .blue-text a": (
    ">s": $main-font $semi-bold 18px 19px,
    "<=s": $main-font $semi-bold 18px 19px,
    "<=xs": $main-font $semi-bold 18px 19px,
  ),
  "supplier__description h3 a": (
    ">s": $main-font $semi-bold 24px 40px,
    "<=s": $main-font $semi-bold 22px 35px,
    "<=xs": $main-font $semi-bold 20px 30px,
  ),
  "suppliers__list p a": (
    ">s": $main-font $semi-bold 20px 30px,
    "<=s": $main-font $semi-bold 17px 25px,
    "<=xs": $main-font $semi-bold 15px 20px,
  ),
  "suppliers__list p": (
    ">s": $main-font $light 20px 30px,
    "<=s": $main-font $light 17px 25px,
    "<=xs": $main-font $light 15px 20px,
  ),
  "grey-box > h2": (
    ">s": $main-font $semi-bold 20px 25px,
    "<=s": $main-font $semi-bold 17px 25px,
    "<=xs": $main-font $semi-bold 15px 20px,
  ),
  "grey-box > p": (
    ">s": $main-font $light 20px 25px,
    "<=s": $main-font $light 17px 25px,
    "<=xs": $main-font $light 13px 18px,
  ),
  "grid-narrow > h2": (
    "<=s": $main-font $semi-bold 19px 24px,
    ">s": $main-font $semi-bold 24px 40px,
  ),
  "grid-narrow > h3": (
    "<=s": $main-font $semi-bold 16px 24px,
    ">s": $main-font $semi-bold 20px 40px,
  ),
  "grid-narrow > h4": (
    "<=s": $main-font $semi-bold 13px 24px,
    ">s": $main-font $semi-bold 18px 40px,
  ),
  "grid-narrow p": (
    "<=s": $main-font $light 17px 23px,
    ">s": $main-font $light 21px 32px,
  ),
  "grid-narrow > h2.section__title--no-capitalize": (
    ">s": $main-font $light 35px 47px,
    "<=s": $main-font $light 30px 40px,
    "<=xs": $main-font $light 25px 35px,
  ),
  "supplier__title": (
    ">s": $main-font $semi-bold 24px 40px,
    "<=s": $main-font $semi-bold 17px 25px,
    "<=xs": $main-font $semi-bold 15px 20px,
  ),
  "rahn-form": (
    "<=s": $main-font $light 16px 19px,
    ">s": $main-font $light 18px 22px,
  ),
  "rahn-form label": (
    "<=s": $main-font $semi-bold 15px 20px,
    ">s": $main-font $semi-bold 15px 20px,
  ),
  "rahn-form [type='text']": (
    "<=s": $main-font $light 20px 27px,
    ">s": $main-font $light 20px 27px,
  ),
  "rahn-form [type='email']": (
    "<=s": $main-font $light 20px 27px,
    ">s": $main-font $light 20px 27px,
  ),
  "rahn-form select": (
    "<=s": $main-font $light 20px 27px,
    ">s": $main-font $light 20px 27px,
  ),
  "filters__country-filter select": (
    "<=s": $main-font $light 20px 27px,
    ">s": $main-font $light 20px 27px,
  ),
  "rahn-form > p": (
    "<=s": $main-font $light 16px 19px,
    ">s": $main-font $light 18px 22px,
  ),
  "rahn-form__actions a": (
    "<=s": $main-font $light 14px 19px,
    ">s": $main-font $light 14px 19px,
  ),
  "aldryn-form .aldryn-form__success-text": (
    ">s": $main-font $light 20px 30px,
    "<=s": $main-font $light 17px 25px,
    "<=xs": $main-font $light 15px 20px,
  ),
  "btn-form": (
    "<=s": $main-font $semi-bold 14px 25px,
    ">s": $main-font $semi-bold 14px 25px,
  ),
  "btn-checkout": (
    "<=s": $main-font $semi-bold 12px 20px,
    ">s": $main-font $semi-bold 14px 25px,
  ),
  "checkout__order-address-title": (
    ">s": $main-font $semi-bold 24px 40px,
    "<=s": $main-font $semi-bold 17px 25px,
    "<=xs": $main-font $semi-bold 15px 20px,
  ),
  "checkout__order-address li": (
    ">s": $main-font $light 24px 40px,
    "<=s": $main-font $light 17px 25px,
    "<=xs": $main-font $light 15px 20px,
  ),
  "checkout .products__list-item-name": (
    "<=s": $main-font $light 17px 23px,
    ">s": $main-font $light 24px 40px,
  ),
  "jobs__list-item h3": (
    "<=s": $main-font $light 16px 20px,
    ">s": $main-font $light 20px 24px,
  ),
  "job-detail-form .input-radiocheck label": (
    "<=s": $main-font $light 17px 23px,
    ">s": $main-font $light 21px 32px,
  ),
  "products__list-recipe-details": (
    "<=s": $main-font $light 12px 20px,
    ">s": $main-font $light 14px 24px,
  ),
  "search-results__title": (
    ">s": $main-font $light 35px 45px,
    "<=s": $main-font $light 30px 40px,
    "<=xs": $main-font $light 25px 35px,
  ),
  "search-results__section-title": (
    ">s": $main-font $light 28px 37px,
    "<=s": $main-font $light 22px 29px,
    "<=xs": $main-font $light 20px 26px,
  ),
  "search-results__no-results-container p": (
    ">s": $main-font $light 20px 30px,
    "<=s": $main-font $light 17px 25px,
    "<=xs": $main-font $light 15px 20px,
  ),
  "search-results__pages-list a": (
    ">s": $main-font $light 20px 30px,
    "<=s": $main-font $light 17px 25px,
    "<=xs": $main-font $light 15px 20px,
  ),
  "header__user-login--authenticated p": (
    "<=s": $main-font $semi-bold 15px 25px,
    ">s": $main-font $semi-bold 15px 25px,
  ),
  "rca-styling .product-effects__title": (
    ">s": $secondary-font-rca-styling $medium 20px 25px,
    "<=s": $secondary-font-rca-styling $medium 17px 20px,
    "<=xs": $secondary-font-rca-styling $medium 17px 16px,
  ),
  "rca-styling .product-effects__grid h3": (
    ">s": $secondary-font-rca-styling $medium 20px 25px,
    "<=s": $secondary-font-rca-styling $medium 17px 20px,
    "<=xs": $secondary-font-rca-styling $medium 17px 16px,
  ),
  "rca-styling h4": (
    ">s": $main-font-rca-styling $light 20px 25px,
    "<=s": $main-font-rca-styling $light 20px 25px,
    "<=xs": $main-font-rca-styling $light 20px 25px,
  ),
  "rca-styling .card__hover-description--title": (
    ">s": $main-font-rca-styling $medium 20px 25px,
    "<=s": $main-font-rca-styling $medium 20px 25px,
    "<=xs": $main-font-rca-styling $medium 20px 25px,
  ),
  "rca-styling .collapsible-employee__title": (
    "<=s": $main-font-rca-styling $light 20px 25px,
    ">s": $main-font-rca-styling $medium 25px 36px,
  ),
  "rca-styling .text-plugin .normal-text": (
    "<=s": $main-font $normal 20px 28px,
    ">s": $main-font $normal 20px 28px,
  ),
  "grid-25-with-border > .text-plugin > p > span": (
    "<=s": $main-font $light 20px 28px,
    ">s": $main-font $normal 20px 28px,
  ),
);
@each $class, $tmap in $typography-classes-map {
  .#{$class} {
    @include typography($tmap);
  }
}
